(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('IssueSignificanceController', IssueSignificanceController);

    IssueSignificanceController.$inject = ['$scope', '$state', 'IssueSignificance', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function IssueSignificanceController ($scope, $state, IssueSignificance, ParseLinks, AlertService, paginationConstants, pagingParams) {
        var vm = this;

        
        loadAll();

        function loadAll () {
            IssueSignificance.query({}, onSuccess, onError);
            
            function onSuccess(data) {
                vm.issueSignificances = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }
})();
