(function () {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('InformationController', InformationController);

    InformationController.$inject = ['$scope',
        '$state', 'Information', 'ParseLinks',
        'AlertService', 'paginationConstants', 'pagingParams',
        'QuestionnaireCompany', 'DataUtils',
        'userPreferences', 'UserPreferences', 'DateUtils',
        '$stateParams', 'TicketState', 'Product', 'ProductLine', 'ReasonCode'];

    function InformationController($scope,
                                   $state, Information, ParseLinks,
                                   AlertService, paginationConstants, pagingParams,
                                   QuestionnaireCompany, DataUtils,
                                   userPreferences, UserPreferences, DateUtils,
                                   $stateParams, TicketState, Product, ProductLine, ReasonCode) {
        var vm = this;

        vm.savingPreferences = false;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 50;
        vm.userPreferences = userPreferences;
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {fromDate: false, toDate: false};
        vm.informationQuestionnairesTypes = ["ANOMALY", "CLOSE_CALL",
            "DANGER", "PROPOSITION", "CHANGE_NOTIFICATION",
            "BULLETIN_NOTIFICATION", "INJURY_NOTIFICATION", "RECLAMATION_NOTIFICATION", "INITIATIVE_NOTIFICATION"];

        vm.informationQuestionnaires = QuestionnaireCompany.QueryByTypes.query({
            types: vm.informationQuestionnairesTypes.join(),
            "size": 1000
        });

        vm.addToFilterByQuestionnaireTypes = ""; //"" means slect shows placeholder
        vm.filterByQuestionnaireTypes = [];

        if (pagingParams.questionnareTypes) {
            vm.filterByQuestionnaireTypes = pagingParams.questionnareTypes.split(',');
        }
        vm.cacheBuster = new Date().getTime();
        vm.createRootCauseAnalysisForSelected = createRootCauseAnalysisForSelected;
        vm.uploadImportSheet = uploadImportSheet;

        if ($stateParams.toDate) {
            vm.filteringToDate = DateUtils.convertLocalDateFromServer($stateParams.toDate);
        }
        if ($stateParams.fromDate) {
            vm.filteringFromDate = DateUtils.convertLocalDateFromServer($stateParams.fromDate);
        }
        if ($stateParams.searchTerm) {
            vm.filteringSearchTerm = $stateParams.searchTerm;
        }
        if ($stateParams.productId) {
            vm.filteringProductId = $stateParams.productId;
        }
        if ($stateParams.productLineId) {
            vm.filteringProductLineId = $stateParams.productLineId;
        }
        if ($stateParams.ticketStateTypeId) {
            vm.filteringTicketStateTypeId = $stateParams.ticketStateTypeId;
        }
        if ($stateParams.reasonCodeId) {
            vm.filteringReasonCodeId = $stateParams.reasonCodeId;
        }
        if ($stateParams.productionUnitId) {
            vm.filteringProductionUnitId = $stateParams.productionUnitId;
        }

        loadAll();
        loadProductLines();
        loadReasonCodes();
        loadStatusCodes();
        if(vm.filteringProductId){
            loadProduct();
        }

        vm.productSelected = function () {
            if (vm.filteringProduct) {
                vm.filteringProductId = vm.filteringProduct.id;
            }else{
                vm.filteringProductId = null;
            }
            vm.refreshSearch();

        }

        vm.refreshSearch = function () {
            if (vm.addToFilterByQuestionnaireTypes == "ALL") {
                vm.filterByQuestionnaireTypes = [];
            } else if (vm.addToFilterByQuestionnaireTypes != "") {
                var index = vm.filterByQuestionnaireTypes.indexOf(vm.addToFilterByQuestionnaireTypes);
                if (index == -1) {
                    vm.filterByQuestionnaireTypes.push(vm.addToFilterByQuestionnaireTypes);
                }
            }
            vm.addToFilterByQuestionnaireTypes = "";
            loadPage(1);
        };

        vm.removeFilterQuestionnaireType = function (type) {
            var index = vm.filterByQuestionnaireTypes.indexOf(type);
            if (index > -1) {
                vm.filterByQuestionnaireTypes.splice(index, 1);
            }
            loadPage(1);
        };

        function addFilteringParameters(parameters) {

            if (vm.filteringFromDate) {
                parameters.fromDate = DateUtils.convertLocalDateToServer(vm.filteringFromDate);
            }
            if (vm.filteringToDate) {
                parameters.toDate = DateUtils.convertLocalDateToServer(vm.filteringToDate);
            }
            if (vm.filteringSearchTerm) {
                parameters.searchTerm = vm.filteringSearchTerm;
            }
            if (vm.filteringProductId) {
                parameters.productId = vm.filteringProductId;
            }
            if (vm.filteringProductLineId) {
                parameters.productLineId = vm.filteringProductLineId;
            }
            if (vm.filteringReasonCodeId) {
                parameters.reasonCodeId = vm.filteringReasonCodeId;
            }
            if (vm.filteringProductionUnitId){
                parameters.productionUnitId = vm.filteringProductionUnitId;
            }
            if (vm.filteringTicketStateTypeId){
                parameters.ticketStateTypeId = vm.filteringTicketStateTypeId;
            }

            return parameters;
        }

        function loadAll() {
            var parameters = {
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                questionnareTypes: vm.filterByQuestionnaireTypes.join(),
                sort: sort()
            };

            parameters = addFilteringParameters(parameters);
            vm.informationExportParameters = parameters;
            Information.informationEventsQuery(parameters, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.information = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadProduct() {

            Product.get({'id': vm.filteringProductId}, onSuccess, onError);

            function onSuccess(product) {
                vm.filteringProduct = product;
            }

            function onError(error) {
                console.log("failed loading products");
            }
        }

        function loadProductLines() {
            ProductLine.query({"size": 1000, "sort": "name,asc"}, onSuccess, onError);

            function onSuccess(productLines, headers) {
                vm.productLines = productLines;
            }

            function onError(error) {
                console.log("failed loading productLines");
            }
        }

        function loadStatusCodes() {
            TicketState.query({"size": 1000, "sort": "name,asc"}, onSuccess, onError)

            function onSuccess(statusCode, headers) {
                var opts=[];
                for (var key in statusCode) {
                    if (statusCode[key].ticketType == "INFORMATION") {
                        opts.push({id: statusCode[key].id, name: statusCode[key].name})
                    }
                }
                vm.statusCodes = opts;
            }

            function onError(error) {
                console.log("failed loading statusCodes ", error)
            }
        }

        function loadReasonCodes() {
            ReasonCode.query({"size": 1000, "sort": "name,asc"}, onSuccess, onError);

            function onSuccess(reasonCode, headers) {
                vm.reasonCodes = reasonCode;
            }

            function onError(error) {
                console.log("failed loading reasonCodes");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {

            var parameters = {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                size: pagingParams.size
            };
            parameters = addFilteringParameters(parameters);
            if (vm.filterByQuestionnaireTypes.length > 0) {
                parameters.questionnareTypes = vm.filterByQuestionnaireTypes.join();
            }
            vm.informationExportParameters = parameters;
            console.log("transition parameters " + JSON.stringify(parameters));
            $state.transitionTo($state.$current, parameters);
        }

        function createRootCauseAnalysisForSelected() {
            var ids = [];
            vm.information.forEach(function (info) {
                if (info.selectionChecked) {
                    ids.push(info.id);
                }
            });

            if (ids.length === 0) {
                console.log('Nothing selected');
                return;
            }

            $state.go('root-cause-analysis.new', {inf: ids})
        }


        function uploadImportSheet($file) {

            if ($file == null) {
                return;
            }

            var fileDTO = {};

            DataUtils.toBase64($file, function (base64Data) {
                fileDTO.fileContent = base64Data;
                vm.upload(fileDTO);
            });
        }

        vm.upload = function (fileDTO) {
            vm.uploadingImportSheet = true;
            vm.importError = null;
            Information.uploadInformationImportSheet(fileDTO, onSuccess, onError);

            function onSuccess() {
                console.log('uploaded ');
                vm.importError = null;
                vm.uploadingImportSheet = false;
                $state.reload();
            }

            function onError(err) {
                vm.uploadingImportSheet = false;
                console.log("failed to upload import sheet " + JSON.stringify(err.data));

                vm.importError = {};
                if (err.data) {
                    vm.importError = err.data;

                    if (vm.importError.missingRequiredFields) {
                        vm.importError.missingRequiredFields = vm.importError.missingRequiredFields.join(", ");
                    }
                }
            }
        }

        vm.toggleVisibleColumn = function (colName) {

            var index = vm.userPreferences.informationPage.hiddenColumns.indexOf(colName);
            if (index > -1) {
                vm.userPreferences.informationPage.hiddenColumns.splice(index, 1);
            } else {
                vm.userPreferences.informationPage.hiddenColumns.push(colName);
            }

            vm.savingPreferences = true;
            UserPreferences.save(vm.userPreferences, prefSaveSuccess, prefSaveFail);

            function prefSaveSuccess(newPrefs) {
                vm.userPreferences = newPrefs;
                vm.savingPreferences = false;
                $state.reload();
            }

            function prefSaveFail() {
                vm.savingPreferences = false;
            }

        };

        vm.isHiddenColumn = function (colName) {
            return vm.userPreferences.informationPage.hiddenColumns.indexOf(colName) !== -1;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }


    }
})();
