(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TrainingRecordDialogController', TrainingRecordDialogController);

    TrainingRecordDialogController.$inject = ['$rootScope','$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TrainingRecord', 'TrainingRepositoryTrainingEventSelect', 'User', 'Company'];

    function TrainingRecordDialogController ($rootScope, $timeout, $scope, $stateParams, $uibModalInstance, entity, TrainingRecord, TrainingRepositoryTrainingEventSelect, User, Company) {
        var vm = this;

        vm.trainingRecord = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.isIndependentStudy = vm.trainingRecord.trainingEvent != null && vm.trainingRecord.trainingEvent.training.trainingType == 'INDEPENDENT';
        vm.trainingevents = TrainingRepositoryTrainingEventSelect.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.trainingRecord.id !== null) {
                TrainingRecord.update(vm.trainingRecord, onSaveSuccess, onSaveError);
            } else {
                vm.trainingRecord.company = $rootScope.getSelectedCompanyFromServer();
                TrainingRecord.save(vm.trainingRecord, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:trainingRecordUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.signupDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
