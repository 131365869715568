(function () {
    'use strict';

    angular
            .module('abcQualityMatchApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('taskEvent');
                        $translatePartialLoader.addPart('informationEvent');
                        $translatePartialLoader.addPart('information');
                        $translatePartialLoader.addPart('anomalyEvent');
                        $translatePartialLoader.addPart('questionnaireType');
                        $translatePartialLoader.addPart('taskVerification');
                        $translatePartialLoader.addPart('project');
                        $translatePartialLoader.addPart('ticket');
                        $translatePartialLoader.addPart('ticketMaterial');
                        $translatePartialLoader.addPart('login');
                        $translatePartialLoader.addPart('document');
                        $translatePartialLoader.addPart('documentFile');
                        return $translate.refresh();
                    }]
            }
        })
                .state('home-admin', {
                    parent: 'home',
                    url: '/home-admin',
                    data: {
                        authorities: ["ROLE_ADMIN"]
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/home/admin-home.html',
                            controller: 'AdminHomeController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                return $translate.refresh();
                            }]
                    }

                })
                .state('home-company-admin', {
                    parent: 'home',
                    url: '/home-company-admin',
                    data: {
                        authorities: ["ROLE_COMPANY_ADMIN"]
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/home/company-admin-home.html',
                            controller: 'CompanyAdminHomeController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                return $translate.refresh();
                            }]
                    }

                })
                .state('home-company-supervisor', {
                    parent: 'home',
                    url: '/home-company-supervisor',
                    data: {
                        authorities: ["ROLE_COMPANY_SUPERVISOR"]
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/home/company-supervisor-home.html',
                            controller: 'CompanySupervisorHomeController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                return $translate.refresh();
                            }]
                    }

                })
                .state('home-user', {
                    parent: 'home',
                    url: '/home-user',
                    data: {
                        authorities: ["ROLE_COMPANY_USER"]
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/home/user-home.html',
                            controller: 'UserHomeController',
                            controllerAs: 'vm'
                        }
                    },
                    resolve: {
                        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                return $translate.refresh();
                            }]
                    }

                });
    }
})();
