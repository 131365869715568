(function () {
    'use strict';
    angular
            .module('abcQualityMatchApp')
            .factory('DocumentTree', DocumentTree);

    DocumentTree.$inject = ['$resource'];

    function DocumentTree($resource) {
        var resourceUrl = 'api/document-folders';

        return $resource(resourceUrl, {}, {
            'getEditableFolderTree': {method: 'GET',
                isArray: true,
                url: 'api/document-folders/editable-folder-tree'},

            'getPublishedFolderTree':{
                isArray: true,
                url: 'api/document-folders/published-folder-tree'
            },
            'getWritableFolderSelectTree':{
                url: 'api/document-folders/writable-folder-select-tree'
            }
        });
    }
})();
