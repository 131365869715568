(function () {
    'use strict';

    angular
            .module('abcQualityMatchApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('questionnaire-company', {
            parent: 'company_admin',
            url: '/questionnaire-company/{companyId}',
            data: {
                authorities: ['ROLE_COMPANY_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire-edit/questionnaires-company-list.html',
                    controller: 'QuestionnairesListController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('questionnaireEdit');
                    $translatePartialLoader.addPart('questionnaire');
                    $translatePartialLoader.addPart('question');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('questionnaireType');
                    $translatePartialLoader.addPart('anomalyType');
                    return $translate.refresh();
                }]
            }
        })
        .state('questionnaire-company.new', {
            parent: 'questionnaire-company',
            url: '/new',
            params : {
                companyId: null
            },
            data: {
                authorities: ['ROLE_COMPANY_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire-edit/questionnaire-edit-dialog.html',
                    controller: 'QuestionnaireEditDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: function () {
                    return {
                        title: null,
                        description: null,
                        sections: [],
                        deleted: false,
                        company: null
                    };
                }
            }
        })
        .state('questionnaire-company.edit', {
            parent: 'questionnaire-company',
            url: '/edit/{id}',
            params : {
                companyId: null
            },
            data: {
                authorities: ['ROLE_COMPANY_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/questionnaire-edit/questionnaire-edit-dialog.html',
                    controller: 'QuestionnaireEditDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['Questionnaire', '$stateParams', function (Questionnaire, $stateParams) {
                    return Questionnaire.get({id: $stateParams.id}).$promise;
                }]
            }
        })
        .state('questionnaire-company.copy', {
                parent: 'questionnaire-company',
                url: '/copy/{id}',
                params : {
                    companyId: null,
                    copyMode: true
                },
                data: {
                    authorities: ['ROLE_COMPANY_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/questionnaire-edit/questionnaire-edit-dialog.html',
                        controller: 'QuestionnaireEditDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['QuestionnaireCompany', '$stateParams', function (QuestionnaireCompany, $stateParams) {
                        return QuestionnaireCompany.CopyToEditForCompany.copytoeditforcompany({id: $stateParams.id}).$promise;
                    }]
                }
            })
        .state('questionnaire-company.delete', {
            parent: 'questionnaire-company',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_COMPANY_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/questionnaire-edit/questionnaire-delete-dialog.html',
                    controller: 'QuestionnaireDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Questionnaire', function (Questionnaire) {
                            return Questionnaire.get({id: $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    $state.go('^', null, {reload: true});
                }, function () {
                    $state.go('^');
                });
            }]
        });
    }
})();
