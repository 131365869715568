(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('RootCauseAnalysisDetailController', RootCauseAnalysisDetailController);

    RootCauseAnalysisDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity'];

    function RootCauseAnalysisDetailController($scope, $rootScope, $stateParams, previousState, entity) {
        var vm = this;

        vm.rootCauseAnalysis = entity;
        vm.previousState = 'root-cause-analysis';  //previousState.name;

        var unsubscribe = $rootScope.$on('abcQualityMatchApp:rootCauseAnalysisUpdate', function(event, result) {
            vm.rootCauseAnalysis = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
    }
})();
