(function () {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .factory('AbcListUtils', AbcListUtils);

    function AbcListUtils () {
        var service = {
            stableSortByListIndex: stableSortByListIndex,
            refreshListIndexes:    refreshListIndexes,
            setDeletedOnIdex: setDeletedOnIdex,
            addNewToLast: addNewToLast,
            moveUpOrDown: moveUpOrDown,
            moveDeletedLast: moveDeletedLast,
            findById:findById
        };
        return service;


        function addNewToLast(element, list){
            list.push(element);
            moveDeletedLast(list);
            refreshListIndexes(list);
        }

        function setDeletedOnIdex(index, list){
            stableSortByListIndex(list); // for making sure list is in listIndex order initially
            list[index].deleted = true;
            moveDeletedLast(list);
            refreshListIndexes(list);
        }


        function stableSortByListIndex(list){
            var idsInOriginalOrder = [];
            list.forEach(function(element){idsInOriginalOrder.push(element.id)});

            list.sort(function(a,b){
                var diff = a.listIndex - b.listIndex;
                if (diff == 0){
                    diff = idsInOriginalOrder.indexOf(a.id) - idsInOriginalOrder.indexOf(b.id);
                }
                return diff;
            });
            return list;
        }

        function refreshListIndexes(elements){
            elements.forEach(function (element, index){element.listIndex = index});
        }

        function moveDeletedLast(list){
            var deleted = [];
            list.forEach(function(element){
                if (element.deleted){
                    deleted.push(element);
                }
            });
            deleted.forEach(function(element){
                list.splice(list.indexOf(element),1);
                list.push(element);
            });
        }

        function moveUpOrDown(index, direction, list) {
            stableSortByListIndex(list);
            if(direction === "up") {
                list[index-1].listIndex++;
                list[index].listIndex--;
            }
            else {
                list[index+1].listIndex--;
                list[index].listIndex++;
            }
            stableSortByListIndex(list);
            refreshListIndexes(list);
        }


        /**
         * Find item from list where item.id === id
         * @param id
         * @param list
         * @returns item with given id or null if not found
         */
        function findById(id, list){

            for (var i=0;i< list.length; ++i){
                if (list[i].id === id){
                    return list[i];
                }
            }
            return null;
        }

    }
})();
