(function() {
    'use strict';
    angular
        .module('abcQualityMatchApp')
        .factory('TraineeRecord', TraineeRecord);

    TraineeRecord.$inject = ['$resource', 'DateUtils'];

    function TraineeRecord ($resource, DateUtils) {

        return {
            UserRecords: $resource('api/training-records/user', {}, {'query': {method: 'GET', isArray: true}}),
            ExamOrSigningRequired: $resource('api/training-records/user/exam-or-signing-required', {}, {'query': {method: 'GET', isArray: true}}),
        };
    }

    angular
        .module('abcQualityMatchApp')
        .factory('TraineeEventSignup', TraineeEventSignup);

    TraineeEventSignup.$inject = ['$resource', 'DateUtils'];

    function TraineeEventSignup ($resource, DateUtils) {
        var resourceUrl =  'api/training-event-signups/for-user/:trainingEventSignupId';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'signupToIndependentStydyTraining': {
                params: {trainingId: '@trainingId'},
                method:'POST'
            },
            'signupToTrainingEvent': {
                params: {trainingEventId: '@trainingEventId'},
                method:'POST'
            },
            'deleteSignup': {
                method:'DELETE'
            }
        });
    }


    angular
        .module('abcQualityMatchApp')
        .factory('TraineeEventSignupExamPassed', TraineeEventSignupExamPassed);

    TraineeEventSignupExamPassed.$inject = ['$resource', 'DateUtils'];

    function TraineeEventSignupExamPassed ($resource, DateUtils) {
        var resourceUrl =  'api/training-event-signups/exam-passed';

        return $resource(resourceUrl, {}, {
            'setExamPassed': {
                params: {trainingEventSignupId: '@trainingEventSignupId'},
                method:'POST'
            }
        });
    }


})();
