(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('QuestionnaireEditQuestionController',QuestionnaireEditQuestionController);

    QuestionnaireEditQuestionController.$inject = ['$uibModalInstance', 'entity', 'AbcListUtils'];

    function QuestionnaireEditQuestionController($uibModalInstance, entity, AbcListUtils) {
        var vm = this;
        vm.question = entity;
        vm.newOptionValue;
        vm.questionnaireType = entity.questionnaireType;
        vm.cancel = cancel;
        vm.save = save;
        vm.addNewOption = addNewOption;
        vm.deleteOption = deleteOption;
        vm.setDefault = setDefault;
        vm.setAnomaly = setAnomaly;
        vm.moveOption = moveOption;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            $uibModalInstance.close(vm.question);
        }

        function addNewOption() {
            var option = {
                listIndex: vm.question.options.length,
                name: '',
                allowWriteIn: false,
                anomalyOption: false,
                deleted: false
            }
            AbcListUtils.addNewToLast(option, vm.question.options);
            return false;
        }

        function deleteOption(index) {
            vm.question.options.splice(index, 1);
            AbcListUtils.refreshListIndexes(vm.question.options);
            return false;
        }

        function setDefault(index) {
            for(var i=0; i < vm.question.options.length; i++) {
                if(i == index) {
                    vm.question.options[i].defaultSelection = !vm.question.options[i].defaultSelection;
                }
                else {
                    vm.question.options[i].defaultSelection = false;
                }
            }
        }

        function setAnomaly(index) {
            for(var i=0; i < vm.question.options.length; i++) {
                if(i == index) {
                    vm.question.options[i].anomalyOption = !vm.question.options[i].anomalyOption;
                }
            }
        }

        function moveOption(index, direction) {
            AbcListUtils.moveUpOrDown(index, direction, vm.question.options);

        }
    }
})();
