(function () {
    'use strict';
    angular
            .module('abcQualityMatchApp')
            .factory('Task', Task);

    Task.$inject = ['$resource', 'DateUtils'];

    function Task($resource, DateUtils) {
        return $resource('api/tasks/:id', {}, {
            //List of TaskSimplified where task is assigned to or editable by user
            'getAssignedOrEditableByUser': {
                url: 'api/tasks/assigned-or-editable-by-user',
                method: 'GET',
                isArray: true},
            //List task executions
            'getExecutionCounts': {
                url: 'api/tasks/execution-counts',
                method: 'GET',
                isArray: true},
            'getExecutionsTables': {
                url: 'api/tasks/executions-tables/:taskId',
                method: 'GET',
                isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.start = DateUtils.convertDateTimeFromServer(data.start);
                        data.end = DateUtils.convertDateTimeFromServer(data.end);
                        data.until = DateUtils.convertDateTimeFromServer(data.until);
                        data.doneTime = DateUtils.convertDateTimeFromServer(data.doneTime);
                    }
                    return data;
                }
            },

            'delete' : {
                method: 'DELETE',
                url: 'api/tasks/:id'
            },

            'update': {method: 'PUT'},

            //List of Task related to project phase
            'getProjectPhaseTasks':{method: 'GET',
                               isArray: true,
                               url: 'api/tasks/project-phase/:projectPhaseId/tasks'
            },
            'saveUpdateTaskFulfillment':{
                url: 'api/tasks/fulfillment',
                method:'PUT'
            },
            'getTaskFulfillment':{
                url: 'api/tasks/fulfillment/:taskId',
                method:'GET'
            },
            'getAnomalyCategorySelectList': {
                url:'/api/anomaly-categories/select-list',
                method:'GET',
                isArray:true
            },
            'saveAnswerAttachment': {
                url:'api/tasks/upload-answer-attachment/tasks/fulfillment',
                method:'PUT',
            },
            'deleteAnswerAttachment': {
                url:'/api/tasks/delete-answer-attachment/:id',
                method:'DELETE',
            },
            'activateTask': {
                method: 'POST',
                url: '/api/tasks/activate-task'
            }
        });
    }
})();
