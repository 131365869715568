(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('FactoryDialogController', FactoryDialogController);

    FactoryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Factory'];

    function FactoryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Factory) {
        var vm = this;

        vm.factory = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('#field_name').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.factory.id !== null) {
                Factory.update(vm.factory, onSaveSuccess, onSaveError);
            } else {
                Factory.save(vm.factory, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:factoryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
