(function() {
    'use strict';

    /**
     * Renders user object into "lastName, firstName" or login if names not set
     */
    angular.module('abcQualityMatchApp').filter('fullUserName', function() {
        return function(user) {

          var out = "";
          if (user){
              if (user.lastName){
                  out = user.lastName;
              }
              if (out.length > 0 && user.firstName){
                  out = out + ", " + user.firstName;
              }
              else if (user.firstName){
                  out = user.firstName;
              }
              if (out.length === 0){
                  out = user.login;
              }
          }
          return out;
        };
    });

    /**
     * Renders truthy value as checkmark and false as " "
     */
    angular.module('abcQualityMatchApp').filter('checkMark', function() {
        return function(value) {

            var mark = " ";
            if (value){
                mark = "\u2714"; // unicode: 'heavy checkmark'
            }
            return mark;
        };
    });

    /**
     * Renders ReasonCode object into "code name"
     */
    angular.module('abcQualityMatchApp').filter('codeAndName', function() {
        return function(reasonCode) {

          var out = "";
          if (reasonCode){
            out = reasonCode.code + " " + reasonCode.name;
          }
          return out;
        };
    });

    /**
     * Renders product object into "product number: product name"
     */
    angular.module('abcQualityMatchApp').filter('productNumberAndName', function() {
        return function(product) {

            var out = "";
            if (product){
                out = product.number + " " + product.name;
            }
            return out;
        };
    });

    /**
     * Translates anomaly reporting status
     */
    angular.module('abcQualityMatchApp').filter('anomalyReported', AnomalyReported);
    AnomalyReported.$inject = ['$translate'];
    function AnomalyReported($translate) {
        return function (anomaly) {

            var out = "";
//            if (!((anomaly.qaAnomaly && !anomaly.qaAnomalyConfirmed) || (anomaly.answerAnomaly && !anomaly.answerAnomalyConfirmed))) {
            if (anomaly.reported) {
                out = $translate.instant('abcQualityMatchApp.anomalyEvent.reported.true');
            } else {
                out = $translate.instant('abcQualityMatchApp.anomalyEvent.reported.false');
            }
            return out;
        };
    };


    /**
     * Renders informationEvent as time and title
     */
    angular.module('abcQualityMatchApp').filter('informationEventFormat', ['dateFilter',function (dateFilter) {
        return function (event) {
            if (!event){
                return "";
            }

            var time = dateFilter(event.created,'shortDate');
            return time + " " + event.title;
        };
    }]);

    /**
     * Renders anomalyEvent
     */
    angular.module('abcQualityMatchApp').filter('anomalyEventFormat', ['dateFilter',function (dateFilter) {
        return function (event) {
            if (!event){
                return "";
            }

            var time = dateFilter(event.answerTime,'shortDate');
            return time + " " + event.questionnaireTitle + " / " + event.questionTitle;
        };
    }]);

    /**
     * Renders json to queryString
     */
    angular.module('abcQualityMatchApp').filter('toQueryString', ['$httpParamSerializer',function ($httpParamSerializer) {
        return function (params) {
            var qs = $httpParamSerializer(params);
            return "?" + qs;
        };
    }]);


    /**
     * Wraps matching part of given text into span with class highlightedMatch
     */
    angular.module('abcQualityMatchApp').filter('highlightMatch', ['$sce', function($sce) {
        return function(text, phrase) {
            if (phrase && text) {
                text = text.replace(new RegExp('(' + phrase + ')', 'gi'),
                    '<span class="highlightedMatch">$1</span>');
            }
            return $sce.trustAsHtml(text);
        };
    }]);


    /**
     * Renders trainingEvent instructor and outside instructor name.
     */
    angular.module('abcQualityMatchApp').filter('trainingEventInstructor', ['fullUserNameFilter', function(fullUserName) {
        return function(trainingEvent) {
            var out = fullUserName(trainingEvent.instructor);
            if (trainingEvent.outsideInstructor){
                 out = out + " / " + trainingEvent.outsideInstructor;
            }
            return out;
        };
    }]);

})();
