(function () {
    'use strict';

    var verificationEvents = {
        templateUrl: 'app/components/home/verification-events/verification-events.html',
        controllerAs: 'vm',
        controller: ['TaskVerificationRedone', function (TaskVerificationRedone) {
                var vm = this;
                vm.taskVerifications = null;

                loadTaskVerifications();

                function loadTaskVerifications() {

                    var params = {
                        sort: '(done_time_min),asc',
                        'unverifiedOnly':true
                    };

                    TaskVerificationRedone.query(params, onSuccess, onError);
                    function onSuccess(data, headers) {
                        vm.taskVerifications = data;
                    }
                    function onError(error) {
                        console.log("failed");
                    }
                }
            }]
    };

    angular
            .module('abcQualityMatchApp')
            .component('verificationEvents', verificationEvents);


})();
