(function () {
    'use strict';

    angular
            .module('abcQualityMatchApp')
            .config(stateConfig)
            .run(['$rootScope', '$http', '$q', 'ENV', function ($rootScope, $http, $q, ENV) {

                    $rootScope.ENV = ENV;
                    $rootScope.selectedCompany;
                    $rootScope.userCompanies;

                    $rootScope.resetCompany = function () {
//                        console.log("start reset");
                        $rootScope.selectedCompany = null;
                        $rootScope.userCompanies = null;
                        $rootScope.getDefaultCompany();
//                        console.log("end reset");
                    };

                    $rootScope.getSelectedCompanyId = function () {
                        if ($rootScope.selectedCompany &&
                                $rootScope.selectedCompany.id &&
                                $rootScope.selectedCompany != null &&
                                $rootScope.selectedCompany.id > 0) {
                            return $rootScope.selectedCompany.id;
                        } else {
                            return $q.when($rootScope.getSelectedCompany()).then(function (company) {
                                if (company) {
                                    return company.id;
                                } else {
                                    return 0;
                                }
                            });
                        }
                    };

                    $rootScope.getSelectedCompany = function () {
                        if ($rootScope.selectedCompany &&
                                $rootScope.selectedCompany != null &&
                                $rootScope.selectedCompany.id &&
                                $rootScope.selectedCompany.id > 0) {
                            return $rootScope.selectedCompany;
                        } else {
                            return null;
                        }
                    };

                    $rootScope.getDefaultCompany = function () {
                        return $q.when($rootScope.getUserCompanies()).then(function () {
                            return $rootScope.getSelectedCompanyFromServer().then(function (company) {
                                if (company) {
//                                    console.log("default server company");
                                    $rootScope.userCompanies.forEach(function (userCompany) {
                                        if (userCompany.id == company.id) {
                                            $rootScope.selectedCompany = userCompany;
                                        }
                                    });
                                    return $rootScope.selectedCompany;
                                } else if ($rootScope.userCompanies.length > 0) {
//                                    console.log("default company");
                                    $rootScope.selectedCompany = $rootScope.userCompanies[0];
                                    $rootScope.setSelectedCompanyToServer($rootScope.selectedCompany);
                                    return $rootScope.selectedCompany;
                                } else {
                                    return null;
                                }
                            })
                        });
                    }

                    $rootScope.getUserCompanies = function (refresh) {
                        if (refresh == undefined || refresh != undefined && refresh == false) {
                            if ($rootScope.userCompanies != undefined) {
                                return $q.when($rootScope.userCompanies).then(function () {
                                    return $rootScope.userCompanies;
                                });
                            }
                        }
                        return $http.get("/api/companies/user").then(function (response) {
//                            console.log("fetch companies");
                            $rootScope.userCompanies = response.data;
                            return $rootScope.userCompanies;
                        });
                    };

                    $rootScope.setSelectedCompany = function (company) {
                        $rootScope.selectedCompany = company;
                    };

                    $rootScope.setSelectedCompanyToServer = function (company) {
                        console.log("Company changed to :");
                        console.log(company);

                        $http.post('api/companies/selected', company)
                                .success(function (response) {
                                    console.log(response);
                                });
                    };

                    $rootScope.getSelectedCompanyFromServer = function () {
                        return $http.get("/api/companies/selected").then(function (response) {
                            console.log("selected from server");
                            console.log(response.data);
                            return response.data;
                        });
                    };

                    $rootScope.getDefaultCompany();
                }]);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            views: {
                'navbar@': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                    }]
            }
        });
    }
})();
