(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('DocumentFileEditDialogController', DocumentFileEditDialogController);

    DocumentFileEditDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'documentFile', 'DocumentFile', 'useCase'];

    function DocumentFileEditDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, documentFile, DocumentFile, useCase) {
        var vm = this;

        vm.useCase = useCase;

        vm.fileContent = null;

        vm.allowFileSelect = vm.useCase === 'newFile' || vm.useCase === 'newVersion' || vm.useCase === 'nextVersionEdit';
        vm.requireFileSelect = vm.useCase === 'newFile' || vm.useCase === 'newVersion';
        vm.allowStateSelect = vm.useCase !== 'metadataCorrection';

        vm.isSaving = false;

        vm.documentFile = documentFile;
        vm.save = save;


        vm.datePickerOpenStatus = {
            validityEndDate: false,
            validityEndAlertDate: false

        };

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        vm.preparingSelectedFile = false;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        function save () {
            vm.isSaving = true;

            var saveDTO = {
                documentFile: vm.documentFile,
                fileContent: vm.fileContent
            };

            if (vm.useCase === 'newFile'){
                DocumentFile.save(saveDTO, onSaveSuccess, onSaveError);
            }
            else if (vm.useCase === 'newVersion'){
                DocumentFile.createNewVersion(saveDTO, onSaveSuccess, onSaveError);
            }
            else if (vm.useCase === 'metadataCorrection'){
                saveDTO.fileContent = null;
                DocumentFile.update(saveDTO, onSaveSuccess, onSaveError);
            }
            else if (vm.useCase === 'nextVersionEdit'){
                DocumentFile.update(saveDTO, onSaveSuccess, onSaveError);
            }
            else{
                vm.isSaving = false;
                console.log("error no useCase set")
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:documentFileUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.setFileContent = function ($file, $invalidFiles) {

            vm.selectedFileTooLarge = false;
            if ($invalidFiles && $invalidFiles.length > 0) {
                vm.selectedFileTooLarge = true;
                $timeout(function () {
                    vm.selectedFileTooLarge = false;
                }, 3000);
                return;
            }

            if ($file == null) {
                vm.fileContent = null;
                vm.documentFile.fileName = null;
                vm.documentFile.fileContentContentType = null;
                return;
            }

            vm.preparingSelectedFile = true;
            DataUtils.toBase64($file, function (base64Data) {

                vm.fileContent = base64Data;
                vm.documentFile.fileName = $file.name;
                vm.documentFile.fileContentContentType = $file.type;

                if (!vm.documentFile.name){
                    vm.documentFile.name = $file.name;
                }

                $timeout(function () {
                    vm.preparingSelectedFile = false;
                    console.log("done setting file data");
                }, 1);


            });
        };

    }
})();
