(function () {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('ProductController', ProductController);

    ProductController.$inject = ['$uibModal', '$scope', '$state', 'Product', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$stateParams'];

    function ProductController($uibModal, $scope, $state, Product, ParseLinks, AlertService, paginationConstants, pagingParams, $stateParams) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.includeArchived = $stateParams.includeArchived == "true";

        loadAll();

        function loadAll() {
            if (vm.includeArchived) {
                Product.queryWithArchived({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Product.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.products = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.reload = function () {
            loadAll();
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {

            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                includeArchived: vm.includeArchived

            });
        }

        vm.openProductImportDialog = function () {

            $uibModal.open({
                templateUrl: 'app/company_admin/product/import-dialog/product-import-dialog.html',
                controller: 'ProductImportdialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg'

            }).result.then(function (result) {
                $state.reload();
            });

        };


    }
})();
