(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('DocumentTreeViewController', DocumentTreeViewController);

    DocumentTreeViewController.$inject = [
        '$scope', '$state', '$stateParams',
        'DocumentFolder', 'AlertService', '$translate',
        'DocumentTree','DocumentTreeUtils'];

    function DocumentTreeViewController (
        $scope, $state, $stateParams,
        DocumentFolder, AlertService, $translate,
        DocumentTree, DocumentTreeUtils) {

        var vm = this;

        vm.fsItems = [];
        vm.searchTerms = null;
        vm.searchMatchCount = 0;
        vm.rootFolders = [];

        vm.filterBySearchTerms = function(searchTerms){
            vm.searchTerms = searchTerms;
            if (!vm.searchTerms){
                DocumentTreeUtils.setDefaultOpenStates(vm.rootFolders);
                return;
            }
            vm.rootFolders.forEach(function(folder){
                DocumentTreeUtils.closeFolder(folder, vm.searchTerms);
            });

            vm.rootFolders.forEach(function(folder){
                vm.searchMatchCount = DocumentTreeUtils.setFilterMatchFlags(folder, vm.searchTerms);
            });
        };

        loadTreeRoot();

        function loadTreeRoot() {
            var params = {};
            DocumentTree.getPublishedFolderTree(params, onSuccess, onError);

            function onSuccess(rootFolders) {
                vm.rootFolders = rootFolders;
                var openFolderIds = [];
                vm.fsItems.forEach(function(isItem){
                    if (isItem.rowType === 'folder' && isItem.open){
                        openFolderIds.push(isItem.id);
                    }
                });
                vm.fsItems = DocumentTreeUtils.rootFoldersToFlatFsItemList(rootFolders, openFolderIds);

                if (openFolderIds.length === 0){
                    DocumentTreeUtils.setDefaultOpenStates(vm.rootFolders);
                }

                if (vm.searchTerms) {
                    vm.filterBySearchTerms();
                }
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.reload = function(){
            loadTreeRoot();
        };

        vm.folderClick = function(folder){
            folder.open = !folder.open;
            if (!folder.open){
                DocumentTreeUtils.closeFolder(folder);
            }
        };
    }
})();











