(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TrainingEventDetailController', TrainingEventDetailController);

    TrainingEventDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TrainingEventSignupList'];

    function TrainingEventDetailController($scope, $rootScope, $stateParams, previousState, entity, TrainingEventSignupList) {
        var vm = this;

        vm.trainingEvent = entity;
        vm.trainingEvent.signups = [];
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('abcQualityMatchApp:trainingEventUpdate', function(event, result) {
            vm.trainingEvent = result;
            vm.trainingEvent.signups = [];
            loadSignups();
        });
        $scope.$on('$destroy', unsubscribe);
        
        loadSignups();
        
        function loadSignups() {
            TrainingEventSignupList.query({id: vm.trainingEvent.id}, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.trainingEvent.signups = data;
            }
            function onError(error) {
                console.log("failed");
            }
        }
    }
})();
