(function() {
    'use strict';
    angular.module('abcQualityMatchApp').component('documentFolderSelect', {
        templateUrl: 'app/document/document-tree/document-folder/document-folder-select-component/document-folder-select-component.html',
        restrict: 'E',
        bindings: {
            folderId: '=',              //Currently selected folder's id or null/undefined for root
            disableFromFolderId: '<',  //disable selecting folder with this id or any of its subfolders (to prevent folder loop)
            validSelection: '=?',       // Sets value to true if valid selection has been made
            allowInitialValue: '<' //Allow selecting what ever folder was initially given as folderId
        },
        controller: DocumentFolderSelectController
    });

    DocumentFolderSelectController.$inject = ['DocumentTree', '$translate'];
    function DocumentFolderSelectController(DocumentTree, $translate) {
        var ctrl = this;

        ctrl.selectedFolder = null;
        ctrl.initialValue = ctrl.folderId;


        ctrl.folders = [];

        init();

        ctrl.selectFolder = function(folder){

            if (!folder || folder.disabled || !folder.writable){
                return;
            }

            ctrl.selectedFolder = folder;
            ctrl.folderId = ctrl.selectedFolder.id;
            ctrl.validSelection = true;
        };

        function init(){
            DocumentTree.getWritableFolderSelectTree({}, onSuccess, onError);

            function onSuccess(rootFolder) {
                ctrl.folders = rootFoldersToFlatList(rootFolder);

                ctrl.folders.forEach(function(dir){
                    if (dir.id == ctrl.folderId){
                        ctrl.selectFolder(dir);
                    }
                });
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function rootFoldersToFlatList(rootFolder){

            rootFolder.nestingLevel = 0;

            var list = [rootFolder];

            rootFolder.subFolders.forEach(function(folder){
                toFlatList(folder, 1, false).forEach(function(sub){list.push(sub);});
            });

            list.forEach(function (folder) {
                if (ctrl.allowInitialValue && folder.id == ctrl.initialValue) {
                    folder.writable = true;
                    folder.disabled = false;
                }
            });

            return list;
        }

        function toFlatList(folder, nestingLevel, disabled){
            var list = [];
            folder.nestingLevel = nestingLevel;
            folder.indent = Array(folder.nestingLevel +1).join("&nbsp;");

            disabled = disabled || ctrl.disableFromFolderId == folder.id;
            folder.disabled = disabled;

            list.push(folder);

            folder.subFolders.forEach(function(sub){
                sub.parentFolder = folder;
                var subList = toFlatList(sub, nestingLevel + 1, disabled);
                subList.forEach(function(subTree){
                    list.push(subTree);
                });
            });

            return list;
        }





    }

})();
