(function() {
    'use strict';

    var questionnaireAnswerQuestion = {
        templateUrl: 'app/components/questionnaire-answer-question/questionnaire-answer-question.html',
        bindings: {
            question: '=',
            comparisons: '<',
            viewonly: '<',
            onAnswerChange: '<',
            questionnaire: '<',
            editForm: '<?'
        },
        controllerAs: 'vm',
        controller: function() {
            var vm = this;
        }
    };

    angular
        .module('abcQualityMatchApp')
        .component('questionnaireAnswerQuestion', questionnaireAnswerQuestion);


})();
