(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('QuestionnairesListController', QuestionnairesListController);

    QuestionnairesListController.$inject =
        ['$scope', '$state', 'Principal', 'QuestionnaireCompany',
            'Questionnaire', 'ParseLinks', 'AlertService',
            'paginationConstants'];

    function QuestionnairesListController (
        $scope, $state, Principal, QuestionnaireCompany,
        Questionnaire, ParseLinks, AlertService,
        paginationConstants) {


        var vm = this;

        //Id of company whow's questionnaires are show or undefined/null for template questionnaires
        vm.companyId = $state.params.companyId;

        vm.questionnaires = [];
        vm.loadPage = loadPage;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;
        vm.account = null;


        if (vm.companyId) {
            vm.templateQuestionnaires = Questionnaire.getCompanyTemplates();
        }

        loadAll();


        function loadAll () {

            var params = {
                page: vm.page,
                size: vm.itemsPerPage,
                sort: sort()
            };

            if (vm.companyId) {
                QuestionnaireCompany.Query.query(params, onSuccess, onError);
            }
            else {
                Questionnaire.query(params, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }



            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.questionnaires.push(data[i]);
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset () {
            vm.page = 0;
            vm.questionnaires = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        $scope.copy = function(copyQuestionnaireId) {
            console.log("CopyScope");
            console.log(copyQuestionnaireId);
            QuestionnaireCompany.Copy.copy({id: copyQuestionnaireId},null, onSuccess, onError);

            function onSuccess(data, headers) {
                $scope.$emit('abcQualityMatchApp:questionnaireUpdate', data);
                reset();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }
})();
