(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TrainingRecordDetailController', TrainingRecordDetailController);

    TrainingRecordDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TrainingRecord', 'TrainingEvent', 'User', 'Company'];

    function TrainingRecordDetailController($scope, $rootScope, $stateParams, previousState, entity, TrainingRecord, TrainingEvent, User, Company) {
        var vm = this;

        vm.trainingRecord = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('abcQualityMatchApp:trainingRecordUpdate', function(event, result) {
            vm.trainingRecord = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
