(function () {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('CompanyDialogController', CompanyDialogController);

    CompanyDialogController.$inject = ['$rootScope', '$timeout', '$scope', 'DataUtils', '$stateParams', '$uibModalInstance', 'entity', 'Company'];

    function CompanyDialogController($rootScope, $timeout, $scope, DataUtils, $stateParams, $uibModalInstance, entity, Company) {
        var vm = this;

        vm.company = entity.company;
        vm.clear = clear;
        vm.save = save;
        loadLogo();
        vm.deleteLogo = false;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function loadLogo() {
            if (!entity.companyLogo) {
                vm.hasLogo = false;
                return;
            }
            vm.logoUrl = "/api/companies/get-logo/" + entity.companyLogo.id;
            vm.hasLogo = true;
        }

        function save() {
            vm.isSaving = true;

            if (vm.fileContent) {
                vm.companyLogo = {
                    fileContent: vm.fileContent,
                    fileContentContentType: vm.fileType
                };
            }
            vm.companyDTO = {company: vm.company, companyLogo: vm.companyLogo}
            if (vm.company.id) {
                if (vm.deleteLogo) {
                    vm.companyDTO = {company: vm.company, companyLogo: null}
                    vm.hasLogo = false;
                }
                if(!vm.fileContent && !vm.deleteLogo){
                    vm.companyDTO = {company: vm.company, companyLogo: entity.companyLogo};

                }
                Company.update(vm.companyDTO, onSaveSuccess, onSaveError);

            } else {
                Company.save(vm.companyDTO, onSaveSuccess, onSaveError);
            }

        }


        vm.setFileContent = function ($file, $invalidFiles) {
            vm.selectedFileTooLarge = false;
            vm.isSaving = false;
            vm.badFile = false;

            if ($file) {
                var imgTypes = ["image/jpeg", "image/jpg", "image/png"];
                var typeIndex = imgTypes.indexOf($file.type);
                if (typeIndex < 0) {
                    console.log("wrong filetype " + $file.type + " ");
                    vm.isSaving = true;
                    vm.badFile = true;
                    vm.badFileLabel = "-  Väärä tiedostomuoto. Tuetut tiedostomuodot ovat: .jpeg .jpg .png";
                    vm.fileLabel = $file.name;

                    return;
                }
                vm.fileType = $file.type;
                console.log($file.type);
                vm.fileLabel = $file.name;

                if ($invalidFiles && $invalidFiles.length > 0) {
                    vm.selectedFileTooLarge = true;
                    $timeout(function () {
                        vm.selectedFileTooLarge = false;
                    }, 3000);
                    return;
                }

                vm.preparingSelectedFile = true;
                DataUtils.toBase64($file, function (base64Data) {
                    vm.fileContent = base64Data;
                    $timeout(function () {
                        vm.preparingSelectedFile = false;
                        console.log("done setting file data");
                    }, 1);

                });
            }

        };



        function onSaveSuccess(result) {
            $scope.$emit('abcQualityMatchApp:companyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
            $rootScope.resetCompany();
            console.log(result);

        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
