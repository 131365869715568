(function () {
    'use strict';

    angular
            .module('abcQualityMatchApp')
            .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', '$state', '$rootScope', '$timeout', 'Auth'];

    function HomeController($scope, Principal, $state, $rootScope, $timeout, Auth) {
        var vm = this;

        vm.authenticationError = false;
        vm.login = login;

        vm.username = null;
        vm.password = null;
        vm.rememberMe = true;

        vm.account = null;
        vm.isAuthenticated = null;


        $timeout(function (){angular.element('#username').focus();});

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });
        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                if (account == null){
                    return;
                }
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (account.forcedPasswordChangeOnNextLogin){
                    $state.go('forced-password-change');
                }
                else if (account.authorities) {

                    if (Principal.hasAnyAuthority(["ROLE_ADMIN"])) {
                        $state.go("home-admin");
                    } else if (Principal.hasAnyAuthority(["ROLE_COMPANY_ADMIN"])) {
                        $state.go("home-company-admin");
                    } else if (Principal.hasAnyAuthority(["ROLE_COMPANY_SUPERVISOR"])) {
                        $state.go("home-company-supervisor");
                    } else if (Principal.hasAnyAuthority(["ROLE_COMPANY_USER"])) {
                        $state.go("home-user");
                    } else {
                    }
                }
            });
        }

        function login (event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }

                $rootScope.$broadcast('authenticationSuccess');

                console.log("call reset")
                $rootScope.resetCompany();

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function () {
                vm.authenticationError = true;
            });
        }
    }
})();
