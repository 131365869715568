(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('RootCauseAnalysisFactorDeleteController',RootCauseAnalysisFactorDeleteController);

    RootCauseAnalysisFactorDeleteController.$inject = ['$uibModalInstance', 'entity', 'RootCauseAnalysisFactor'];

    function RootCauseAnalysisFactorDeleteController($uibModalInstance, entity, RootCauseAnalysisFactor) {
        var vm = this;

        vm.rootCauseAnalysisFactor = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            RootCauseAnalysisFactor.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
