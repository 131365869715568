(function () {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .factory('QuestionnaireUtils', QuestionnaireUtils);

    function QuestionnaireUtils() {
        var service = {
            checkIfAnswered: checkIfAnswered,
            hasUnansweredRequiredQuestions: hasUnansweredRequiredQuestions
        };
        return service;

        function checkIfAnswered(question) {
            if (question.questionType === "multi") {
                return (question.answer.optionAnswers.length > 0);
            }
            if (question.questionType === "integer") {
                return (typeof question.answer.value == 'number');
            }
            if (question.questionType === "decimal") {
                return (typeof question.answer.value == 'number');
            }
            if (question.questionType === "freetext") {
                return !!question.answer.value && question.answer.value.length > 0;
            }
            console.log('unknown question type '+ question.questionType);
            return false;
        }

        function hasUnansweredRequiredQuestions(questionnaire) {
            var hasUnansweredRequired = false;
            questionnaire.sections.forEach(function (section) {
                if (hasUnansweredRequired) {
                    return;
                }
                section.questions.forEach(function (question) {
                    if (hasUnansweredRequired) {
                        return;
                    }
                    hasUnansweredRequired = question.required && !checkIfAnswered(question);
                });
            });
            return hasUnansweredRequired;
        }
    }
})();
