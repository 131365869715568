(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TrainingEventDialogController', TrainingEventDialogController);

    TrainingEventDialogController.$inject =
        ['$translate','$timeout', '$scope',
            '$stateParams', '$uibModalInstance', 'entity',
            'TrainingEvent', 'Company', 'User',
            'Training', 'TrainingEventSignupList', 'UserGroup'];

    function TrainingEventDialogController(
        $translate, $timeout, $scope,
        $stateParams, $uibModalInstance, entity,
        TrainingEvent, Company, User,
        Training, TrainingEventSignupList, UserGroup) {

        var vm = this;

        vm.trainingEvent = entity;
        vm.trainingEvent.signups = [];
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.newSignup = createEmptySignup();
        vm.addSignup = addSignup;
        vm.deleteSignup = deleteSignup;

        vm.userGroupSelectList = UserGroup.selectList();
        vm.newUserGroupForSignupLimitLimit = null;

        if (vm.trainingEvent.id){
            loadSignups();
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });


        function loadSignups() {
            TrainingEventSignupList.query({id: vm.trainingEvent.id}, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.trainingEvent.signups = data;
            }
            function onError(error) {
                console.log("failed");
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.trainingEvent.id !== null) {
                TrainingEvent.update(vm.trainingEvent, onSaveSuccess, onSaveError);
            } else {
                TrainingEvent.save(vm.trainingEvent, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:trainingEventUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.start = false;
        vm.datePickerOpenStatus.end = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }


        function addSignup() {

            if (vm.newSignup.user === null){
                return;
            }
            vm.newSignup.signupDate = new Date();

            var userAlreadySignedup = vm.trainingEvent.signups.find(function(signup){return signup.user.id == vm.newSignup.user.id});
            if (userAlreadySignedup){
                vm.newSignup = createEmptySignup();
                return;
            }


            vm.trainingEvent.signups.push(vm.newSignup);
            vm.newSignup = createEmptySignup();
            return false;
        }

        function deleteSignup(index){
            vm.trainingEvent.signups.splice(index,1);
            return false;

        }

        function createEmptySignup(){
            return {
                user: null,
                signupDate: null,
                signupAccepted: true,
                examPassed: false
            };
        }

        vm.deleteSignupLimitGroup = function(index){
            vm.trainingEvent.userGroupSignupLimits.splice(index,1);
        };

        vm.addUserGroupLimit = function(){

            if (vm.newUserGroupForSignupLimitLimit != null) {

                var alreadyAdded = false;
                vm.trainingEvent.userGroupSignupLimits.forEach(function (groupLimit) {
                    if (groupLimit.userGroup.id == vm.newUserGroupForSignupLimitLimit.id) {
                        alreadyAdded = true;
                    }
                });
                if (!alreadyAdded) {

                    var groupLimit ={
                        id: null,
                        userGroup: vm.newUserGroupForSignupLimitLimit
                    }

                    vm.trainingEvent.userGroupSignupLimits.push(groupLimit);
                }
            }
            vm.newUserGroupForSignupLimitLimit = null;
        }
    }
})();
