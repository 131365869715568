(function() {
    'use strict';
    angular.module('abcQualityMatchApp').component('documentFileDetailsView', {
        templateUrl: 'app/document/document-tree/document-file/details-view/document-file-details-view.html',
        restrict: 'E',
        bindings: {
            documentFile: '<'
        },
        controller: DocumentFileDetailsViewController
    });

    DocumentFileDetailsViewController.$inject = ['$scope']
    function DocumentFileDetailsViewController(scope) {
        var ctrl = this;

    }




})();
