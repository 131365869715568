(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = [
        '$scope', '$stateParams', '$uibModalInstance',
        'entity', 'User', 'JhiLanguageService',
        'Principal', 'UserGroup', 'ProductLine'];

    function UserManagementDialogController (
        $scope, $stateParams, $uibModalInstance,
        entity, User, JhiLanguageService,
        Principal, UserGroup, ProductLine) {

        var vm = this;

        $scope.password="";
        $scope.confirmPassword = "";
        $scope.changePassword = true;

        $scope.doNotMatch = false;

        vm.newUserGroup = null;
        vm.newProductLine = null;
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {activeFromDate: false, activeToDate:false };
        vm.currentAccount = null;
        vm.authorities = ['ROLE_COMPANY_USER', 'ROLE_COMPANY_SUPERVISOR', 'ROLE_COMPANY_ADMIN'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;
 
        if (vm.user.login) {
            vm.originalLogin = vm.user.login;
            vm.newUser = false;
        } else {
            vm.newUser = true;
        }
        vm.userGroups = UserGroup.selectList();
        vm.productLines = ProductLine.selectList();

        if (vm.user.id != null) {
            $scope.changePassword = false;
            vm.user.forcedPasswordChangOnNextLogin = false;
        }

        if (vm.user.activated){
            vm.activityType = 'ACTIVE';
        }
        else{
            vm.activityType = 'INACTIVE';
        }

        Principal.identity().then(function(account) {
            vm.currentAccount = account;
            if(account.authorities.indexOf("ROLE_ADMIN") !== -1) {
            	vm.authorities = ['ROLE_COMPANY_USER', 'ROLE_COMPANY_SUPERVISOR', 'ROLE_COMPANY_ADMIN','ROLE_ADMIN'];
            } else {
            	vm.authorities = ['ROLE_COMPANY_USER', 'ROLE_COMPANY_SUPERVISOR', 'ROLE_COMPANY_ADMIN'];
            }
        });

        vm.addUserGroup = function () {
            if (vm.newUserGroup != null) {
                var alreadyAdded = false;
                vm.user.userGroupLites.forEach(function (group) {
                    if (group.id == vm.newUserGroup.id) {
                        alreadyAdded = true;
                    }
                });
                if (!alreadyAdded) {
                    vm.user.userGroupLites.push(vm.newUserGroup);
                }
            }
            vm.newUserGroup = null;
        }

        vm.deleteUserGroup = function (index) {
            vm.user.userGroupLites.splice(index,1);
            return false;
        }

        vm.addProductLine = function () {
            if (vm.newProductLine != null) {
                var alreadyAdded = false;
                vm.user.productLineLites.forEach(function (productLine) {
                    if (productLine.id == vm.newProductLine.id) {
                        alreadyAdded = true;
                    }
                });
                if (!alreadyAdded) {
                    vm.user.productLineLites.push(vm.newProductLine);
                }
            }
            vm.newProductLine = null;
        }

        vm.deleteProductLine = function (index) {
            vm.user.productLineLites.splice(index,1);
            return false;
        }

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            vm.user.activated = vm.activityType != 'INACTIVE';

            vm.isSaving = true;
            if ($scope.changePassword && $scope.password !== $scope.confirmPassword) {
        		$scope.doNotMatch = true;
        		$scope.isSaving = false;
        		return;
        	} else if ($scope.changePassword){
        		$scope.doNotMatch = false;
        		if ($scope.password !== "") {
        			vm.user.password = $scope.password;
        		} else {
        			alert($translate.instant('user-management.emptyPassword'));
        			return;
        		}
        	}

            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }

        vm.radioButtonChange = function(option) {
            vm.user.authorities[0] = option;
        };
    }
})();
