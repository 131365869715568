(function() {
    'use strict';

    var questionnaireAnswerSection = {
        templateUrl: 'app/components/questionnaire-answer-section/questionnaire-answer-section.html',
        bindings: {
            section: '=',
            comparisons: '<',
            viewonly: '<',
            onAnswerChange: '&',
            questionnaire: '<',
            editForm: '<?'
        }
    };

    angular
        .module('abcQualityMatchApp')
        .component('questionnaireAnswerSection', questionnaireAnswerSection);


})();
