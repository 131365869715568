(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TraineeStudyController', TraineeStudyController);

    TraineeStudyController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TraineeEventSignupExamPassed'];

    function TraineeStudyController($timeout, $scope, $rootScope, $stateParams, previousState, entity, TraineeEventSignupExamPassed) {
        var vm = this;

        vm.trainingEventSignup = entity;
        vm.trainingEvent = entity.trainingEvent;
        vm.previousState = previousState.name;
        vm.examCheckPassed = null;
        vm.checkExam = checkExam;
        vm.showExam = false;

        if (vm.trainingEventSignup.examPassed){
            vm.examCheckPassed = true;
        }

        vm.confirmMaterials = function() {
            vm.showExam = true;
        };
        
        function checkExam(){
            vm.isSaving = false;
            vm.examCheckPassed = null;
            
            var numberOfCorrectAnswers = 0;
            
            vm.trainingEvent.training.examQuestions.forEach(function(examQuestion){
                if (examQuestion.answer === examQuestion.expectedAnswer){
                        ++numberOfCorrectAnswers;
                }
            });
            
            var numberOfQuestions = vm.trainingEvent.training.examQuestions.length;
            var correctPercent = 100;
            
            if (numberOfQuestions > 0){
                correctPercent = Math.round(numberOfCorrectAnswers / numberOfQuestions * 100); 
            }
            
            var passed = true;
            if (vm.trainingEvent.training.examPassRequiredPercent){
                passed = correctPercent >= vm.trainingEvent.training.examPassRequiredPercent;
            }

            if (passed){
                vm.examCheckPassed = true;
                TraineeEventSignupExamPassed.setExamPassed({trainingEventSignupId: vm.trainingEventSignup.id}, onSaveSuccess, onSaveError);
            }
            else {
                vm.examCheckPassed = false;
                $timeout(function () { vm.examCheckPassed = null; }, 3000); 
                
            }
        }

        function onSaveSuccess (result) {
            
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
