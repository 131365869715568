(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['$timeout','Principal', 'User', 'ParseLinks', 'AlertService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService'];

    function UserManagementController($timeout, Principal, User, ParseLinks, AlertService, $state, pagingParams, paginationConstants, JhiLanguageService) {
        var vm = this;

        vm.authorities = ['ROLE_COMPANY_USER', 'ROLE_COMPANY_SUPERVISOR', 'ROLE_COMPANY_ADMIN','ROLE_ADMIN'];
        vm.currentAccount = null;
        vm.languages = null;
        vm.loadAll = loadAll;
        vm.setActive = setActive;
        vm.users = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.clear = clear;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.refreshSearch = refreshSearch;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;

        vm. status = 'ALL';
        vm.searchTerm = null;

        if (pagingParams.searchTerm){
            vm.searchTerm = pagingParams.searchTerm;
        }
        if (pagingParams.status){
            vm.status = pagingParams.status;
        }

        $timeout(function (){
            angular.element('#seachTerm').focus();
        });

        vm.loadAll();
        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });
        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });

        function refreshSearch(){
            loadPage(1);
        }


        function setActive (user, isActivated) {
            user.activated = isActivated;
            user.activeFromDate = null;
            user.activeToDate= null;
            User.update(user, function () {
                vm.loadAll();
                vm.clear();
            });
        }

        function loadAll() {
            User.getManagementList({
                searchTerm: vm.searchTerm,
                status: vm.status,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
        }

        vm.spreadsheetDownloadParams ={
            searchTerm: vm.searchTerm,
            status: vm.status,
            page: pagingParams.page - 1,
            size: vm.itemsPerPage,
            sort: sort()
        };

        function onSuccess(data, headers) {
            //hide anonymous user from user management: it's a required user for Spring Security
            var hiddenUsersSize = 0;
            for (var i in data) {
                if (data[i]['login'] === 'anonymoususer') {
                    data.splice(i, 1);
                    hiddenUsersSize++;
                }
            }
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count') - hiddenUsersSize;
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.users = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function clear () {
            vm.user = {
                id: null, login: null, firstName: null, lastName: null, email: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null
            };
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                searchTerm: vm.searchTerm,
                status: vm.status
            });
        }
    }
})();
