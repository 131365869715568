(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .factory('ForcedPasswordChange', ForcedPasswordChange);

    ForcedPasswordChange.$inject = ['$resource'];

    function ForcedPasswordChange($resource) {
        var resourceUrl =  'api/account/forced-password-change';

        return $resource(resourceUrl, {}, {
            'changePassword': { method:'POST' }
        });
    }


})();
