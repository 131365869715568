(function() {
    'use strict';
    angular.module('abcQualityMatchApp').component('anomalyEventAddSelect', {
          templateUrl: 'app/components/anomaly-event-add-select/anomaly-event-add-select-component.html',  
          restrict: 'E', 
          bindings: { onSelect: '&' },
          controller: AnomalyEventAddSelectController
        });

    AnomalyEventAddSelectController.$inject = ['$scope', 'Anomaly','$http']
    function AnomalyEventAddSelectController(scope, Anomaly, $http) {
        var ctrl = this;
        
        this.selected = null;
        
        ctrl.getAnomalyEvents = function(val){
            return Anomaly.Company.query({searchTerm: val,
                                          sort:'(answer_time),desc'}
                                        ).$promise;
        }
        
        ctrl.setSelected = function(){
            console.log("selected " + JSON.stringify(this.selected));
            this.onSelect({selected: this.selected});
            this.selected = null;
        }
    }
    
    
    
    
})();
