(function () {
    'use strict';
    angular
            .module('abcQualityMatchApp')
            .factory('Training', Training);

    Training.$inject = ['$resource'];

    function Training($resource) {
        var resourceUrl = 'api/trainings/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
    
    
    angular.module('abcQualityMatchApp')
           .factory('TrainingForNewTrainingEvent', TrainingForNewTrainingEvent);
    TrainingForNewTrainingEvent.$inject = ['$resource'];
    function TrainingForNewTrainingEvent($resource) {
        var resourceUrl = 'api/trainings/for-new-training-event-creation';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            
        });
    }
    
    
    

    angular
            .module('abcQualityMatchApp')
            .factory('TrainingRelatedMaterial', TrainingRelatedMaterial);
    TrainingRelatedMaterial.$inject = ['$resource'];

    function TrainingRelatedMaterial($resource) {
        var resourceUrl = 'api/training-materials/for-training';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }


    function TrainingRelatedMaterial($resource, DateUtils) {

        return {
            Materials: $resource('api/training-materials/for-training', {}, {'query': {method: 'GET', isArray: true}}),
            Order: $resource('api/training-materials/update-list-index/:trainingMaterialId', {}, {'updateListIndex': 
                        {method: 'POST', isArray: true,
                        params: '@inParams'
                    }
            }),
        };
    }


    angular
            .module('abcQualityMatchApp')
            .factory('TrainingMaterial', TrainingMaterial);

    TrainingMaterial.$inject = ['$resource'];

    function TrainingMaterial($resource) {
        var resourceUrl = 'api/training-materials/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }


})();
