(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('ProjectPhaseDialogController', ProjectPhaseDialogController);

    ProjectPhaseDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProjectPhase', 'Project', 'User', 'UserGroup', 'TicketStateTypeList', '$rootScope', 'uiDatetimePickerConfig', '$translate'];

    function ProjectPhaseDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ProjectPhase, Project, User, UserGroup, TicketStateTypeList, $rootScope, uiDatetimePickerConfig, $translate) {
        var vm = this;
        window.vvm = vm;

        vm.projectPhase = entity;
        vm.clear = clear;
        vm.save = save;
        vm.usergroups = UserGroup.query({"size": 1000});
        vm.ticketstates = TicketStateTypeList.query({"ticketType": 'PROJECT_PHASE', "size": 1000, "sort":"listIndex,asc"});
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
console.log(entity);
        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.projectPhase.id !== null) {
                ProjectPhase.update(vm.projectPhase, onSaveSuccess, onSaveError);
            } else {
                vm.projectPhase.ticket.company = $rootScope.getSelectedCompanyFromServer();
                ProjectPhase.save(vm.projectPhase, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:projectPhaseUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.datePickerOpenStatus.doneTime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
            uiDatetimePickerConfig.buttonBar.today.text = $translate.instant('global.datepicker.today');
            uiDatetimePickerConfig.buttonBar.now.text = $translate.instant('global.datepicker.now');
            uiDatetimePickerConfig.buttonBar.time.text = $translate.instant('global.datepicker.time');
            uiDatetimePickerConfig.buttonBar.date.text = $translate.instant('global.datepicker.date');
            uiDatetimePickerConfig.buttonBar.clear.text = $translate.instant('global.datepicker.clear');
            uiDatetimePickerConfig.buttonBar.close.text = $translate.instant('global.datepicker.close');
        }

    }
})();
