(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('trainee-available', {
            parent: 'training_menu',
            url: '/trainee/available-training?page&sort&search',
            data: {
                authorities: ['ROLE_COMPANY_USER'],
                pageTitle: ''
            },
            views: {
                'content@': {
                    templateUrl: 'app/training-main/trainee/trainee-availabe-training.html',
                    controller: 'TraineeTrainingAvailableController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'start,asc',
                    squash: true
                },
                search: null

            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('training');
                    $translatePartialLoader.addPart('trainingType');
                    $translatePartialLoader.addPart('trainingExamQuestion');
                    $translatePartialLoader.addPart('trainingRecord');
                    $translatePartialLoader.addPart('trainingEventSignup');
                    $translatePartialLoader.addPart('trainingEvent');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('trainingExamQuestion');
                    return $translate.refresh();
                }]
            }
        })
        .state('trainee-independent-study-signup', {
            parent: 'trainee-available',
            url: '/signup-independent-study/{trainingId}?signedUp',
            data: {
                authorities: ['ROLE_COMPANY_USER'],
                pageTitle: 'abcQualityMatchApp.training.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/training-main/trainee/trainee-training-signup.html',
                    controller: 'TraineeTrainingSignUpDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('training');
                    $translatePartialLoader.addPart('trainingType');
                    $translatePartialLoader.addPart('trainingExamQuestion');
                    return $translate.refresh();
                }],
                independentStudyTraining: ['$stateParams', 'Training', function($stateParams, Training) {
                    return Training.get({id : $stateParams.trainingId}).$promise;
                }],
                trainingEvent : [function(){return null;}],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'trainee-available',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('trainee-training-event-signup', {
            parent: 'trainee-available',
            url: '/signup-training-event/{trainingEventId}?signedUp',
            data: {
                authorities: ['ROLE_COMPANY_USER'],
                pageTitle: 'abcQualityMatchApp.training.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/training-main/trainee/trainee-training-signup.html',
                    controller: 'TraineeTrainingSignUpDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('training');
                    $translatePartialLoader.addPart('trainingType');
                    $translatePartialLoader.addPart('trainingExamQuestion');
                    return $translate.refresh();
                }],
                independentStudyTraining: [function(){return null;}],
                trainingEvent: ['$stateParams', 'TrainingEvent', function($stateParams, TrainingEvent) {
                    return TrainingEvent.get({id : $stateParams.trainingEventId}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'trainee-available',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('trainee-records', {
            parent: 'training_menu',
            url: '/trainee/records?page&sort&search',
            data: {
                authorities: ['ROLE_COMPANY_USER'],
                pageTitle: 'abcQualityMatchApp.trainingRecord.myTrainingsTitle'
            },
            views: {
                'content@': {
                    templateUrl: 'app/training-main/trainee/trainee-records.html',
                    controller: 'TraineeRecordController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'trainingEvent.start,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trainingRecord');
                    $translatePartialLoader.addPart('training');
                    $translatePartialLoader.addPart('trainingEventSignup');
                    $translatePartialLoader.addPart('trainingEvent');
                    $translatePartialLoader.addPart('trainingType');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('trainingExamQuestion');
                    return $translate.refresh();
                }]
            }
        })
        .state('trainee-records.exam', {
            parent: 'trainee-records',
            url: '/{id}/exam',
            data: {
                authorities: ['ROLE_COMPANY_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/training-main/trainee/trainee-exam-dialog.html',
                    controller: 'TraineeExamDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TrainingRecord', function(TrainingRecord) {
                            return TrainingRecord.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('trainee-records', null, { reload: 'trainee-records' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('trainee-records.sign', {
            parent: 'trainee-records',
            url: '/{id}/sign',
            data: {
                authorities: ['ROLE_COMPANY_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/training-main/trainee/trainee-sign-dialog.html',
                    controller: 'TraineeSignDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TrainingRecord', function(TrainingRecord) {
                            return TrainingRecord.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('trainee-records', null, { reload: 'trainee-records' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('trainee-records-study', {
            parent: 'trainee-records',
            url: '/study/{signupId}',
            data: {
                authorities: ['ROLE_COMPANY_USER'],
                pageTitle: 'abcQualityMatchApp.trainingEvent.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/training-main/trainee/trainee-study.html',
                    controller: 'TraineeStudyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trainingEvent');
                    $translatePartialLoader.addPart('training');
                    $translatePartialLoader.addPart('trainingType');
                    $translatePartialLoader.addPart('trainingEventSignup');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'TrainingEventSignup', function($stateParams, TrainingEventSignup) {
                    return TrainingEventSignup.get({id : $stateParams.signupId}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'training-event',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        });
    }
})();
