(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('ProductionUnitDialogController', ProductionUnitDialogController);

    ProductionUnitDialogController.$inject = [
        '$timeout', '$scope', '$stateParams',
        '$uibModalInstance', 'entity', 'ProductionUnit',
        'Factory'];

    function ProductionUnitDialogController (
        $timeout, $scope, $stateParams,
        $uibModalInstance, entity, ProductionUnit,
        Factory) {

        var vm = this;

        vm.productionUnit = entity;
        vm.clear = clear;
        vm.save = save;
        vm.factories = Factory.query({"size": 1000, "sort":"name,asc"});

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.productionUnit.id !== null) {
                ProductionUnit.update(vm.productionUnit, onSaveSuccess, onSaveError);
            } else {
                ProductionUnit.save(vm.productionUnit, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:productionUnitUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
