(function () {
    'use strict';

    angular
            .module('abcQualityMatchApp')
            .controller('QuestionnaireEditSectionDialogController', QuestionnaireEditSectionDialogController);

    QuestionnaireEditSectionDialogController.$inject = ['$uibModalInstance'];

    function QuestionnaireEditSectionDialogController($uibModalInstance) {
        var vm = this;
        vm.newSectionName;
        
        vm.closeDialog = function() {
            $uibModalInstance.dismiss('cancel');
        }
        
        vm.save = function() {
            var section = {
              title: vm.newSectionName,
              listIndex: -1,
              questions: []
            };
            $uibModalInstance.close(section);
        }
    }
    
})();