(function () {
    'use strict';

    var stats = {
        templateUrl: 'app/components/report/stats/stats.html',
        controllerAs: 'vm',
        controller: ['Report', '$state', function (Report, $state) {
                var vm = this;
                vm.stats = Report.Stats.query({});
            }]
    };

    angular
            .module('abcQualityMatchApp')
            .component('stats', stats);


})();