(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TicketDialogController', TicketDialogController);

    TicketDialogController.$inject = ['$scope', '$uibModalInstance'];

    function TicketDialogController ($scope, $uibModalInstance) {
        var vm = this;
        vm.ticketTypes = [{name:"Poikkeamailmotus", qId:3}, {name:"Kehitysehdotus", qId:4}];
        vm.selectedType = vm.ticketTypes[0];
        vm.pl = null;
        vm.vt = null;
        
        
        vm.clear = function() {
            $uibModalInstance.close();
        };
        
        vm.save = function() {
            $uibModalInstance.close();
        }
    }
})();