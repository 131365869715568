(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('training-event', {
            parent: 'training_menu',
            url: '/training-event?page&sort&search',
            data: {
                authorities: ['ROLE_COMPANY_USER'],
                pageTitle: 'abcQualityMatchApp.trainingEvent.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/training-main/training-event/training-events.html',
                    controller: 'TrainingEventController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'start,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trainingEvent');
                    $translatePartialLoader.addPart('training');
                    $translatePartialLoader.addPart('trainingType');
                    $translatePartialLoader.addPart('trainingEventSignup');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('training-event-detail', {
            parent: 'training_menu',
            url: '/training-event/{id}',
            data: {
                authorities: ['ROLE_COMPANY_USER'],
                pageTitle: 'abcQualityMatchApp.trainingEvent.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/training-main/training-event/training-event-detail.html',
                    controller: 'TrainingEventDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trainingEvent');
                    $translatePartialLoader.addPart('training');
                    $translatePartialLoader.addPart('trainingType');
                    $translatePartialLoader.addPart('trainingEventSignup');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'TrainingEvent', function($stateParams, TrainingEvent) {
                    return TrainingEvent.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'training-event',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('training-event-detail.edit', {
            parent: 'training-event-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_COMPANY_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/training-main/training-event/training-event-dialog.html',
                    controller: 'TrainingEventDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TrainingEvent', function(TrainingEvent) {
                            return TrainingEvent.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('training-event.new', {
            parent: 'training-event',
            url: '/new?trainingId',
            data: {
                authorities: ['ROLE_COMPANY_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/training-main/training-event/training-event-dialog.html',
                    controller: 'TrainingEventDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TrainingEventPrepare', function(TrainingEventPrepare) {
                            return TrainingEventPrepare.get({trainingId : $stateParams.trainingId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('training-event', null, { reload: 'training-event' });
                }, function() {
                    $state.go('training-event');
                });
            }]
        })
        .state('training-event.edit', {
            parent: 'training-event',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_COMPANY_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/training-main/training-event/training-event-dialog.html',
                    controller: 'TrainingEventDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TrainingEvent', function(TrainingEvent) {
                            return TrainingEvent.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('training-event', null, { reload: 'training-event' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('training-event.delete', {
            parent: 'training-event',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_COMPANY_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/training-main/training-event/training-event-delete-dialog.html',
                    controller: 'TrainingEventDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['TrainingEvent', function(TrainingEvent) {
                            return TrainingEvent.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('training-event', null, { reload: 'training-event' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('training-event.instuctor-view', {
            parent: 'training-event',
            url: '/{id}/instructor',
            data: {
                authorities: ['ROLE_COMPANY_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/training-main/training-event/training-event-instructor-view.html',
                    controller: 'TrainingEventInstructorViewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TrainingEvent', function(TrainingEvent) {
                            return TrainingEvent.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('training-event', null, { reload: 'training-event' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
