(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('AnomalyCategoryDetailController', AnomalyCategoryDetailController);

    AnomalyCategoryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AnomalyCategory'];

    function AnomalyCategoryDetailController($scope, $rootScope, $stateParams, previousState, entity, AnomalyCategory) {
        var vm = this;

        vm.anomalyCategory = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('abcQualityMatchApp:anomalyCategoryUpdate', function(event, result) {
            vm.anomalyCategory = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
