(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('UserGroupDialogController', UserGroupDialogController);

    UserGroupDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'UserGroup', 'User', 'Training'];

    function UserGroupDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, UserGroup, User, Training) {
        var vm = this;
        window.vvm = vm;
        vm.userGroup = entity;
        vm.clear = clear;
        vm.save = save;
        vm.authorities = UserGroup.getUserGroupAuthorityTypes();
        vm.newUser = null;
        vm.newAuthority = null;
        vm.trainingSelectList = Training.query();
        vm.newTraining = null;

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.userGroup.id !== null) {
                UserGroup.update(vm.userGroup, onSaveSuccess, onSaveError);
            } else {
                UserGroup.save(vm.userGroup, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:userGroupUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.addUser = function () {
            if (vm.newUser != null) {
                var alreadyAdded = false;
                vm.userGroup.users.forEach(function (userGroupUser) {
                    if (userGroupUser.id == vm.newUser.id) {
                        alreadyAdded = true;
                    }
                });
                if (!alreadyAdded) {
                    vm.userGroup.users.push(vm.newUser);
                    vm.newUser = null;
                }
            }
        };

        vm.deleteUser = function (index) {
            vm.userGroup.users.splice(index,1);
            return false;
        };

        vm.addAuthority = function () {
            if (vm.newAuthority != null) {
                vm.newAuthority.forEach(function (a) {
                    var alreadyAdded = false;
                    vm.userGroup.userGroupAuthorities.forEach(function (authority) {
                        if (authority.name == a) {
                            alreadyAdded = true;
                        }
                    });
                    if (!alreadyAdded) {
                        vm.userGroup.userGroupAuthorities.push({name: a});
                    }
                });
            }
            vm.newAuthority = null;
        };

        vm.deleteAuthority = function (index) {
            vm.userGroup.userGroupAuthorities.splice(index,1);
            return false;
        };


        vm.deleteExpectedTraining = function(index){
            vm.userGroup.expectedTrainings.splice(index,1);
        };
        vm.addExpectedTraining = function(){
            if (vm.newTraining != null){
                var alreadyAdded = false;
                vm.userGroup.expectedTrainings.forEach(function(expt){
                    if (expt.training.id == vm.newTraining.id){
                        alreadyAdded = true;
                    }
                })

                if (!alreadyAdded){
                    var newExpectedTraining = {id: null, training: vm.newTraining, required: false};
                    vm.userGroup.expectedTrainings.push(newExpectedTraining);
                }
                vm.newTraining = null;
            }
        };
    }
})();
