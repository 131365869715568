(function () {
    'use strict';
    angular
        .module('abcQualityMatchApp')
        .component('questionnaireAnswerDecimalQuestion', {
            templateUrl: 'app/components/questionnaire-answer-decimal-question/questionnaire-answer-decimal-question.html',
            bindings: {
                question: '=',
                viewonly: '<',
                onAnswerChange: '&',
                questionnaire: '<',
                editForm: '<?'
            },
            controllerAs: 'vm',
            controller: questionnaireAnswerDecimalQuestion
        });

    questionnaireAnswerDecimalQuestion.$inject = ['QuestionnaireUtils', '$scope', '$uibModal'];
    function questionnaireAnswerDecimalQuestion(QuestionnaireUtils, $scope, $uibModal) {

        var vm = this;
        if (vm.viewonly) {
            vm.inputName = '' + vm.question.id + '-view';
        }
        else{
            vm.inputName = '' + vm.question.id + '-answerInput';
        }

        vm.answerInputChange = function () {
            var answerCausesException = false;
            if (vm.questionnaire.questionnaireType === "NORMAL" ) {
                if (vm.question.answer.value != null) {
                    if (vm.question.minimumValue != null && vm.question.minimumValue > vm.question.answer.value) {
                        answerCausesException = true;
                    }
                    if (vm.question.maximumValue != null && vm.question.maximumValue < vm.question.answer.value) {
                        answerCausesException = true;
                    }
                }
            }
            vm.question.answer.anomaly = answerCausesException || !!vm.question.answer.anomalyCategory;
            vm.question.answer.isAnswered = QuestionnaireUtils.checkIfAnswered(vm.question);
            vm.onAnswerChange();
        };

        vm.preventInvalidInput = function(e) {

            //console.log('key \'' +  e.key + '\' keycode: ' + e.keyCode);

            if (e.keyCode === 109 || //numbad minus
                e.keyCode === 189 || //minus
                e.key === '-' ||
                e.key === '.' ||
                e.keyCode === 108 || //numpad decimal key
                e.keyCode === 110 || //numpad decimal key IE
                e.keyCode === 190 ||  //dot
                e.keyCode === 188  //dot
            ){
                return; // accept negative sign and decimal separator
            }

            // prevent invalid chars
            if (e.key.length === 1 && (isNaN(Number(e.key) ))){ // single char that is not a number
                e.preventDefault();
            }
        };

        vm.deleteAttachment = function(answer, attachment) {
            $scope.$emit("abcQualityMatchApp:deleteAttachment",{answer: answer, attachment: attachment});
        }

        vm.addAttachment = function(answer) {
            $uibModal.open({
                templateUrl: 'app/components/questionnaire-answer-attachment/answer-attachment-dialog.html',
                controller: 'AnswerAttachmentDialogController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                resolve:{}
            }).result.then(function(result) {
                console.log('add attachment dialog closing ', result);
                console.log('answer', answer);
                if (!answer.attachments) {
                    answer.attachments=[];    
                }
                answer.attachments.push(
                    {
                        id: null,
                        storageFile: result
                    }
                )
            });
        }

    }
})();
