(function() {
    'use strict';
    angular.module('abcQualityMatchApp').component('userSelect', {
          templateUrl: 'app/components/user-select/user-select-component.html',
          restrict: 'E',
          bindings: {
              user: '=',
              onSelect: '&',
              placeholder:'@',
              viewonly: '<'
          },
          controller: UserSelectController
        });

    UserSelectController.$inject = ['$timeout','$scope', 'User']
    function UserSelectController($timeout,scope, User) {
        var ctrl = this;

        ctrl.getUsers = function(val){
            return User.query({searchTerm: val,
                                          sort:'last_name,asc'}
                                        ).$promise;
        };

        ctrl.setSelected = function(){
            $timeout(function (){
                if (ctrl.onSelect) {
                    ctrl.onSelect();
                }
            });
        };
    }

})();
