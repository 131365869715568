(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('AnomalyCategoryDialogController', AnomalyCategoryDialogController);

    AnomalyCategoryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'AnomalyCategory'];

    function AnomalyCategoryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, AnomalyCategory) {
        var vm = this;

        vm.anomalyCategory = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.anomalyCategory.id !== null) {
                AnomalyCategory.update(vm.anomalyCategory, onSaveSuccess, onSaveError);
            } else {
                AnomalyCategory.save(vm.anomalyCategory, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:anomalyCategoryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
