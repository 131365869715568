(function () {
    'use strict';

    angular
            .module('abcQualityMatchApp')
            .controller('QuestionnaireEditDialogController', QuestionnaireEditDialogController);

    QuestionnaireEditDialogController.$inject =
        ['$scope', 'entity', 'Questionnaire',
            '$timeout', '$state',
            '$uibModal', '$log',
            'AbcListUtils'];

    function QuestionnaireEditDialogController(
        $scope, entity, Questionnaire,
        $timeout, $state,
        $uibModal, $log,
        AbcListUtils) {


        var vm = this;
        window.vvm = vm;
        vm.questionnaire = entity;
        vm.companyId = $state.params.companyId;
        vm.copyMode = $state.params.copyMode;
        if (vm.copyMode){
            vm.copyingFromQUestionnaireTitle = vm.questionnaire.title;
        }

        vm.questionTypes = ['integer', 'decimal', 'multi', 'freetext'];

        vm.clear = function () {
            $state.go('^', {}, { reload: false });
        }

        vm.addSection = function () {
            console.log("add section");

            var newSection = {
                title: '',
                listIndex: vm.questionnaire.sections.length,
                questions: []
            };
            vm.questionnaire.sections.push(newSection);
        };



        vm.addQuestion = function (section, type) {
            $uibModal.open({
                templateUrl: 'app/questionnaire-edit/questions/question-' + type + '.html',
                controller: 'QuestionnaireEditQuestionController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    entity: function () {
                        var question = {
                            questionType: type
                        };
                        question.questionnaireType = vm.questionnaire.questionnaireType;
                        if(type === "multi") {
                            question.options = [];
                            question.renderType = 'CHECKBOX';
                        }
                        if(type === "freetext") {
                            question.size = 'SHORT';
                        }
                        if (type == 'decimal' || type == 'integer'){
                            question.interpretation = 'NONE';
                        }

                        return question;
                    }
                }
            }).result.then(function (newQuestion) {
                newQuestion.listIndex = section.questions.length;
                section.questions.push(newQuestion);
                AbcListUtils.refreshListIndexes(section.questions);
            });
        }



        vm.editQuestion = function (section, index) {
            console.log("edit question: " + index);
            var question = section.questions[index];
            question.questionnaireType = vm.questionnaire.questionnaireType;
            $uibModal.open({
                bindings: {
                    resolve: '='
                },
                templateUrl: 'app/questionnaire-edit/questions/question-' + question.questionType + '.html',
                controller: 'QuestionnaireEditQuestionController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    entity: function () {
                        return question;
                    }
                }
            });
        };

        vm.deleteQuestion = function (section, index) {
            console.log("delete question: " + index);
            section.questions.splice(index, 1);
            AbcListUtils.refreshListIndexes(section.questions);
        };

        vm.moveQuestion = function (section, index, direction) {
            console.log("move question in list postion: "  + index + " " + direction );
            AbcListUtils.moveUpOrDown(index, direction, section.questions);
        };


        vm.moveSection = function (index, direction) {
            console.log("move section in list postion: "  + index + " " + direction );
            AbcListUtils.moveUpOrDown(index, direction, vm.questionnaire.sections);
        };

        vm.deleteSection = function (index) {
            console.log("delete section: " + index);
            vm.questionnaire.sections.splice(index, 1);
            AbcListUtils.refreshListIndexes(vm.questionnaire.sections);
        };


        vm.save = function () {
            console.log("save");
            vm.isSaving = true;

            if (vm.questionnaire.id){
                Questionnaire.update(vm.questionnaire, onSaveSuccess, onSaveError);
            }
            else{
                if (vm.companyId){
                    vm.questionnaire.company = {id: vm.companyId};
                }
                Questionnaire.save(vm.questionnaire, onSaveSuccess, onSaveError);
            }


            function onSaveSuccess(result) {
                $scope.$emit('abcQualityMatchApp:questionnaireUpdate', result);
                $state.go('^', {}, { reload: true });
                vm.isSaving = false;
            }

            function onSaveError() {
                vm.isSaving = false;
            }
        };

        vm.selectQuestionnaireType = function() {
            //Ensuring that anomalyType is set only when questionnaireType is "ANOMALY"
            if (!(vm.questionnaire.questionnaireType === 'ANOMALY')) {
                vm.questionnaire.anomalyType = null;
            }
        }

    }
})();
