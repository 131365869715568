(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('task', {
            parent: 'functions_menu',
            url: '/task?page&sort&search',
            data: {
                authorities: ['ROLE_COMPANY_USER'],
                pageTitle: 'abcQualityMatchApp.task.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/task/tasks.html',
                    controller: 'TaskController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('task');
                    $translatePartialLoader.addPart('ticket');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('frequence');
                    $translatePartialLoader.addPart('ticket');
                    $translatePartialLoader.addPart('ticketMaterial');
                    $translatePartialLoader.addPart('recurrence');
                    return $translate.refresh();
                }]
            }
        })
        .state('task-detail', {
            parent: 'functions_menu',
            url: '/task/{id}',
            data: {
                authorities: ['ROLE_COMPANY_USER'],
                pageTitle: 'abcQualityMatchApp.task.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/task/task-detail.html',
                    controller: 'TaskDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('task');
                    $translatePartialLoader.addPart('frequence');
                    $translatePartialLoader.addPart('ticket');
                    $translatePartialLoader.addPart('ticketMaterial');
                    $translatePartialLoader.addPart('recurrence');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Task', function($stateParams, Task) {
                    return Task.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'task',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('task-detail.edit', {
            parent: 'task-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_COMPANY_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/task/task-dialog.html',
                    controller: 'TaskDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Task', function (Task) {
                            return Task.get({id: $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function () {
                    $state.go('^', {}, {reload: false});
                }, function () {
                    $state.go('^');
                });
            }]
        })
        .state('task.new', {
            parent: 'task',
            url: '/new',
            data: {
                authorities: ['ROLE_COMPANY_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/task/task-dialog.html',
                    controller: 'TaskDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                subject: null,
                                description: null,
                                start: null,
                                end: null,
                                allDay: false,
                                id: null,
                                useRecurrence:false,
                                freq: null,
                                count: null,
                                interval: null,
                                assignedTo: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('task', null, { reload: 'task' });
                }, function() {
                    $state.go('task');
                });
            }]
        })
        .state('task.activate', {
            parent: 'task',
            url: '/{id}/activate',
            data: {
                authorities: ['ROLE_COMPANY_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/task/task-activate.html',
                    controller: 'TaskActivateController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        entity: ['Task', function(Task) {
                            return Task.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('task', null, { reload: 'task' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('task.edit', {
            parent: 'task',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_COMPANY_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/task/task-dialog.html',
                    controller: 'TaskDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Task', function(Task) {
                            return Task.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('task', null, { reload: 'task' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('task.delete', {
            parent: 'task',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_COMPANY_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/task/task-delete-dialog.html',
                    controller: 'TaskDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Task', function(Task) {
                            return Task.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('task', null, { reload: 'task' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('project-detail.task-edit', {
            parent: 'project-detail',
            url: '/phase/{phaseId}/task/edit/{taskId}',
            data: {
                authorities: ['ROLE_COMPANY_ADMIN', 'PROJECT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/task/task-dialog.html',
                    controller: 'TaskDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Task', function(Task) {
                            return Task.get({id : $stateParams.taskId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('project-detail.task-new', {
            parent: 'project-detail',
            url: '/phase/{phaseId}/task/new',
            data: {
                authorities: ['ROLE_COMPANY_ADMIN', 'PROJECT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/task/task-dialog.html',
                    controller: 'TaskDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                subject: null,
                                description: null,
                                start: null,
                                end: null,
                                allDay: false,
                                id: null,
                                useRecurrence:false,
                                freq: null,
                                count: null,
                                interval: null,
                                assignedTo: null,
                                projectPhase: {
                                    id: $stateParams.phaseId
                                }
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('project-detail.task-delete', {
            parent: 'project-detail',
            url: '/phase/{phaseId}/task/delete/{taskId}',
            data: {
                authorities: ['ROLE_COMPANY_ADMIN', 'PROJECT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/task/task-delete-dialog.html',
                    controller: 'TaskDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Task', function(Task) {
                            return Task.get({id : $stateParams.taskId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('root-cause-analysis-detail.newCorrectiveTask', {
            parent: 'root-cause-analysis-detail',
            url: '/newcorrectivetask',
            data: {
                authorities: ['ROLE_COMPANY_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/task/task-dialog.html',
                    controller: 'TaskDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                subject: null,
                                description: null,
                                start: null,
                                end: null,
                                allDay: false,
                                id: null,
                                useRecurrence:false,
                                freq: null,
                                count: null,
                                interval: null,
                                assignedTo: null,
                                linkAsCorrectiveTaskToRootCauseAnalysisId: $stateParams.id
                            };
                        },

                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
