(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('ForcedPasswordChangeController', ForcedPasswordChangeController);

    ForcedPasswordChangeController.$inject = ['ForcedPasswordChange', 'Principal', '$state', '$injector','$rootScope', '$uibModal'];

    function ForcedPasswordChangeController (ForcedPasswordChange, Principal, $state, $injector, $rootScope, $uibModal) {
        var vm = this;

        vm.error = null;

        vm.passwordChange = {
            oldPassword:"",
            newPassword:""
        };


        Principal.identity().then(function(account) {
            vm.account = account;
        });

        $rootScope.$on('$stateChangeStart',
            function(event){
                if (event.currentScope.fromState.name ==='forced-password-change'){
                    var authenticated = Principal.isAuthenticated();
                    if (authenticated){
                        if (vm.account.forcedPasswordChangeOnNextLogin){
                            event.preventDefault();
                        }
                    }
                }
            });

        vm.changePassword = function() {

            vm.confirmationMatch = vm.password !== vm.confirmPassword;
            vm.error = null;

            if (!vm.confirmationMatch){
                return;
            }

            ForcedPasswordChange.changePassword(vm.passwordChange, onSuccess, onError);

            function onSuccess() {
                showDoneDialog();
            }

            function onError(result) {
                vm.error = result.data.error;
            }

        }

        function showDoneDialog(){
            $uibModal.open({
                templateUrl: 'app/account/forced-password-change/password-change-done-dialog.html',
                controller: 'PasswordChangeDoneController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md'
            }).result.then(function () {
                var Auth = $injector.get('Auth');
                Auth.logout();
                vm.account.forcedPasswordChangeOnNextLogin = false;
                $state.go('home', {reload: false})
            });
        }
    }

    angular.module('abcQualityMatchApp')
        .controller('PasswordChangeDoneController', PasswordChangeDoneController);

    PasswordChangeDoneController.$inject = ['$uibModalInstance'];

    function PasswordChangeDoneController($uibModalInstance) {
        var vm = this;
        vm.close = function() {
            $uibModalInstance.close(true);
        }
    }
})();
