(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TicketStateDetailController', TicketStateDetailController);

    TicketStateDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TicketState', 'Company', 'ticketType'];

    function TicketStateDetailController($scope, $rootScope, $stateParams, previousState, entity, TicketState, Company, ticketType) {
        var vm = this;

        vm.ticketState = entity;
        vm.previousState = previousState.name;
        vm.type = ticketType.type;
        console.log("TicketType");
        console.log(ticketType);

        var unsubscribe = $rootScope.$on('abcQualityMatchApp:ticketStateUpdate', function(event, result) {
            vm.ticketState = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
