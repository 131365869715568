(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TicketStateDialogController', TicketStateDialogController);

    TicketStateDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TicketState', 'ticketType'];

    function TicketStateDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TicketState, ticketType) {
        var vm = this;

        vm.ticketState = entity;
        vm.clear = clear;
        vm.save = save;
        vm.type = ticketType.type;
        console.log("TicketType");
        console.log(ticketType);

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.ticketState.id !== null) {
                TicketState.update(vm.ticketState, onSaveSuccess, onSaveError);
            } else {
                TicketState.save(vm.ticketState, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:ticketStateUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
