(function () {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .component('questionnaireAnswerFreetextQuestion', {
            templateUrl: 'app/components/questionnaire-answer-freetext-question/questionnaire-answer-freetext-question.html',
            bindings: {
                Task: '<',
                question: '<',
                answer: '=',
                viewonly: '<',
                onAnswerChange: '&',
                deleteAttachment: '&',
                questionnaire: '<',
                editForm: '<?'
            },
            controllerAs: 'vm',
            controller: QuestionnaireAnswerFreetextQuestion
        });

    QuestionnaireAnswerFreetextQuestion.$inject = ['QuestionnaireUtils', '$scope', '$uibModal'];
    function QuestionnaireAnswerFreetextQuestion(QuestionnaireUtils, $scope, $uibModal) {

        var vm = this;

        if (vm.viewonly) {
            vm.inputName = '' + vm.question.id + '-view';
        }
        else{
            vm.inputName = '' + vm.question.id + '-answerInput';
        }

        vm.rowCount = 5;
        if (vm.question.size === 'LONG') {
            vm.rowCount = 15;
        }

        vm.deleteAttachment = function(answer, attachment) {
            console.log('delete attachment attachment ->', attachment);
            console.log('delete attachment answer ->', answer);
            $scope.$emit("abcQualityMatchApp:deleteAttachment",{answer: answer, attachment: attachment});
        }

        vm.addAttachment = function(answer) {
            console.log('Lisää modalin avaus ', answer);            
            $uibModal.open({
                templateUrl: 'app/components/questionnaire-answer-attachment/answer-attachment-dialog.html',
                controller: 'AnswerAttachmentDialogController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                resolve:{}
            }).result.then(function(result) {
                console.log('add attachment dialog closing ', result);
                console.log('answer', answer);
                if (!answer.attachments) {
                    answer.attachments=[];    
                }
                answer.attachments.push(
                    {
                        id: null,
                        storageFile: result
                    }
                )
            });
        }

        vm.answerInputChange = function () {
            var answerCausesException = false;
            if (vm.questionnaire.questionnaireType === "NORMAL") {
                answerCausesException = !!vm.question.answer.anomalyCategory;
            }

            vm.question.answer.anomaly = answerCausesException || !!vm.question.answer.anomalyCategory;
            vm.question.answer.isAnswered = QuestionnaireUtils.checkIfAnswered(vm.question);
            vm.onAnswerChange();
        };
    }

})();
