(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('document-tree', {
            parent: 'document_menu',
            url: '/document-tree?openFolderIds&showDeleted',
            data: {
                authorities: ['ROLE_COMPANY_USER'],
                pageTitle: 'abcQualityMatchApp.documentFolder.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/document/document-tree/document-tree.html',
                    controller: 'DocumentTreeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                openFolderIds: ['$stateParams', function($stateParams){ return $stateParams.openFolderIds;}],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('documentFile');
                    $translatePartialLoader.addPart('documentFolder');
                    $translatePartialLoader.addPart('document');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('document-tree.file-versioning', {
            parent: 'document-tree',
            url: '/file/versioning/{rootVersionId}?tab',
            data: {
                authorities: ['ROLE_COMPANY_USER'],
                pageTitle: 'abcQualityMatchApp.documentFile.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/document/document-tree/document-file/document-file-detail.html',
                    controller: 'DocumentFileDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('documentFile');
                    $translatePartialLoader.addPart('documentFolder');
                    $translatePartialLoader.addPart('document');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                documentFileVersioning: ['$stateParams', 'DocumentFile', function($stateParams, DocumentFile) {
                    return DocumentFile.getVersioningForRootId({rootVersionId : $stateParams.rootVersionId}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'document-tree',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('document-tree-view', {
            parent: 'document_menu',
            url: '/document-tree-view?openFolderIds',
            data: {
                authorities: ['ROLE_COMPANY_USER'],
                pageTitle: 'abcQualityMatchApp.documentFolder.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/document/document-tree/tree-view/document-tree-view.html',
                    controller: 'DocumentTreeViewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                openFolderIds: ['$stateParams', function($stateParams){ return $stateParams.openFolderIds;}],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('documentFile');
                    $translatePartialLoader.addPart('documentFolder');
                    $translatePartialLoader.addPart('document');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        ;
    }

})();
