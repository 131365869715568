(function () {
    'use strict';
    angular
            .module('abcQualityMatchApp')
            .factory('DocumentFile', DocumentFile);

    DocumentFile.$inject = ['$resource','DateUtils'];

    function DocumentFile($resource, DateUtils) {
        var resourceUrl = 'api/document-files/:id';

        function convertDatesFromServer(documentFile){
            if (documentFile) {
                documentFile = angular.fromJson(documentFile);
                documentFile.validityEndDate = DateUtils.convertLocalDateFromServer(documentFile.validityEndDate);
                documentFile.validityEndAlertDate = DateUtils.convertLocalDateFromServer(documentFile.validityEndAlertDate);
                documentFile.approvalDate = DateUtils.convertLocalDateFromServer(documentFile.approvalDate);
                documentFile.inspectionDate = DateUtils.convertLocalDateFromServer(documentFile.inspectionDate);
                documentFile.lastReviewDate = DateUtils.convertLocalDateFromServer(documentFile.lastReviewDate);
                documentFile.publishedDate = DateUtils.convertLocalDateFromServer(documentFile.publishedDate);
            }
            return documentFile;
        }

        function converDateToServer(documentFileSaveDTO){
            var copy = angular.copy(documentFileSaveDTO);
            copy.documentFile.validityEndDate = DateUtils.convertLocalDateToServer(documentFileSaveDTO.documentFile.validityEndDate);
            copy.documentFile.validityEndAlertDate = DateUtils.convertLocalDateToServer(documentFileSaveDTO.documentFile.validityEndAlertDate);
            copy.documentFile.approvalDate = DateUtils.convertLocalDateToServer(documentFileSaveDTO.documentFile.approvalDate);
            copy.documentFile.inspectionDate = DateUtils.convertLocalDateToServer(documentFileSaveDTO.documentFile.inspectionDate);
            copy.documentFile.lastReviewDate = DateUtils.convertLocalDateToServer(documentFileSaveDTO.documentFile.lastReviewDate);
            copy.documentFile.publishedDate = DateUtils.convertLocalDateToServer(documentFileSaveDTO.documentFile.publishedDate);
            return angular.toJson(copy);
        }

        return $resource(resourceUrl, {},{
            'get': {
                method: 'GET',
                transformResponse: convertDatesFromServer
            },
            'update': {
                method: 'PUT',
                transformRequest:converDateToServer
            },
            'undelete': {
                url: 'api/document-files/undelete',
                method: 'PUT'},
            'save': {
                method: 'POST',
                transformRequest:converDateToServer
            },
            'createNewVersion': {
                method: 'PUT',
                url: 'api/document-files/new-version',
                transformRequest:converDateToServer
            },
            'getVersioningForRootId': {
                method:'GET',
                url:'api/document-files/versioning/:rootVersionId',
                transformResponse: function(versioningDTO){
                    if (versioningDTO){
                        versioningDTO = angular.fromJson(versioningDTO);
                        versioningDTO.nextVersion = convertDatesFromServer(versioningDTO.nextVersion);
                        versioningDTO.currentVersion = convertDatesFromServer(versioningDTO.currentVersion);
                    }
                    return versioningDTO;
                }
            },
            'getNotableFiles':{
                method: 'GET',
                url: 'api/document-files/notable-files'
            },
            'stateChange':{
                method: 'POST',
                url: 'api/document-files/state-change'
            }
        });
    }
})();
