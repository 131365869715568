(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TicketStateController', TicketStateController);

    TicketStateController.$inject = ['$scope', '$state', 'TicketState', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'ticketType', 'TicketStateTypeList'];

    function TicketStateController ($scope, $state, TicketState, ParseLinks, AlertService, paginationConstants, pagingParams, ticketType, TicketStateTypeList) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.type = ticketType.type;
        console.log("TicketType");
        console.log(ticketType);

        loadAll();

        function loadAll () {
            TicketStateTypeList.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                ticketType: vm.type
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.ticketStates = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
