(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('CompanySupervisorHomeController', CompanySupervisorHomeController);

    CompanySupervisorHomeController.$inject = [];

    function CompanySupervisorHomeController () {
        var vm = this;        
    }
})();
