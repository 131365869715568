(function () {
    'use strict';
    angular
            .module('abcQualityMatchApp')
            .factory('QuestionnaireCompany', QuestionnaireCompany);

    QuestionnaireCompany.$inject = ['$resource'];

    function QuestionnaireCompany($resource) {
        var resourceUrl = 'api/questionnaires/';

        return {
            Query: $resource(resourceUrl + 'company', {}, {'query': {method: 'GET', isArray: true}}),
            Copy: $resource(resourceUrl + 'company/copy/:id', {}, {'copy': {method: 'POST', isArray: false}}),
            CopyToEditForCompany: $resource(resourceUrl + 'company/copy-to-edit-for-company/:id', {}, {'copytoeditforcompany': {method: 'GET', isArray: false}}),
            CopyToEditForAdmin: $resource(resourceUrl + 'company/copy-to-edit-for-admin/:id', {}, {'copytoeditforadmin': {method: 'GET', isArray: false}}),
            QueryByType: $resource(resourceUrl + 'company/:questionnaireType', {}, {'query': {method: 'GET', isArray: true}}),
            QueryByTypes: $resource(resourceUrl + 'company/types', {}, {'query': {method: 'GET', isArray: true}})
        };
    }
})();
