(function () {
    'use strict';
    angular.module('abcQualityMatchApp')
           .factory('TrainingEvent', TrainingEvent);
    TrainingEvent.$inject = ['$resource', 'DateUtils'];
    function TrainingEvent($resource, DateUtils) {
        var resourceUrl = 'api/training-events/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.start = DateUtils.convertDateTimeFromServer(data.start);
                        data.end = DateUtils.convertDateTimeFromServer(data.end);
                        data.validToDate = DateUtils.convertDateTimeFromServer(data.validToDate);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'getAvailableForSignUp': {
                url: 'api/training-events-available_for_signup',
                method: 'GET',
                isArray: true
            },
            'getIndependentTrainingAvailableForSignUp': {
                url: 'api/training-events/independent-studies-available-for-user',
                method: 'GET',
                isArray: true},
        });
    }

    angular.module('abcQualityMatchApp').filter('trainingEventViewFormat', ['dateFilter',function (dateFilter) {
        return function (trainingEvent) {
            var start = dateFilter(trainingEvent.start,'short');
            var end = '';
            if (trainingEvent.end){
                end = dateFilter(trainingEvent.end,'short');
            }
            return trainingEvent.training.name + ' ' + start + ' - ' + end ;
        };
    }]);


angular
        .module('abcQualityMatchApp')
        .factory('TrainingEventSignup', TrainingEventSignup);

    TrainingEventSignup.$inject = ['$resource', 'DateUtils'];

    function TrainingEventSignup ($resource, DateUtils) {
        var resourceUrl =  'api/training-event-signups/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.signupDate = DateUtils.convertDateTimeFromServer(data.signupDate);
                    }
                    return data;
                }
            }
        });
    }

})();
