(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('abcQualityMatchApp')
        .constant('VERSION', "2021-11-04-SNAPSHOT")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('ENV', "prod")
;
})();
