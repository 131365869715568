(function () {
    'use strict';

    angular
            .module('abcQualityMatchApp')
            .controller('TaskListController', TaskListController);

    TaskListController.$inject = ['$scope', '$state', 'TaskEvent', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Principal'];

    function TaskListController($scope, $state, TaskEvent, ParseLinks, AlertService, paginationConstants, pagingParams, Principal) {
        var vm = this;
        window.vvm = vm;
        vm.account = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        getAccount();
        loadAll();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
            });
        }

        function loadAll () {
            TaskEvent.Company.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== '(start_time)') {
                    result.push('(start_time)');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.taskEvents = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        vm.confirm = function (taskId, recurrenceDateId) {
            TaskEvent.Confirm.confirm({"taskId": taskId, "recurrenceDateId": recurrenceDateId}, onSuccess, onError);
            function onSuccess(data, headers) {
                $state.reload();
            }
            function onError(error) {
                console.log("failed");
            }
        }
    }
})();
