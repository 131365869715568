(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TraineeRecordController', TraineeRecordController);

    TraineeRecordController.$inject = ['$scope', '$state', 'TraineeRecord', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','TraineeEventSignup'];

    function TraineeRecordController ($scope, $state, TraineeRecord, ParseLinks, AlertService, paginationConstants, pagingParams,TraineeEventSignup) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.trainingEventSignups = [];

        vm.trainingRecordsExamRequired = [];
        vm.trainingRecordsSigningRequired = [];
        vm.deleteSignup = deleteSignup;
        vm.deletingSignup = false;

        loadAll();
        loadSignups();
        loadExamOrSigningRequired();
        
        function loadSignups(){
            TraineeEventSignup.query({}, success, onError);
            
            function success(data){
                vm.trainingEventSignups = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        
        function loadExamOrSigningRequired(){
            TraineeRecord.ExamOrSigningRequired.query({}, success, onError);
            
            function success(data){
                vm.trainingRecordsExamRequired = data.filter(function(tr){return !tr.examPassed;});
                vm.trainingRecordsSigningRequired = data.filter(function(tr){return tr.examPassed && tr.traineeSignedDate == null;});
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        
        


        function loadAll () {
            TraineeRecord.UserRecords.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
  
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.trainingRecords = data;
                vm.page = pagingParams.page;              
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        
        

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        
        function deleteSignup(trainingEventSignupId){
           TraineeEventSignup.deleteSignup({trainingEventSignupId: trainingEventSignupId}, onSuccess, onFailure);

           function onSuccess(result) {
               vm.deletingSignup = false;
               loadSignups();
           }

           function onFailure(result) {
               vm.deletingSignup  = false;
           }

        }
    }
    
    
    
    
    
    
})();
