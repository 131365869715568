(function() {
    'use strict';
    angular
        .module('abcQualityMatchApp')
        .factory('Product', Product);

    Product.$inject = ['$resource'];

    function Product ($resource) {
        var resourceUrl =  'api/products/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'queryWithArchived': { method: 'GET', isArray: true, url:'/api/products?includeArchived=true'},
            'uploadImportSpreadsheet': {method: 'POST', url: 'api/products/upload-export-sheet'},


        });
    }
})();
