(function() {
    'use strict';

    angular.module('abcQualityMatchApp').component('ticketMaterial', {
          templateUrl: 'app/components/ticket-material/ticket-material.component.html',  
          bindings: {task: '<',
                     ticket: '<'
                     },
          controller: TicketMaterialController
        });

    TicketMaterialController.$inject = ['$scope', 'TicketRelatedMaterial','AlertService']
    function TicketMaterialController(scope, TicketRelatedMaterial, AlertService) {

        var ctrl = this;
        ctrl.ticketMaterials = [];
        
        if (ctrl.task){
            ctrl.ticketId = ctrl.task.ticketId;
        }
        else if (ctrl.ticket){
            ctrl.ticketId = ctrl.ticket.id;
        }

        loadAll();

        function loadAll () {
            TicketRelatedMaterial.Materials.query( {ticketId: ctrl.ticketId}, onSuccess, onError);
            function onSuccess(data) {
                ctrl.ticketMaterials = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }
})();
