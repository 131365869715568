(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('ProjectDetailController', ProjectDetailController);

    ProjectDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Project', 'ProjectPhase', 'Task', 'AlertService', '$translate'];

    function ProjectDetailController($scope, $rootScope, $stateParams, previousState, entity, Project, ProjectPhase, Task, AlertService, $translate) {
        var vm = this;
        window.vvm = vm;

        vm.project = entity;

        vm.previousState = 'project';

        // for making navigation from root cause analysis work at least until user goes opens a dialog on this page
        if ('root-cause-analysis-detail' === previousState.name){
            vm.previousState = previousState.name + "(" + JSON.stringify(previousState.params) +")";
        }
        if ('root-cause-analysis' === previousState.name){
            vm.previousState = previousState.name;
        }
        vm.deletePhase = deletePhase;

        var unsubscribe = $rootScope.$on('abcQualityMatchApp:projectUpdate', function(event, result) {
            vm.project = result;
        });
        $scope.$on('$destroy', unsubscribe);

        loadPhases();

        function loadPhases() {
            vm.project.doneCount = 0;
            vm.project.allCount = 0;
            Project.Phases.query({"id": vm.project.id}, onPhaseSuccess, onPhaseError);

            function onPhaseSuccess(data, headers) {
                vm.project.allCount = data.length;
                vm.phases = data;
                vm.phases.forEach(function (phase) {
                    if (phase.ticket.state.ticketStateType == 'DONE') {
                        vm.project.doneCount++;
                    }
                    phase.doneCount = 0;
                    phase.allCount = 0;
                    Task.getProjectPhaseTasks({"projectPhaseId": phase.id}, onTaskSuccess, onTaskError);
                });
            }
            function onPhaseError(error) {
                console.log("failed");
            }

            function onTaskSuccess(data, headers) {
                if (data != undefined && data != null && data.length > 0) {
                    vm.phases.forEach(function (phase) {
                        if (phase.id == data[0].projectPhase.id) {
                            phase.allCount = data.length;
                            data.forEach(function (task) {
                                if (task.mainTicket.state.ticketStateType == 'DONE') {
                                    phase.doneCount++;
                                }
                            });
                            phase.tasks = data;
                        }
                    });
                }

            }
            function onTaskError(error) {
                console.log("failed");
            }
        }

        function deletePhase(phase) {
            ProjectPhase.delete(phase, onSuccess, onError);

            function onSuccess(phase) {
                console.log('deleted project phase');
                loadPhases();
            }
            function onError(err) {
                console.log("failed to delete project phase " + err);
                AlertService.error($translate.instant("abcQualityMatchApp.projectPhase.delete.fail"));
            }
        }

    }
})();
