(function() {
    'use strict';
    angular.module('abcQualityMatchApp').component('informationEventSelect', {
          templateUrl: 'app/components/information-select/information-select-component.html',
          restrict: 'E',
          bindings: { onSelect: '&' },
          controller: InformationSelectController
        });

    InformationSelectController.$inject = ['$scope', 'Information','AlertService', '$http']
    function InformationSelectController(scope, Information, AlertService, $http) {
        var ctrl = this;

        this.selected = null;

        ctrl.getInformation = function(val){
            return Information.informationEventsQuery({searchTerm: val,
                                             sort:'submit_date,desc&sort=id'
            }).$promise;
        }

        ctrl.setSelected = function(){
            console.log("selected " + JSON.stringify(this.selected));
            this.onSelect({selected: this.selected});
            this.selected = null;
        }
    }




})();
