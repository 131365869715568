(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TraineeExamDialogController', TraineeExamDialogController);

    TraineeExamDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TrainingRecord', 'TrainingEvent', 'User', 'Company'];

    function TraineeExamDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TrainingRecord, TrainingEvent, User, Company) {
        var vm = this;

        vm.trainingRecord = entity;
        vm.checkExam = checkExam;
        vm.clear = clear;
        vm.examCheckPassed = null;


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function checkExam(){
            vm.isSaving = false;
            vm.examCheckPassed = null;
            
            var numberOfCorrectAnswers = 0;
            
            vm.trainingRecord.trainingEvent.training.examQuestions.forEach(function(examQuestion){
                if (examQuestion.answer === examQuestion.expectedAnswer){
                        ++numberOfCorrectAnswers;
                }
            });
            
            var numberOfQuestions = vm.trainingRecord.trainingEvent.training.examQuestions.length;
            var correctPercent = 100;
            
            if (numberOfQuestions > 0){
                correctPercent = Math.round(numberOfCorrectAnswers / numberOfQuestions * 100); 
            }
            var passed = true;
            
            if (vm.trainingRecord.trainingEvent.training.examPassRequiredPercent){
                passed = correctPercent >= vm.trainingRecord.trainingEvent.training.examPassRequiredPercent;
            }

            if (passed){
                vm.examCheckPassed = true;
                vm.trainingRecord.examPassed = true;
                TrainingRecord.update(vm.trainingRecord, onSaveSuccess, onSaveError);
            }else {
                vm.examCheckPassed = false;
                $timeout(function () { vm.examCheckPassed = null; }, 3000); 
                
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:trainingRecordUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
