(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TaskExecutionCountsController', TaskExecutionCountsController);

    TaskExecutionCountsController.$inject = [
        '$scope', '$state', 'Task',
        'ParseLinks', 'AlertService', 'paginationConstants',
        'pagingParams', '$uibModal', 'DateUtils'];

    function TaskExecutionCountsController (
        $scope, $state, Task,
        ParseLinks, AlertService, paginationConstants,
        pagingParams, $uibModal, DateUtils) {

        var vm = this;
        window.vvm = vm;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {fromDate: false, toDate:false };

        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.taskExecutions = null;

        vm.filtering = {
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate
        };

        if (!vm.filtering.fromDate){
            var firstOfJanuary = new Date();
            firstOfJanuary.setMonth(0);
            firstOfJanuary.setDate(1);
            //defaulting to first day of this year
            vm.filtering.fromDate = firstOfJanuary;
        }
        if (!vm.filtering.toDate){
            vm.filtering.toDate = new Date();
        }


        loadAll();

        function loadAll () {

            var parameters = {
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            };

            parameters = addFilteringParameters(parameters);

            Task.getExecutionCounts(parameters, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'task_id') {
                    result.push('task_id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.taskExecutions = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function addFilteringParameters(parameters) {
            parameters.fromDate = null;
            if (vm.filtering.fromDate) {
                parameters.fromDate = DateUtils.convertLocalDateToServer(vm.filtering.fromDate);
            }

            parameters.toDate = null;
            if (vm.filtering.toDate) {
                parameters.toDate = DateUtils.convertLocalDateToServer(vm.filtering.toDate);
            }
            return parameters;
        }

        vm.refreshSearch = function(){
            loadPage(1);
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {

            var parameters = {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            };
            parameters = addFilteringParameters(parameters);

            $state.transitionTo($state.$current, parameters);
        }

        vm.getParametersForTaskVerification = function(taskId){
            var parameters = {taskId: taskId};

            if (vm.filtering.fromDate) {
                parameters.fromDate = DateUtils.convertLocalDateToServer(vm.filtering.fromDate);
            }

            parameters.toDate = null;
            if (vm.filtering.toDate) {
                parameters.toDate = DateUtils.convertLocalDateToServer(vm.filtering.toDate);
            }
            return parameters;
        };

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
