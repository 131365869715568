(function() {
    'use strict';
    angular
        .module('abcQualityMatchApp')
        .factory('TrainingEventPrepare', TrainingEventPrepare);

    TrainingEventPrepare.$inject = ['$resource', 'DateUtils'];

    function TrainingEventPrepare ($resource, DateUtils) {
        var resourceUrl =  'api/training-events-preparenew';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                params: {trainingId: '@trainingId'},
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.start = DateUtils.convertDateTimeFromServer(data.start);
                        data.end = DateUtils.convertDateTimeFromServer(data.end);
                    }
                    return data;
                }
            },
        });
    }
    
    angular
        .module('abcQualityMatchApp')
        .factory('TrainingEventEnder', TrainingEventEnder);

    TrainingEventEnder.$inject = ['$resource', 'DateUtils'];
    
    function TrainingEventEnder ($resource, DateUtils) {
        var resourceUrl =  'api/training-events-end-event';

        return $resource(resourceUrl, {}, {
            'update': { 
                method:'PUT' 
            }
        });
    }
    
    
    angular
        .module('abcQualityMatchApp')
        .factory('TrainingEventProgress', TrainingEventProgress);

    TrainingEventProgress.$inject = ['$resource', 'DateUtils'];
    
    function TrainingEventProgress ($resource, DateUtils) {
        var resourceUrl =  'api/training-events-update-progress';

        return $resource(resourceUrl, {}, {
            'update': { 
                method:'PUT' 
            }
        });
    }
    
    
    
    angular
        .module('abcQualityMatchApp')
        .factory('TrainingEventSignupList', TrainingEventSignupList);

    TrainingEventSignupList.$inject = ['$resource', 'DateUtils'];
    function TrainingEventSignupList ($resource) {
        var resourceUrl =  'api/training-event-signups/for-event/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
        
})();
