(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TrainingEventInstructorViewController', TrainingEventInstructorViewController);

    TrainingEventInstructorViewController.$inject = ['$translate','$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TrainingEventProgress', 'TrainingEventEnder'];

    function TrainingEventInstructorViewController ($translate, $timeout, $scope, $stateParams, $uibModalInstance, entity, TrainingEventProgress, TrainingEventEnder) {
        var vm = this;

        vm.trainingEvent = entity;
        vm.clear = clear;
        vm.save = save;
        vm.endTraining = false;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            
            if (vm.endTraining){
                TrainingEventEnder.update(vm.trainingEvent, onSaveSuccess, onSaveError);
            }
            else{
                TrainingEventProgress.update(vm.trainingEvent, onSaveSuccess, onSaveError); 
            }
        }
        
        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:trainingEventUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }
        
        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
