(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('QuestionnaireGroupDialogController', QuestionnaireGroupDialogController);

    QuestionnaireGroupDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'QuestionnaireGroup', 'Company', 'Questionnaire'];

    function QuestionnaireGroupDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, QuestionnaireGroup, Company, Questionnaire) {
        var vm = this;

        vm.questionnaireGroup = entity;
        vm.clear = clear;
        vm.save = save;
        vm.companies = Company.query({"size": 1000, "sort":"name,asc"});

        vm.newQuestionnaire = null;
        vm.newCompany = null;
        vm.templateQuestionnaires = Questionnaire.query({"size": 1000, "sort":"title,asc"});

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.questionnaireGroup.id !== null) {
                QuestionnaireGroup.update(vm.questionnaireGroup, onSaveSuccess, onSaveError);
            } else {
                QuestionnaireGroup.save(vm.questionnaireGroup, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:questionnaireGroupUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.removeQuestionnaire = function(questionnaire){

            var index = null;
            for (var i = 0; i<vm.questionnaireGroup.questionnaires.length; ++i){
                if (vm.questionnaireGroup.questionnaires[i].id == questionnaire.id){
                    index = i;
                }
            }
            if (index != null) {
                vm.questionnaireGroup.questionnaires.splice(index, 1);
            }
        };

        vm.addQuestionnaire = function(){

            if (!vm.newQuestionnaire){
                return;
            }

            var found = false;

            vm.questionnaireGroup.questionnaires.forEach(function(qn){
                if (qn.id == vm.newQuestionnaire.id){
                    found = true;
                } });

            if (!found){
                vm.questionnaireGroup.questionnaires.push(vm.newQuestionnaire);
            }
            vm.newQuestionnaire = null;
        };


        vm.removeCompany = function(company){

            var index = null;
            for (var i = 0; i< vm.questionnaireGroup.companies.length; ++i){
                if (vm.questionnaireGroup.companies[i].id == company.id){
                    index = i;
                }
            }
            if (index != null) {
                vm.questionnaireGroup.companies.splice(index, 1);
            }
        };

        vm.addCompany = function(){

            if (!vm.newCompany){
                return;
            }

            var found = false;

            vm.questionnaireGroup.companies.forEach(function(company){
                if (company.id == vm.newCompany.id){
                    found = true;
                } });

            if (!found){
                vm.questionnaireGroup.companies.push(vm.newCompany);
            }
            vm.newCompany = null;
        };

    }
})();
