(function() {
    'use strict';
    angular.module('abcQualityMatchApp').component('questionnaireAnswer', {
        templateUrl: 'app/components/questionnaire-answer/questionnaire-answer.html',
        bindings: {
            questionnaire:"=",
            comparisons: '<',
            viewonly: '<',
            onAnswerChange: '&',
            editForm: '<?'
        },
        controllerAs: 'vm',
        controller: QuestionnaireAnswerComponent
    });

    QuestionnaireAnswerComponent.$inject=['$location', '$anchorScroll','QuestionnaireUtils'];
    function QuestionnaireAnswerComponent($location, $anchorScroll, QuestionnaireUtils) {

        var vm = this;
        vm.showAnswered = true;
        vm.currentSection = 0;
        vm.hasPrevSection = false;
        vm.hasNextSection = false;

        vm.$onInit = function() {
            vm.questionnaire.sections.forEach(function(section) {
                section.questions.forEach(function(question){
                    question.answer.isAnswered = QuestionnaireUtils.checkIfAnswered(question);
                });
            });
            updateHasNextPreviousSectionEnabled();
        };

        vm.goToQuestion = function(section, question){
            vm.currentSection = getSectionIndex(section);
            $location.hash("question"+question.id);
            $anchorScroll();
            updateHasNextPreviousSectionEnabled();
        };

        vm.goToSection = function (section){
            vm.currentSection = getSectionIndex(section);
            updateHasNextPreviousSectionEnabled();
        };

        function getSectionIndex(section){
            for (var i = 0; i < vm.questionnaire.sections.length; i++) {
                if(vm.questionnaire.sections[i].id === section.id){
                    return i;
                }
            }
            console.log('error section not found');
            return 0;
        }

        vm.nextSection = function () {
            if (vm.hasNextSection) {
                vm.currentSection++;
            }
            updateHasNextPreviousSectionEnabled();
        };

        vm.prevSection = function () {
            if (vm.hasPrevSection) {
                vm.currentSection--;
            }
            updateHasNextPreviousSectionEnabled();
        };

        function updateHasNextPreviousSectionEnabled(){
            vm.hasPrevSection = vm.currentSection > 0;
            vm.hasNextSection = vm.currentSection < vm.questionnaire.sections.length - 1;
        }
    }
})();
