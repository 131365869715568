(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('RootCauseAnalysisDialogController', RootCauseAnalysisDialogController);

    RootCauseAnalysisDialogController.$inject = ['$timeout',
        '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'RootCauseAnalysis', 'RootCauseAnalysisFactor', 'User', 'Principal',
        'AlertService','Information', 'Anomaly', 'AbcListUtils'];

    function RootCauseAnalysisDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                                    RootCauseAnalysis, RootCauseAnalysisFactor, User, Principal,
                                                    AlertService, Information, Anomaly, AbcListUtils) {
        var vm = this;

        vm.rootCauseAnalysis = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.addCause = addCause;
        vm.moveCause = moveCause;
        vm.deleteCause = deleteCause;
        vm.deleteFactor = deleteFactor;

        vm.deleteInformationEvent = deleteInformationEvent;
        vm.addInformationEvent = addInformationEvent;

        vm.addAnomalyEvent = addAnomalyEvent;
        vm.deleteAnomalyEvent = deleteAnomalyEvent;

        vm.datePickerOpenStatus.created = false;

        loadFactors();






        vm.factorToAdd = null;
        vm.addFactor = addFactor;

        $timeout(function (){
            angular.element('#field_issue').focus();
        });

        if (vm.rootCauseAnalysis.id == null){

            if (entity.linkToInformationIds){
                loadInformationEventsForLinking(entity.linkToInformationIds);
            }
            if (entity.linkToAnomalyEventIds){
                loadAnomalyEventsForLinking(entity.linkToAnomalyEventIds);
            }

            Principal.identity().then(function (account) {
                vm.rootCauseAnalysis.author = account;
            });

            if (vm.rootCauseAnalysis.causes.length == 0){
                for (var i=0; i < 5; ++i){
                    var cause = { description: '',
                                   listIndex: vm.rootCauseAnalysis.causes.length,
                                 };
                    vm.rootCauseAnalysis.causes.push(cause);
                }
            }
        }

        function loadFactors() {
            RootCauseAnalysisFactor.query({
                page: 0,
                size: 1000,
                sort: ['name,asc','id']
            }, onSuccess, onError);
            function onSuccess(data) {
                vm.factors = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }


        function loadInformationEventsForLinking(ids) {
            Information.informationEventsByIds({ids: ids }, onSuccess, onError);
            function onSuccess(data) {
                vm.rootCauseAnalysis.informationEvents = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadAnomalyEventsForLinking(ids){
            Anomaly.EventsByIds.query({ids: ids }, onSuccess, onError);
            function onSuccess(data) {
                vm.rootCauseAnalysis.anomalyEvents = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }



        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.rootCauseAnalysis.id !== null) {
                RootCauseAnalysis.update(vm.rootCauseAnalysis, onSaveSuccess, onSaveError);
            } else {
                RootCauseAnalysis.save(vm.rootCauseAnalysis, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:rootCauseAnalysisUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }



        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function addCause(){
            var cause = { description: '',
                             listIndex: vm.rootCauseAnalysis.causes.length,
                         };
            vm.rootCauseAnalysis.causes.push(cause);
            return false;
        }

        function deleteCause(index) {
            vm.rootCauseAnalysis.causes.splice(index,1);
            updateListIndexes(vm.rootCauseAnalysis.causes);
            return false;
        }

        function moveCause(index, direction) {
            if(direction === "up") {
                vm.rootCauseAnalysis.causes[index-1].listIndex++;
                vm.rootCauseAnalysis.causes[index].listIndex--;
            }
            else {
                vm.rootCauseAnalysis.causes[index+1].listIndex--;
                vm.rootCauseAnalysis.causes[index].listIndex++;
            }
            vm.rootCauseAnalysis.causes.sort(function(a,b){
                return a.listIndex - b.listIndex;
            });
            updateListIndexes(vm.rootCauseAnalysis.causes);
        }

        function updateListIndexes(list){
            list.forEach(function(currentValue, index, array) {
                currentValue.listIndex = index;
            });
        }

        function deleteFactor(index){
            vm.rootCauseAnalysis.factors.splice(index,1);
        }

        function addFactor(){

            if (!vm.factorToAdd){
                return;
            }
            if (AbcListUtils.findById(vm.factorToAdd.id, vm.rootCauseAnalysis.factors) == null){
                vm.rootCauseAnalysis.factors.push(vm.factorToAdd);
            }
            vm.factorToAdd = null;
        }

        function deleteInformationEvent(index){
            vm.rootCauseAnalysis.informationEvents.splice(index,1);
        }

        function addInformationEvent(selected){
            console.log('addInformationEvent');
            if (selected){



                if (AbcListUtils.findById(selected.id, vm.rootCauseAnalysis.informationEvents) == null){
                    vm.rootCauseAnalysis.informationEvents.push(selected);
                }
            }
        }

        function deleteAnomalyEvent(index){
            vm.rootCauseAnalysis.anomalyEvents.splice(index,1);
        }

        function addAnomalyEvent(selected){
            if (selected){
                var alreadySelected = false;
                vm.rootCauseAnalysis.anomalyEvents.forEach(function(event){
                    if (event.row === selected.row){
                        alreadySelected = true;
                    }
                });


                if (!alreadySelected){
                    vm.rootCauseAnalysis.anomalyEvents.push(selected);
                }
            }
        }
    }
})();
