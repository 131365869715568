(function() {
    'use strict';

    angular.module('abcQualityMatchApp').component('trainingMaterial', {
          templateUrl: 'app/training-main/training/training-material.component.html',  
          bindings: {training: '<'},
          controller: TrainingMaterialController
        });

    TrainingMaterialController.$inject = ['$scope', 'TrainingRelatedMaterial','AlertService']
    function TrainingMaterialController(scope, TrainingRelatedMaterial, AlertService) {

        var ctrl = this;
        ctrl.trainingMaterials = [];

        loadAll();

        function loadAll () {
            TrainingRelatedMaterial.Materials.query( {trainingId: ctrl.training.id}, onSuccess, onError);
            function onSuccess(data) {
                ctrl.trainingMaterials = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }
})();
