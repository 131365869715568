(function() {
    'use strict';

    angular.module('abcQualityMatchApp').component('questionnaireAnswerMultiQuestion', {
        templateUrl: 'app/components/questionnaire-answer-multi-question/questionnaire-answer-multi-question.html',
        bindings: {
            question: '=',
            viewonly: '<',
            onAnswerChange: '&',
            questionnaire: '<',
            editForm: '<?'
        },
        controllerAs: 'vm',
        controller: QuestionnaireAnswerMultiQuestionComponent
    });

    QuestionnaireAnswerMultiQuestionComponent.$inject = ['AbcListUtils', 'QuestionnaireUtils', '$scope', '$uibModal'];
    function QuestionnaireAnswerMultiQuestionComponent(AbcListUtils, QuestionnaireUtils, $scope, $uibModal) {

        var vm = this;

        window.foo = vm;
        vm.radioButtonSelectedOptionId = null;
        vm.dropDownSelectedOption = null;
        vm.singleButtonSelectedIndex = null;

        vm.checkBoxSelectionChange = checkBoxSelectionChange;
        vm.radioButtonChange = radioButtonChange;
        vm.singleButtonClick = singleButtonClick;
        vm.dropdownSelectionChange = dropdownSelectionChange;
        vm.answerInputChange = answerInputChange;

        vm.$onInit = function() {
            if (vm.question.renderType === "DROPDOWN") {
                if (vm.question.answer.optionAnswers[0]){
                    vm.dropDownSelectedOption = vm.question.answer.optionAnswers[0].choice;
                }
                else if (shouldSetDefaultSelection()){
                    vm.question.options.forEach(function(opt) {
                        if (opt.defaultSelection){
                            vm.dropDownSelectedOption = opt;
                        }
                    });
                    dropdownSelectionChange();
                }
            }
            else if (vm.question.renderType === "RADIOBUTTON") {
                if (vm.question.answer.optionAnswers[0]){
                    vm.radioButtonSelectedOptionId = vm.question.answer.optionAnswers[0].choice.id;
                }
                else if (shouldSetDefaultSelection()){
                    vm.question.options.forEach(function(opt) {
                        if (opt.defaultSelection){
                            vm.radioButtonSelectedOptionId = opt.id;
                        }
                    });
                    radioButtonChange();
                }
            }
            else if (vm.question.renderType === "CHECKBOX"){
                vm.question.options.forEach(function(opt) {
                    opt.selected = false;
                    vm.question.answer.optionAnswers.forEach(function (optAnswer) {
                        if (optAnswer.choice.id === opt.id){
                            opt.selected = true;
                        }
                    });
                });

                if (shouldSetDefaultSelection()){
                    vm.question.options.forEach(function(opt) {
                        opt.selected = opt.defaultSelection;
                    });
                    checkBoxSelectionChange();
                }
            }
            else if(vm.question.renderType === 'SINGLE_BUTTON'){
                AbcListUtils.stableSortByListIndex(vm.question.options);
                vm.singleButtonSelectedIndex = 0;

                if (vm.question.answer.optionAnswers[0]){
                    vm.question.options.forEach(function(opt, index) {
                        if (vm.question.answer.optionAnswers[0].choice.id === opt.id){
                            vm.singleButtonSelectedIndex = index;
                        }
                    });
                }
                if (shouldSetDefaultSelection()){
                    vm.question.options.forEach(function(opt, index) {
                        if (opt.defaultSelection) {
                            vm.singleButtonSelectedIndex = index;
                        }
                    });
                }
                setSingleButtonSelectionIndex(vm.singleButtonSelectedIndex);
            }
            else{
                console.log("unknown render type "+ vm.question.renderType);
            }

            vm.answerInputChange();
        };

        function shouldSetDefaultSelection(){
            return !vm.question.answer.id && !vm.viewonly && vm.question.answer.optionAnswers.length === 0;
        }

        function dropdownSelectionChange(){
            if (!vm.dropDownSelectedOption){
                return;
            }
            vm.question.answer.optionAnswers = [];
            vm.question.answer.optionAnswers.push(createAnswerOption(vm.dropDownSelectedOption));
            vm.answerInputChange();
        }

        function checkBoxSelectionChange(){
            vm.question.answer.optionAnswers = [];
            vm.question.options.forEach(function(opt) {
                if (opt.selected){
                    vm.question.answer.optionAnswers.push(createAnswerOption(opt));
                }
            });
            vm.answerInputChange();
        }

        function radioButtonChange() {
            vm.question.answer.optionAnswers = [];
            vm.question.options.forEach(function(opt) {
                if (opt.id === vm.radioButtonSelectedOptionId){
                    vm.question.answer.isAnswered=true;
                    vm.question.answer.optionAnswers.push(createAnswerOption(opt));
                }
            });
            vm.answerInputChange();
        }

        function answerInputChange(){
            var answerCausesException = false;
            if (vm.questionnaire.questionnaireType === "NORMAL" && vm.question.answer.optionAnswers.length > 0) {
                vm.question.answer.optionAnswers.forEach(function (optAnswer){
                    if (optAnswer.choice.anomalyOption ) {
                        answerCausesException = true;
                    }
                });
            }

            vm.question.answer.anomaly = answerCausesException || !!vm.question.answer.anomalyCategory;
            vm.question.answer.isAnswered = QuestionnaireUtils.checkIfAnswered(vm.question);
            vm.onAnswerChange();
        }

        function singleButtonClick() {
            setSingleButtonSelectionIndex(vm.singleButtonSelectedIndex + 1);
            vm.answerInputChange();
        }

        function setSingleButtonSelectionIndex(index) {
            if (index >= vm.question.options.length) {
                index = 0;
            }
            vm.singleButtonSelectedIndex = index;
            vm.question.answer.optionAnswers = [];
            vm.question.answer.optionAnswers.push(createAnswerOption(vm.question.options[index]));
        }

        function createAnswerOption(questionOption){
            var optionAnswer = {};
            optionAnswer.id = null;
            optionAnswer.writeIn = null;
            optionAnswer.choice = questionOption;
            return optionAnswer;
        }

        vm.deleteAttachment = function(answer, attachment) {
            $scope.$emit("abcQualityMatchApp:deleteAttachment",{answer: answer, attachment: attachment});
        }

        vm.addAttachment = function(answer) {      
            $uibModal.open({
                templateUrl: 'app/components/questionnaire-answer-attachment/answer-attachment-dialog.html',
                controller: 'AnswerAttachmentDialogController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                resolve:{}
            }).result.then(function(result) {
                console.log('add attachment dialog closing ', result);
                console.log('answer', answer);
                if (!answer.attachments) {
                    answer.attachments=[];    
                }
                answer.attachments.push(
                    {
                        id: null,
                        storageFile: result
                    }
                )
            });
        }
    }
})();
