(function () {
    'use strict';

    angular
            .module('abcQualityMatchApp').filter('ticketMaterialEncodeFilename', function () {

        return function (name) {
            return  window.encodeURIComponent(name)
        };
    });

    angular
        .module('abcQualityMatchApp').filter('ticketMaterialLinkUrlAddProtocoll', function() {
            return function(url) {
                if (!url.match('^.+://')){
                    url = 'http://' + url;
                }
                return url;
            }
        });

    angular
            .module('abcQualityMatchApp')
            .controller('InformationDialogController', InformationDialogController);

    InformationDialogController.$inject = [
        '$scope', 'Principal', '$state', '$anchorScroll','$location',
        'newInformationQuestionnaire',
        'hiddenParams', '$log',
        'TicketStateTypeList', 'Product', 'ProductLine',
        'User', 'Information',
        'UserGroup',
        'ReasonCode', 'uiDatetimePickerConfig', '$translate',
        'TicketRelatedMaterial', 'TicketMaterial', 'DataUtils',
        'informationDialogLoadDTO', 'IssueSignificance','AlertService'];

    function InformationDialogController(
        $scope, Principal, $state, $anchorScroll,$location,
        newInformationQuestionnaire,
        hiddenParams, $log,
        TicketStateTypeList, Product, ProductLine,
        User, Information,
        UserGroup,
        ReasonCode, uiDatetimePickerConfig, $translate,
        TicketRelatedMaterial, TicketMaterial, DataUtils,
        informationDialogLoadDTO, IssueSignificance, AlertService) {

        var vm = this;
        window.vvm = vm;
        $location.hash('page_top');
        $anchorScroll();
        vm.returnToView = 'home';
        if (hiddenParams.returnToView){
            vm.returnToView = hiddenParams.returnToView;
        }


        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.submitDate = false;

        vm.openCalendar = openCalendar;
        vm.picker = {
            timepickerOptions: {
                showMeridian: false
            }
        };

        if (informationDialogLoadDTO){
            // Dialog is used for editing existing information
            vm.questionnaireAnswer = informationDialogLoadDTO.questionnaireAnswer;
            vm.questionnaire = informationDialogLoadDTO.questionnaireAnswer.questionnaire
            vm.information = informationDialogLoadDTO.information;
            vm.disabled = !informationDialogLoadDTO.currentUserEditAllowed;
        }
        else {
            // Dialog is used for creating new information
            vm.questionnaire = newInformationQuestionnaire;
            vm.questionnaireAnswer = null;
            vm.information = {
                ticket: {
                    priority:null,
                    title: hiddenParams.questionnaireSectionTitle,
                    description: hiddenParams.questionTitle
                },
                issueSignificances: [],
                significanceAssesmentDone: false
            };
            vm.information.submitDate = new Date();
            vm.disabled = false;
        }

        vm.currentSection = 0;
        vm.hiddenParams = hiddenParams;

        vm.issueSignificances = [];
        vm.secondaryInformationVisible = hasSomethingDefinedInSecondaryInformation(vm.information);

        vm.productlines = ProductLine.query({"size": 1000});
        vm.usergroups = UserGroup.query({"size": 1000});
        vm.reasoncodes = ReasonCode.forInformationType({"questionnaireType": vm.questionnaire.questionnaireType});
        loadTicketStates();

        loadIssueSignificances();

        vm.addedMaterialids =[];
        vm.uploadingTicketMaterial = false;
        vm.ticketMaterials = [];
        vm.uploadTicketMaterial = uploadTicketMaterial;
        vm.deleteTicketMaterial = deleteTicketMaterial;
        vm.moveTicketMaterial = moveTicketMaterial;
        vm.selectedFileTooLarge = false;

        createAnswersForQuestionnaire();
        loadTicketMaterials();

        vm.showSaveButton = function () {
            if (vm.questionnaire.sections.length === 0) {
                return true;
            }
            return vm.currentSection === vm.questionnaire.sections.length - 1;
        };


        function loadTicketStates () {
            TicketStateTypeList.query({"ticketType": 'INFORMATION', "size": 1000, "sort":"listIndex,asc"}, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.ticketstates = data;
                if (!vm.information.id) {
                    vm.information.ticket.state = vm.ticketstates[0];
                }
            }
            function onError(error) {
            }
        }

        function loadIssueSignificances(){
            IssueSignificance.query({}, onSuccess);

            function onSuccess(issueSignificances){
                vm.issueSignificances = issueSignificances;

                //adding already set but since deleted significance
                vm.information.issueSignificances.forEach(function(significance){
                    if (-1 === vm.issueSignificances.findIndex(function(sig){return sig.id == significance.id;})){
                        vm.issueSignificances.push(significance);
                    }
                });

                // setting selected significances to selected state
                vm.issueSignificances.forEach(function(significance){
                        significance.selected = -1 < vm.information.issueSignificances.findIndex(function(sig){return sig.id == significance.id;});
                });
            }
        }

        function createAnswersForQuestionnaire() {
            var answerMap = new Map();
            if (vm.questionnaireAnswer != null) {
                vm.questionnaireAnswer.answers.forEach(function (answer) {
                    answerMap.set(answer.question.id, answer);
                });
            }

            vm.questionnaire.sections.forEach(function (section) {
                section.questions.forEach(function (question) {
                    $log.debug(question.id);
                    $log.debug(answerMap.get(question.id));
                    var answer = answerMap.get(question.id);

                    if (!answer) {
                        answer = {};
                        answer.questionRootVersionId = question.rootVersionId;
                        answer.answerType = question.questionType;
                        answer.anomaly = false;
                        answer.anomalyConfirmed = false;
                        if (answer.answerType === "multi") {
                            answer.optionAnswers = [];
                        } else {
                            answer.value = null;
                        }
                    } else {
                        answer = angular.copy(answer);
                    }
                    question.answer = answer;

                });
            });

        }

        function onSaveSuccess() {

            $state.go(vm.returnToView);
        }

        vm.cancel = function() {
            $state.go(vm.returnToView);
        };

        function onSaveError() {
            alert("Virhe tapahtui lomaketta tallennettaessa.");
        }

        vm.saveAnswers = function () {
            var questionnaireAnswer = {};
            if (vm.questionnaireAnswer != null) {
                questionnaireAnswer = angular.copy(vm.questionnaireAnswer);
                questionnaireAnswer.answers = [];
            } else {
                questionnaireAnswer.id = null;
                questionnaireAnswer.answerTime = new Date();
                questionnaireAnswer.questionnaire = {};
                questionnaireAnswer.questionnaire.id = vm.questionnaire.id;
                questionnaireAnswer.user = null;
                questionnaireAnswer.answers = [];
                questionnaireAnswer.anomalyConfirmed = false;
            }

            questionnaireAnswer.anomaly = false;


            vm.questionnaire.sections.forEach(function (section) {
                section.questions.forEach(function (questionOriginal) {
                    var answer = angular.copy(questionOriginal.answer);
                    var question = angular.copy(questionOriginal);
                    question.answer = null;
                    answer.question = question;
                    answer.anomaly = false;

                    questionnaireAnswer.answers.push(answer);
                });
            });


            vm.information.issueSignificances = vm.issueSignificances.filter(function(sig){return sig.selected});

            if (vm.questionnaireAnswer != null) {
                $log.debug("Update with information");
                var dto = {};
                dto.questionnaireAnswer = questionnaireAnswer;
                dto.information = vm.information;
                Information.update(dto, onSaveSuccess, onSaveError);
            } else {
                $log.debug("Save with information");
                var dto = {};

                if (vm.hiddenParams.anomalyQuestionnaireAnswerId != null && vm.hiddenParams.anomalyQuestionnaireAnswerId > 0) {
                    dto.anomalyQuestionnaireAnswerId = vm.hiddenParams.anomalyQuestionnaireAnswerId;
                    dto.anomalyAnswerId = vm.hiddenParams.anomalyAnswerId;
                }

                dto.questionnaireAnswer = questionnaireAnswer;
                dto.information = vm.information;
                dto.addedMaterialids = vm.addedMaterialids;
                Information.save(dto, onSaveSuccess, onSaveError);
            }
        };







        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
            uiDatetimePickerConfig.buttonBar.today.text = $translate.instant('global.datepicker.today');
            uiDatetimePickerConfig.buttonBar.now.text = $translate.instant('global.datepicker.now');
            uiDatetimePickerConfig.buttonBar.time.text = $translate.instant('global.datepicker.time');
            uiDatetimePickerConfig.buttonBar.date.text = $translate.instant('global.datepicker.date');
            uiDatetimePickerConfig.buttonBar.clear.text = $translate.instant('global.datepicker.clear');
            uiDatetimePickerConfig.buttonBar.close.text = $translate.instant('global.datepicker.close');
        }

        // Material

        function moveTicketMaterial(index, direction) {
            var tm = vm.ticketMaterials[index];

            var step = 1;
            if (direction === 'down') {
                step = -1;
            }

            var parameters = {
                newListIndex: tm.listIndex + step

            };

            if (vm.information.ticket.id > 0) {
                parameters.ticketId = vm.information.ticket.id;
            }
            if (vm.addedMaterialids.length > 0) {
                parameters.addedMaterialIds = vm.addedMaterialids.join();
            }


            TicketRelatedMaterial.Order.updateListIndex({ticketMaterialId: tm.id, inParams: parameters}, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.ticketMaterials = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }


        function loadTicketMaterials() {

            var parameters = {};
            if (vm.information != null && vm.information.ticket != undefined && vm.information.ticket.id > 0) {
                parameters.ticketId = vm.information.ticket.id;
            }
            if (vm.addedMaterialids.length > 0) {
                parameters.addedMaterialIds = vm.addedMaterialids.join();
            }


            TicketRelatedMaterial.Materials.query(parameters, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.ticketMaterials = data;

                if (!vm.secondaryInformationVisible && vm.ticketMaterials.length > 0){
                    //Case where sencondary section has no properties set and is therefore closed at edit start
                    vm.secondaryInformationVisible = true;
                }
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.setFileContent = function ($file, $invalidFiles) {

            vm.selectedFileTooLarge = false;
            if ($invalidFiles && $invalidFiles.length > 0) {
                invalidFileSelected($invalidFiles);
                return;
            }

            if ($file == null) {
                return;
            }

            var ticketMaterial = {
                name: $file.name,
                fileContentContentType: $file.type,
                company: {},
                ticket: null
            };
            if (vm.information != null && vm.information.ticket != undefined && vm.information.ticket.id) {
                ticketMaterial.ticket = vm.information.ticket;
            }


            DataUtils.toBase64($file, function (base64Data) {
                ticketMaterial.fileContent = base64Data;
                vm.uploadTicketMaterial(ticketMaterial);
            });
        };

        function invalidFileSelected($invalidFiles) {

            console.log('invalid file selected ' + JSON.stringify($invalidFiles));
            vm.selectedFileTooLarge = true;
            $timeout(function () {
                vm.selectedFileTooLarge = false;
            }, 3000);


        }

        function uploadTicketMaterial(ticketMaterial) {
            vm.selectedFileTooLarge = false;
            vm.uploadingTicketMaterial = true;

            ticketMaterial.listIndex = vm.ticketMaterials.length;


            TicketMaterial.save(ticketMaterial, onSuccess, onError);

            function onSuccess(ticketMaterial) {
                console.log('uploaded ' + +ticketMaterial.id);
                if (ticketMaterial.ticket == null) {
                    vm.addedMaterialids.push(ticketMaterial.id);
                }
                vm.uploadingTicketMaterial = false;
                loadTicketMaterials();
            }
            function onError(err) {
                vm.uploadingTicketMaterial = false;
                console.log("failed to upload ticketmaterial " + err);
            }
        }

        function deleteTicketMaterial(tm) {
            TicketMaterial.delete(tm, onSuccess, onError);

            function onSuccess(ticketMaterial) {
                console.log('deleted ticket material');
                loadTicketMaterials();
            }
            function onError(err) {
                console.log("failed to delete ticketmaterial " + err);
            }


        }


        vm.toggleSecondaryVisible = function(){
            vm.secondaryInformationVisible = !vm.secondaryInformationVisible;
        };

        function hasSomethingDefinedInSecondaryInformation(information){
            var ticketHasData = false;
            if (information.ticket) {
                ticketHasData = information.ticket.place ||
                information.ticket.priority ||
                information.ticket.productLine ||
                information.ticket.product ||
                information.ticket.productBatch ||
                information.ticket.assignedTo ||
                information.ticket.assignedToUserGroup;
            }

            return ticketHasData ||
            information.reasonCode ||
            information.standardSection ||
            information.immediateActions ||
            information.productActions ||
            information.correctiveActions;
        }

    }
})();
