(function () {
    'use strict';

    angular
            .module('abcQualityMatchApp')
            .controller('UserHomeController', UserHomeController);

    UserHomeController.$inject = [];

    function UserHomeController() {
        var vm = this;
    }

})();
