(function() {
    'use strict';
    angular
        .module('abcQualityMatchApp')
        .factory('TicketStateTypeList', TicketStateTypeList);

    TicketStateTypeList.$inject = ['$resource'];

    function TicketStateTypeList ($resource) {
        var resourceUrl =  'api/ticket-states/temp/:ticketType';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
