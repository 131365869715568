(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TicketStateDeleteController',TicketStateDeleteController);

    TicketStateDeleteController.$inject = ['$uibModalInstance', 'entity', 'TicketState', 'ticketType'];

    function TicketStateDeleteController($uibModalInstance, entity, TicketState, ticketType) {
        var vm = this;

        vm.ticketState = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.type = ticketType.type;
        console.log("TicketType");
        console.log(ticketType);

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            TicketState.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
