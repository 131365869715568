(function () {
    'use strict';
    angular
            .module('abcQualityMatchApp')
            .factory('Information', Information);

    Information.$inject = ['$resource','DateUtils'];

    function Information($resource, DateUtils) {

        return $resource('', {}, {

            'save': {
                url: 'api/information',
                method: 'POST',
            },
            'update': {
                url: 'api/information',
                method: 'PUT',
            },

            'query': {/*unused*/},
            'get': {/*unused*/},

            'getInformationDialogLoadDTO': {
                method: 'GET',
                url:'api/information/dialogLoadDTO/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.information.submitDate = DateUtils.convertDateTimeFromServer(data.information.submitDate);
                        data.questionnaireAnswer.answerTime = DateUtils.convertDateTimeFromServer(data.questionnaireAnswer.answerTime);
                    }
                    return data;
                }, isArray: false},
            "informationEventsQuery":{
                url: 'api/information/events',
                method: 'GET',
                isArray: true},
            'informationEventsByIds':{
                url: 'api/information/events-by-ids',
                method: 'GET',
                isArray: true},
            'uploadInformationImportSheet':{
                url:'/api/information/information-import-sheet/upload',
                method:'POST'
            }
        });

    }
})();
