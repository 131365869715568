(function () {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .factory('User', User);

    User.$inject = ['$resource','DateUtils'];

    function User ($resource, DateUtils) {
        var service = $resource('api/users/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.activeFromDate = DateUtils.convertDateTimeFromServer(data.activeFromDate);
                    data.activeToDate = DateUtils.convertDateTimeFromServer(data.activeToDate);
                    return data;
                }
            },
            'getManagementList': {url: 'api/users/management-list', method: 'GET', isArray: true},
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'},
            'getUsersSpreadsheet':{url: 'api/users/export-spreadsheet', method: 'GET', isArray: false}
        });

        return service;
    }
})();
