(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('task-execution-counts', {
            parent: 'functions_menu',
            url: '/task-execution-counts?page&sort&search&fromDate&toDate',
            data: {
                authorities: ['ROLE_COMPANY_ADMIN','VERIFICATION','VERIFICATION_BY_PRODUCT_LINE', 'TASK_EXECUTIONS_VIEW'],
                pageTitle: 'abcQualityMatchApp.task.taskExecutions.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/task/task-execution-counts/task-execution-counts.html',
                    controller: 'TaskExecutionCountsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: '(task_ticket_title),asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', 'DateUtils', function ($stateParams, PaginationUtil, DateUtils) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        fromDate: DateUtils.convertLocalDateFromServer($stateParams.fromDate),
                        toDate: DateUtils.convertLocalDateFromServer($stateParams.toDate),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('task');
                    $translatePartialLoader.addPart('ticket');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('frequence');
                    $translatePartialLoader.addPart('ticket');
                    $translatePartialLoader.addPart('ticketMaterial');
                    $translatePartialLoader.addPart('recurrence');
                    return $translate.refresh();
                }]
            }
        })
        .state('task-executions-tables', {
            parent: 'functions_menu',
            url: '/task-execution/{taskId}?page&fromDate&toDate',
            data: {
                authorities: ['ROLE_COMPANY_ADMIN','VERIFICATION','VERIFICATION_BY_PRODUCT_LINE', 'TASK_EXECUTIONS_VIEW'],
                pageTitle: 'abcQualityMatchApp.task.taskExecutions.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/task/task-execution-counts/task-executions/task-executions.html',
                    controller: 'TaskExecutionsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', 'DateUtils', function ($stateParams, PaginationUtil, DateUtils) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        fromDate: DateUtils.convertLocalDateFromServer($stateParams.fromDate),
                        toDate: DateUtils.convertLocalDateFromServer($stateParams.toDate),
                        taskId: $stateParams.taskId
                    };
                }],
                task: ['Task','$stateParams', function(Task, $stateParams) {
                    return Task.get({id : $stateParams.taskId}).$promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('task');
                    $translatePartialLoader.addPart('frequence');
                    $translatePartialLoader.addPart('ticket');
                    $translatePartialLoader.addPart('ticketMaterial');
                    $translatePartialLoader.addPart('recurrence');
                    $translatePartialLoader.addPart('taskVerification');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'task-execution-counts',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        });
    }
})();
