(function() {
    'use strict';
    angular
        .module('abcQualityMatchApp')
        .factory('TrainingRecord', TrainingRecord);

    TrainingRecord.$inject = ['$resource', 'DateUtils'];

    function TrainingRecord ($resource, DateUtils) {
        var resourceUrl =  'api/training-records/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.signupDate = DateUtils.convertDateTimeFromServer(data.signupDate);
                        data.traineeSignedDate = DateUtils.convertDateTimeFromServer(data.traineeSignedDate);
                        data.validToDate = DateUtils.convertDateTimeFromServer(data.validToDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    angular.module('abcQualityMatchApp')
           .factory('TrainingRepositoryTrainingEventSelect', TrainingRepositoryTrainingEventSelect);
    TrainingRepositoryTrainingEventSelect.$inject = ['$resource'];
    function TrainingRepositoryTrainingEventSelect($resource, DateUtils) {
        var resourceUrl = 'api/training-events/training-record-select-list';
        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }
})();
