(function () {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('information', {
                parent: 'functions_menu',
                url: '/information?page&sort&search&searchTerm&questionnareTypes&fromDate&toDate&productId&ticketStateTypeId&productLineId&reasonCodeId&productionUnitId',
                data: {
                    authorities: ['ROLE_COMPANY_USER'],
                    pageTitle: 'abcQualityMatchApp.information.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/information/information.html',
                        controller: 'InformationController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: '(submit_date),desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            searchTerm: $stateParams.searchTerm,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            productId: $stateParams.productId,
                            productLineId: $stateParams.productLineId,
                            statusId: $stateParams.statusId,
                            ticketStateTypeId: $stateParams.ticketStateTypeId,
                            questionnareTypes: $stateParams.questionnareTypes,
                            productionUnitId : $stateParams.productionUnitId
                        };
                    }],

                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('information');
                        $translatePartialLoader.addPart('informationEvent');
                        $translatePartialLoader.addPart('questionnaireType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    userPreferences: ['UserPreferences', function (UserPreferences) {
                        return UserPreferences.get().$promise;
                    }]
                }
            })
            .state('newInformation', {
                parent: 'information',
                url: '/new-information/{questionnaireId}/',
                params : {
                    anomalyQuestionnaireAnswerId: null,
                    anomalyAnswerId: null,
                    questionnaireSectionTitle: null,
                    questionTitle: null,
                    returnToView: null
                },
                data: {
                    authorities: ['ROLE_COMPANY_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/information/information-dialog.html',
                        controller: 'InformationDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('question');
                        $translatePartialLoader.addPart('ticket');
                        $translatePartialLoader.addPart('ticketMaterial');
                        $translatePartialLoader.addPart('anomalyEvent');
                        $translatePartialLoader.addPart('information');
                        $translatePartialLoader.addPart('questionnaire');
                        return $translate.refresh();
                    }],
                    newInformationQuestionnaire: ['$stateParams', 'Questionnaire', function($stateParams, Questionnaire) {
                        return Questionnaire.get({id : $stateParams.questionnaireId}).$promise;
                    }],
                    informationDialogLoadDTO: [ function() {
                        return null;
                    }],
                    hiddenParams: ['$stateParams', function($stateParams) {
                        return {
                            anomalyQuestionnaireAnswerId: $stateParams.anomalyQuestionnaireAnswerId,
                            anomalyAnswerId: $stateParams.anomalyAnswerId,
                            questionnaireSectionTitle: $stateParams.questionnaireSectionTitle,
                            questionTitle: $stateParams.questionTitle,
                            returnToView: $stateParams.returnToView
                        };
                    }]
                }
            })
            .state('editInformation', {
                parent: 'information',
                url: '/edit-information/{id}',
                params : {
                    returnToView: null
                },
                data: {
                    authorities: ['ROLE_COMPANY_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/information/information-dialog.html',
                        controller: 'InformationDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('question');
                        $translatePartialLoader.addPart('ticket');
                        $translatePartialLoader.addPart('ticketMaterial');
                        $translatePartialLoader.addPart('anomalyEvent');
                        $translatePartialLoader.addPart('information');
                        $translatePartialLoader.addPart('questionnaire');
                        return $translate.refresh();
                    }],
                    newInformationQuestionnaire: [function() {
                        return null;
                    }],
                    informationDialogLoadDTO: ['$stateParams', 'Information', function($stateParams, Information) {
                        return Information.getInformationDialogLoadDTO({id : $stateParams.id}).$promise;

                    }],
                    hiddenParams: ['$stateParams', function($stateParams) {
                        return {
                            returnToView: $stateParams.returnToView
                        };
                    }]
                }
            });
    }

})();
