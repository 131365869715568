(function () {
    'use strict';
    angular
        .module('abcQualityMatchApp')
        .factory('Company', Company);

    Company.$inject = ['$resource'];

    function Company($resource) {
        var resourceUrl = 'api/companies/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'getLogo': {
                method: 'GET',
                url: 'api/companies/get-logo/:id'
            },
            'getLogoId': {
                method: 'GET',
                url: 'api/companies/get-logo-id'
            },

            'getDto': {
                method: 'GET',
                url: 'api/companies/dto/:id'
            },


        });
    }
})();
