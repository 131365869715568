(function () {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .factory('TrainingRequirements', TrainingRequirements);

    TrainingRequirements.$inject = ['$resource', 'DateUtils'];

    function TrainingRequirements($resource, DateUtils) {
        var resourceUrl = 'api/training-requirements';

        return $resource(resourceUrl, {} , {
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.validToDate = DateUtils.convertDateTimeFromServer(data.validToDate);
                    }
                    return data;
                }
            }
        });
    }
})();