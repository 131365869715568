(function() {
    'use strict';
    angular
            .module('abcQualityMatchApp')
            .factory('TicketRelatedMaterial', TicketRelatedMaterial);
    TicketRelatedMaterial.$inject = ['$resource'];

    function TicketRelatedMaterial($resource, DateUtils) {

        return {
            Materials: $resource('api/ticket-materials/for-ticket', {}, {'query': {method: 'GET', isArray: true}}),
            Order: $resource('api/ticket-materials/update-list-index/:ticketMaterialId', {}, {'updateListIndex': 
                        {method: 'POST', isArray: true,
                        params: '@inParams'
                    }
            }),
        };
    }
})();
