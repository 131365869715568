(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('ProductionUnitDeleteController',ProductionUnitDeleteController);

    ProductionUnitDeleteController.$inject = ['$uibModalInstance', 'entity', 'ProductionUnit'];

    function ProductionUnitDeleteController($uibModalInstance, entity, ProductionUnit) {
        var vm = this;

        vm.productionUnit = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ProductionUnit.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
