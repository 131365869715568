(function () {
    'use strict';

    var openProjects = {
        templateUrl: 'app/components/home/projects/open-projects.html',
        controllerAs: 'vm',
        controller: ['Project', function (Project) {
                var vm = this;
                vm.projects = null;

                loadProjects();

                function loadProjects() {
                    Project.UsersOpenProjects.query({}, onSuccess, onError);
                    function onSuccess(data, headers) {
                        vm.projects = data;
                    }
                    function onError(error) {
                        console.log("failed");
                    }
                }
            }]
    };

    angular
            .module('abcQualityMatchApp')
            .component('openProjects', openProjects);


})();