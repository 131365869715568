(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('ReportController', ReportController);

    ReportController.$inject = ['$scope', '$state'];

    function ReportController ($scope, $state) {
        var vm = this;
    }
})();
