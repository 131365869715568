(function () {
    'use strict';

    angular
            .module('abcQualityMatchApp')
            .controller('AnomalyController', AnomalyController);

    AnomalyController.$inject = ['$timeout',
        '$scope', '$state', 'Anomaly',
        'ParseLinks', 'AlertService', 'paginationConstants',
        'pagingParams', 'QuestionnaireCompany','User','filterAnswerAuthorUser',
        'DateUtils', 'Task', '$translate'];

    function AnomalyController($timeout, $scope, $state,
                               Anomaly, ParseLinks, AlertService,
                               paginationConstants, pagingParams,
                               QuestionnaireCompany, User, filterAnswerAuthorUser,
                               DateUtils, Task,$translate) {
        var vm = this;

        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {fromDate: false, toDate:false };

        vm.refreshSearch = refreshSearch;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.allQuestionnaires = null;
        vm.informationQuestionnaires = null;
        vm.tasks = null;

        vm.pdfDownloadParameters = {};

        vm.createRootCauseAnalysisForSelected = createRootCauseAnalysisForSelected;

        vm.filtering = {
            searchTerm: pagingParams.searchTerm,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
            taskId: pagingParams.taskId,
            answerAuthorUserId:  pagingParams.answerAuthorUserId,
            questionnaireId: pagingParams.questionnaireId
        };

        var noFilteringDefined =
            typeof vm.filtering.fromDate === "undefined" &&
            typeof vm.filtering.toDate === "undefined" &&
            typeof vm.filtering.searchTerm === "undefined" &&
            typeof vm.filtering.taskId === "undefined" &&
            typeof vm.filtering.answerAuthorUserId === "undefined" &&
            typeof vm.filtering.questionnaireId === "undefined";

        if (noFilteringDefined){
            var firstOfJanuary = new Date();
            firstOfJanuary.setMonth(0);
            firstOfJanuary.setDate(1);
            //defaulting to first day of this year
            vm.filtering.fromDate = firstOfJanuary.toISOString().slice(0,10);
        }

        vm.filteringFromDate = null;
        vm.filteringToDate = null;

        if (vm.filtering.fromDate){
            vm.filteringFromDate = DateUtils.convertLocalDateFromServer(vm.filtering.fromDate);
        }
        if (vm.filtering.toDate){
            vm.filteringToDate = DateUtils.convertLocalDateFromServer(vm.filtering.toDate);
        }




        vm.filterAnswerAuthorUser = filterAnswerAuthorUser;

        $scope.$watch('vm.filterAnswerAuthorUser',function(newVal, oldVal){
            if (typeof newVal === "undefined"){
                return;
            }
            if (!oldVal){
                oldVal = null;
            }
            if (newVal != oldVal) {
                refreshSearch();
            }
        });



        $timeout(function (){
            angular.element('#seachTerm').focus();
        });

        loadAll();
        loadQuestionnaires();
        loadTasks();

        function addFilteringParameters(parameters) {
            if (vm.filterAnswerAuthorUser) {
                vm.filtering.answerAuthorUserId = vm.filterAnswerAuthorUser.id;
                parameters.answerAuthorUserId = vm.filterAnswerAuthorUser.id;
            }
            else {
                vm.filtering.answerAuthorUserId = null;
            }

            if (vm.filteringFromDate) {
                vm.filtering.fromDate = DateUtils.convertLocalDateToServer(vm.filteringFromDate);
                parameters.fromDate = DateUtils.convertLocalDateToServer(vm.filteringFromDate);
            }
            else {
                vm.filtering.fromDate = null;
            }
            if (vm.filteringToDate) {
                vm.filtering.toDate = DateUtils.convertLocalDateToServer(vm.filteringToDate);
                parameters.toDate = DateUtils.convertLocalDateToServer(vm.filteringToDate);
            }
            else {
                vm.filtering.toDate = null;
            }

            if (vm.filtering.searchTerm ){
                parameters.searchTerm = vm.filtering.searchTerm;
            }
            if (vm.filtering.taskId ){
                parameters.taskId = vm.filtering.taskId;
            }
            if (vm.filtering.questionnaireId ){
                parameters.questionnaireId = vm.filtering.questionnaireId;
            }

            return parameters;

        }

        function loadAll() {

            var parameters = {
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            };

            parameters = addFilteringParameters(parameters);

            vm.pdfDownloadParameters = JSON.parse(JSON.stringify(parameters));;
            vm.pdfDownloadParameters.fileName = $translate.instant('abcQualityMatchApp.anomalyEvent.pdfExportFileName');
            vm.pdfDownloadParameters.page = 0;
            vm.pdfDownloadParameters.size = 10000;
            vm.pdfDownloadParameters.cacheBuster = new Date().getTime();

            console.log("loadAll parameters " + JSON.stringify(parameters));


            Anomaly.Company.query(parameters, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== '(row)') {
                    result.push('(row)');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.anomaly = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function refreshSearch(){
            loadPage(1);
        }

        function loadQuestionnaires() {
            QuestionnaireCompany.Query.query({"size": 1000, "sort":"title,asc"}, onSuccess, onError);

            function onSuccess(questionnaires, headers) {

                vm.allQuestionnaires = questionnaires;
                vm.informationQuestionnaires = questionnaires.filter(function(questionnaire){
                    return (questionnaire.questionnaireType === "ANOMALY");
                });
            }
            function onError(error) {
                console.log("failed loading questionnaires");
            }
        }

        function loadTasks() {
            Task.getAssignedOrEditableByUser({"size": 1000, "sort":"(title),asc"}, onSuccess, onError);

            function onSuccess(tasks, headers) {
                vm.tasks = tasks;
            }
            function onError(error) {
                console.log("failed loading tasks");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();


        }

        function transition() {

            var parameters = {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            };
            parameters = addFilteringParameters(parameters);

            console.log("transition parameters " + JSON.stringify(parameters));
            $state.transitionTo($state.$current, parameters);
        }

        vm.reject = function (qaId, answerId) {
            Anomaly.Answer.reject({"questionnaireAnswerId": qaId, "answerId": answerId}, onSuccess, onError);
            function onSuccess(data, headers) {
                $state.reload();
            }
            function onError(error) {
                console.log("failed");
            }
        }

        function createRootCauseAnalysisForSelected(){
            var ids = [];
            vm.anomaly.forEach(function (anom){
               if (anom.selectionChecked){
                   ids.push(anom.row);
               }
            });

            if (ids.length == 0){
                console.log('Nothing selected');
                return;
            }

            $state.go('root-cause-analysis.new', {aes: ids})
        }


        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

    }
})();
