(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TaskActivateController', TaskActivateController);

    TaskActivateController.$inject = ['$translate', '$timeout', '$scope', '$stateParams', '$uibModalInstance',
        'entity', 'Task', 'uiDatetimePickerConfig'];

    function TaskActivateController ($translate, $timeout, $scope, $stateParams, $uibModalInstance,
                                   entity, Task, uiDatetimePickerConfig) {
        var vm = this;
        window.vvm = vm;
        vm.task = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.picker = {
            timepickerOptions: {
                showMeridian: false
            }
        };

        vm.task.start = new Date();
        // Set end date +1 hour by default
        vm.task.end = new Date();
        vm.task.end.setHours(vm.task.end.getHours() + 1);

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            // Set done time to null
            vm.task.doneTime = null;
            Task.activateTask(vm.task, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.start = false;
        vm.datePickerOpenStatus.end = false;
        vm.datePickerOpenStatus.until = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
            uiDatetimePickerConfig.buttonBar.today.text = $translate.instant('global.datepicker.today');
            uiDatetimePickerConfig.buttonBar.now.text = $translate.instant('global.datepicker.now');
            uiDatetimePickerConfig.buttonBar.time.text = $translate.instant('global.datepicker.time');
            uiDatetimePickerConfig.buttonBar.date.text = $translate.instant('global.datepicker.date');
            uiDatetimePickerConfig.buttonBar.clear.text = $translate.instant('global.datepicker.clear');
            uiDatetimePickerConfig.buttonBar.close.text = $translate.instant('global.datepicker.close');
        }
    }
})();
