(function () {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$scope', '$state', '$http', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'Company'];

    function NavbarController($scope, $state, $http, Auth, Principal, ProfileService, LoginService, Company) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.defaultLogoUrl = "content/images/ABC_QualityMatch_logo.png"
        loadCurrentAccount();

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        $scope.$root.$on('authenticationSuccess', function () {
            loadCurrentAccount();
        });

        function loadCurrentAccount() {
            Principal.identity().then(function (account) {
                vm.currentAccount = account;
                loadLogo();
            });
        }

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

        function loadLogo() {
            vm.hasLogo = false;
            Company.getLogoId({}, onGetLogoIdSuccess, setDefaultLogo);
        }

        function setDefaultLogo() {
            vm.hasLogo = false;
        }

        function onGetLogoIdSuccess(result) {
            if (!result.logoId){
                setDefaultLogo();
                return;
            }
            vm.logoUrl = "/api/companies/get-logo/" + result.logoId;
            vm.hasLogo = true;

        }

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        $scope.companySelected = function (company) {
            $scope.$root.setSelectedCompanyToServer(company);
            $state.go('home');
            loadLogo();
        };
    }
})();
