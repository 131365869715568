(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('ProductLineDeleteController',ProductLineDeleteController);

    ProductLineDeleteController.$inject = ['$uibModalInstance', 'entity', 'ProductLine'];

    function ProductLineDeleteController($uibModalInstance, entity, ProductLine) {
        var vm = this;

        vm.productLine = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ProductLine.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
