(function() {
    'use strict';
    angular
        .module('abcQualityMatchApp')
        .factory('Anomaly', Anomaly);

    Anomaly.$inject = ['$resource'];

    function Anomaly ($resource) {
        var resourceUrl =  'api/questionnaire-answers/anomalies';

        return {
            UserLatest: $resource(resourceUrl, {}, {'query': {method: 'GET', isArray: true}}),
            Company: $resource(resourceUrl + '/company', {}, {'query': {method: 'GET', isArray: true}}),
            QuestionnaireAnswer: $resource(resourceUrl + '/reject/:questionnaireAnswerId', {}, {'reject': {method: 'GET', isArray: false}}),
            Answer: $resource(resourceUrl + '/reject/:questionnaireAnswerId/:answerId', {}, {'reject': {method: 'GET', isArray: false}}),
            EventsByIds: $resource(resourceUrl + '/events-by-ids', {}, {'query': {method: 'GET', isArray: true}}),
        };
    }
})();
