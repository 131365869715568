(function () {
    'use strict';
    angular
            .module('abcQualityMatchApp')
            .factory('DocumentFolder', DocumentFolder);

    DocumentFolder.$inject = ['$resource'];

    function DocumentFolder($resource) {
        var resourceUrl = 'api/document-folders';

        return {
            CRUD: $resource(resourceUrl + '/:id', {}, {
                'query': {method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                },
                'update': {method: 'PUT'},
                'undelete': {
                    url: 'api/document-folders/undelete',
                    method: 'PUT'}
            }),
            SubFolders: $resource(resourceUrl + '/sub/:id', {}, {'query': {method: 'GET', isArray: true}})
        };
    }
})();
