(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TicketController', TicketController);

    TicketController.$inject = ['$scope', 'Principal', '$state'];

    function TicketController($scope, Principal, $state) {
        var vm = this;        
    }
})();
