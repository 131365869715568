(function() {
    'use strict';
    angular
        .module('abcQualityMatchApp')
        .factory('TaskEvent', TaskEvent);

    TaskEvent.$inject = ['$resource'];

    function TaskEvent ($resource) {
        var resourceUrl =  'api/tasks/task-events';

         return {
            UserLatest: $resource(resourceUrl, {}, {'query': {method: 'GET', isArray: true}}),
            Company: $resource(resourceUrl + '/company', {}, {'query': {method: 'GET', isArray: true}}),
            Confirm: $resource(resourceUrl + '/confirm/:taskId/:recurrenceDateId', {}, {'confirm': {method: 'GET', isArray: false}})
        };
    }
})();
