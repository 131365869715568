(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('DocumentFileStateChangeDialogController', DocumentFileStateChangeDialogController);

    DocumentFileStateChangeDialogController.$inject = ['documentFile', 'useCase', '$uibModalInstance', 'DocumentFile'];

    function DocumentFileStateChangeDialogController(documentFile, useCase, $uibModalInstance, DocumentFile) {
        var vm = this;

        vm.documentFile = documentFile;
        vm.useCase = useCase;


        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.save = function() {

            var newState;
            if (vm.useCase == 'inspect'){
                newState = 'INSPECTED';
            }
            else if (vm.useCase === 'approve'){
                newState = 'APPROVED';
            }
            else if (vm.useCase == 'publish'){
                newState = 'PUBLISHED';
            }
            else{
                throw "useCase must be inspect, approve, or publish"
            }

            vm.isSaving = true;
            DocumentFile.stateChange({fileId: vm.documentFile.id, newState: newState}, null, onSaveSuccess, onSaveError);

            function onSaveSuccess (result) {
                vm.isSaving = false;
                $uibModalInstance.close(result);

            }

            function onSaveError () {
                vm.isSaving = false;
            }
        };





    }
})();
