(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('DocumentFolderDialogController', DocumentFolderDialogController);

    DocumentFolderDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DocumentFolder', '$rootScope', 'UserGroup'];

    function DocumentFolderDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DocumentFolder, $rootScope, UserGroup) {
        var vm = this;
        window.vvm = vm;
        vm.validParentFolderSelected = false;

        vm.documentFolder = entity;
        vm.clear = clear;
        vm.save = save;
        vm.userGroups = UserGroup.query({"size": 1000});
        
        vm.newPermission = createEmptyPermission();
        vm.addPermission = addPermission;
        vm.deletePermission = deletePermission;

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.documentFolder.id !== null) {
                DocumentFolder.CRUD.update(vm.documentFolder, onSaveSuccess, onSaveError);
            } else {
                DocumentFolder.CRUD.save(vm.documentFolder, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:documentFolderUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function addPermission() {
            
            if (vm.newPermission.userGroup === null){
                return;
            } 
            
            var userGroupAlready = vm.documentFolder.permissions.find(function(permission){return permission.userGroup.id == vm.newPermission.userGroup.id});
            if (userGroupAlready){
                vm.newPermission = createEmptyPermission();
                return;
            }
            
            
            vm.documentFolder.permissions.push(vm.newPermission);
            vm.newPermission = createEmptyPermission();
            return false;
        }
        
        function deletePermission(index){
            vm.documentFolder.permissions.splice(index,1);
            return false;
            
        }
        
        function createEmptyPermission(){
            return {
                userGroup: null,
                writePermission: false
            };
        }

    }
})();
