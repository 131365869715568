(function () {
    'use strict';

    angular
            .module('abcQualityMatchApp')
            .controller('TraineeTrainingSignUpDialogController', TraineeTrainingSignUpDialogController);

    TraineeTrainingSignUpDialogController.$inject = [
        '$uibModal', '$location', '$scope',
        '$rootScope', '$stateParams', 'previousState',
        'independentStudyTraining', 'trainingEvent','TraineeEventSignup',
        '$state'
        ];

    function TraineeTrainingSignUpDialogController(
        $uibModal, $location, $scope,
        $rootScope, $stateParams, previousState,
        independentStudyTraining, trainingEvent, TraineeEventSignup,
        $state) {
        var vm = this;

        vm.trainingEvent = trainingEvent;
        vm.independentStudyTraining = independentStudyTraining;
        vm.eventNotTakingSignUps = false;

        if (vm.independentStudyTraining != null){
            vm.training = independentStudyTraining;
        }
        else{
            vm.training = trainingEvent.training;
        }

        vm.signedUp = $stateParams.signedUp === 'true';

        vm.previousState = previousState.name;
        vm.confirmSignup = confirmSignup;
        vm.isSaving = false;
        vm.failureReason = null;


        function confirmSignup() {
            $uibModal.open({
                templateUrl: 'app/training-main/trainee/trainee-training-signup-confirm-dialog.html',
                controller: 'TrainingSignupConfirmController',
                controllerAs: 'vm',
                size: 'sm'
            }).result.then(function (confirmd) {
               if (confirmd){
                   signup();
               }
            });
        }


        function signup() {
            vm.isSaving = false;

            if (vm.independentStudyTraining){
                TraineeEventSignup.signupToIndependentStydyTraining({trainingId: vm.independentStudyTraining.id}, onSuccess, onFailure);
            }
            else{
                TraineeEventSignup.signupToTrainingEvent({trainingEventId: vm.trainingEvent.id}, onSuccess, onFailure);
            }

        }

        function onSuccess(result) {
            vm.isSaving = false;
            //location.path('/trainee/records');
            $state.go('^');
        }

        function onFailure(result) {
            vm.isSaving = false;
            if (result.status === 409){
                vm.failureReason = result.data.reason;
            }
        }

    }


    angular
            .module('abcQualityMatchApp')
            .controller('TrainingSignupConfirmController', TrainingSignupConfirmController);

    TrainingSignupConfirmController.$inject = ['$uibModalInstance'];

    function TrainingSignupConfirmController($uibModalInstance) {
        var vm = this;

        vm.closeDialog = function() {
            $uibModalInstance.dismiss('cancel');
        }
        vm.confirm = function() {
            $uibModalInstance.close(true);
        }
    }


})();
