(function () {
    'use strict';

    var informations = {
        templateUrl: 'app/components/home/informations/informations.html',
        controllerAs: 'vm',
        controller: ['Information', 'QuestionnaireCompany', function (Information, QuestionnaireCompany) {
                var vm = this;
                vm.informations = null;
                vm.informationQuestionnaires = null;
                vm.questioinnaireTypes = ["ANOMALY",
                    "CLOSE_CALL","DANGER","PROPOSITION",
                    "CHANGE_NOTIFICATION", "BULLETIN_NOTIFICATION", "INJURY_NOTIFICATION",
                    "RECLAMATION_NOTIFICATION", "INITIATIVE_NOTIFICATION"];

                loadInformations();
                loadInformationQuestionnaires();

                function loadInformations() {
                    Information.informationEventsQuery({
                        page: 0,
                        size: 10,
                        //sort: 'submit_date,desc',
                        sort: ['(submit_date),asc','id'],
                        ticketStateType: 'NEW'
                    }, onSuccess, onError);

                    function onSuccess(data, headers) {
                        data.reverse();
                        vm.informations = data;
                    }
                    function onError(error) {
                        console.log("failed");
                    }
                }

                function loadInformationQuestionnaires() {
                    QuestionnaireCompany.QueryByTypes.query({types: vm.questioinnaireTypes.join(), "size": 1000}, onSuccess, onError);
                    function onSuccess(data, headers) {
                        vm.informationQuestionnaires = data;
                    }
                    function onError(error) {
                        console.log("failed");
                    }
                }
            }]
    };

    angular
            .module('abcQualityMatchApp')
            .component('informations', informations);


})();
