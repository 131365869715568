(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TaskDetailsController', TaskDetailsController);

    TaskDetailsController.$inject = ['$uibModalInstance', 'verifiedTaskDto'];

    function TaskDetailsController($uibModalInstance, taskDto) {
        var vm = this;

        vm.task = taskDto.task
        vm.questionnaireAnswer = taskDto.questionnaireAnswer;

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
