(function () {
    'use strict';

    angular.module('abcQualityMatchApp')
           .controller('TaskExecutionController', TaskExecutionController);

    TaskExecutionController.$inject = [
        '$scope', '$state', '$rootScope',
        'taskFulfillmentDTO', '$log',
        'Task','$stateParams',
        'QuestionnaireUtils'];

    function TaskExecutionController(
        $scope, $state,  $rootScope,
        taskFulfillmentDTO, $log,
        Task, $stateParams,
        QuestionnaireUtils) {

        var vm = this;
        console.log('state ->', $state.params);
        vm.files = [];
        window.vvm = vm;
        vm.returnToView = 'home';
        if ($stateParams.returnToView){
            vm.returnToView = $stateParams.returnToView;
        }

        vm.taskFulfillmentDTO = taskFulfillmentDTO;

        vm.manualAnomaly = false;
        vm.questionnaire = taskFulfillmentDTO.questionnaire;
        vm.currentSection = 0;
        vm.questionnaire.anomalyCategories = Task.getAnomalyCategorySelectList();

        vm.ticketState = null;
        vm.taskFulfillmentDTO.settableTaskTicketStates.forEach(function(state){
            if (taskFulfillmentDTO.ticketStateId === state.id){
                vm.ticketState = state;
            }
        });

        vm.oldQuestionnaireAnswer = taskFulfillmentDTO.questionnaireAnswer;
        vm.comparisonQuestionnaireAnswer = taskFulfillmentDTO.comparisonQuestionnaireAnswer;
        vm.comparisons = [];

        if (vm.oldQuestionnaireAnswer != null) {
            vm.manualAnomaly = vm.oldQuestionnaireAnswer.anomaly;
        }
    
        var deleteAttachment = $rootScope.$on('abcQualityMatchApp:deleteAttachment', function(event, result) {
            // console.log('delete attachment -> answer ->', result.answer);
            // console.log('delete attachment -> attachment ->', result.attachment);
            var storageFile = result.attachment.storageFile;
        
            Task.deleteAnswerAttachment( {id: storageFile.id }, onSuccess, onError);
            function onSuccess(response) {
                console.log('Delete file success ->', response)
                vm.questionnaire.sections.forEach(function (section) {
                    section.questions.forEach(function (question) {
                        if (question.answer === result.answer) {
                            // console.log('match');
                            question.answer.attachments = question.answer.attachments.filter(function(value, index, arr){ 
                                return value !== result.attachment;
                            });
                            
                        }
                    });
                });
            }
            function onError(error) {
                console.log('Could not delete file ->', error);
                alert("Tiedoston poistaminen ei onnistunut");
            }
                    
        });
        $scope.$on('$destroy', deleteAttachment);        

        createAnswersForQuestionnaire();

        vm.showSaveButton = function () {
            if (vm.questionnaire.sections.length === 0) {
                return true;
            }
            return vm.currentSection === vm.questionnaire.sections.length - 1;
        };

        // file upload
        vm.setFileContent = function ($file, $invalidFiles) {
            console.log("File upload");
            vm.selectedFileTooLarge = false;
            if ($invalidFiles && $invalidFiles.length > 0) {
                invalidFileSelected($invalidFiles);
                return;
            }

            if ($file == null) {
                return;
            }

            var ticketMaterial = {
                name: $file.name,
                fileContentContentType: $file.type,
                company: {},
                ticket: null
            };
            if (vm.task.ticketId > 0) {
                ticketMaterial.ticket = {id:vm.task.ticketId};
            }


            DataUtils.toBase64($file, function (base64Data) {
                ticketMaterial.fileContent = base64Data;
                vm.uploadTicketMaterial(ticketMaterial);
            });
        };

        function invalidFileSelected($invalidFiles) {

            console.log('invalid file selected ' + JSON.stringify($invalidFiles));
            vm.selectedFileTooLarge = true;
            $timeout(function () {
                vm.selectedFileTooLarge = false;
            }, 3000);

        }

        function createAnswersForQuestionnaire() {
            var comparisonAnswerMap = new Map();
            if (!!vm.comparisonQuestionnaireAnswer && !!vm.comparisonQuestionnaireAnswer.answers) {
                vm.comparisonQuestionnaireAnswer.answers.forEach(function (answer) {
                    comparisonAnswerMap.set(answer.question.id, answer);
                });
            }

            var answerMap = new Map();
            if (vm.oldQuestionnaireAnswer != null) {
                vm.oldQuestionnaireAnswer.answers.forEach(function (answer) {
                    answerMap.set(answer.question.id, answer);
                });
            }

            vm.questionnaire.sections.forEach(function (section) {
                section.questions.forEach(function (question) {
                    var answer = answerMap.get(question.id);

                    if (!answer) {
                        answer = {};
                        answer.questionRootVersionId = question.rootVersionId;
                        answer.answerType = question.questionType;
                        answer.anomaly = false;
                        answer.anomalyConfirmed = false;
                        if (answer.answerType === "multi") {
                            answer.optionAnswers = [];
                        } else {
                            answer.value = null;
                        }
                    } else {
                        answer = angular.copy(answer);
                    }
                    question.answer = answer;

                    var comparisonAnswer = comparisonAnswerMap.get(question.id);
                    if (comparisonAnswer != null) {
                        comparisonAnswer = angular.copy(comparisonAnswer);
                        var comparisonQuestion = angular.copy(question)
                        comparisonQuestion.answer = comparisonAnswer;
                        vm.comparisons.push(comparisonQuestion);
                    }
                });
            });
            vm.containsUnansweredQuestions = QuestionnaireUtils.hasUnansweredRequiredQuestions(vm.questionnaire);
        }

        function onSaveSuccess() {
            $state.go(vm.returnToView);
        }

        vm.cancel = function() {
            $state.go(vm.returnToView);
        };

        function onSaveError() {
            alert("Virhe tapahtui lomaketta tallennettaessa.");
        }

        vm.saveAnswers = function () {
            console.log('Save answers !');
            var questionnaireAnswer = {};
            if (vm.oldQuestionnaireAnswer != null) {
                questionnaireAnswer = angular.copy(vm.oldQuestionnaireAnswer);
                questionnaireAnswer.answers = [];
            } else {
                questionnaireAnswer.id = null;
                questionnaireAnswer.answerTime = new Date();
                questionnaireAnswer.questionnaire = {id: vm.questionnaire.id};
                questionnaireAnswer.user = null;
                questionnaireAnswer.answers = [];
                questionnaireAnswer.anomalyConfirmed = false;
            }

            questionnaireAnswer.anomaly = vm.manualAnomaly;

            vm.questionnaire.sections.forEach(function (section) {
                section.questions.forEach(function (questionOriginal) {
                    var answer = angular.copy(questionOriginal.answer);
                    var question = angular.copy(questionOriginal);
                    question.answer = null; // removing this so that received json makes sense.
                    answer.question = question;
                    questionnaireAnswer.answers.push(answer);
                });
            });

            var taskFulfilmentToSave = {};
            taskFulfilmentToSave.questionnaireAnswer = questionnaireAnswer;
            taskFulfilmentToSave.taskId = vm.taskFulfillmentDTO.taskId;
            taskFulfilmentToSave.recurrenceDateId = vm.taskFulfillmentDTO.recurrenceDateId;
            taskFulfilmentToSave.ticketStateId = vm.ticketState.id;
            console.log('saving ->', taskFulfilmentToSave);
            Task.saveUpdateTaskFulfillment(taskFulfilmentToSave, onSaveSuccess, onSaveError);
        };

        vm.onAnswerChange = function(){
            vm.containsUnansweredQuestions = QuestionnaireUtils.hasUnansweredRequiredQuestions(vm.questionnaire);
        };
    }
})();
