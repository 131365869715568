(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('ProductLineDialogController', ProductLineDialogController);

    ProductLineDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProductLine', 'ProductionUnit', 'User', 'Training'];

    function ProductLineDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ProductLine, ProductionUnit, User, Training) {
        var vm = this;

        vm.productLine = entity;
        vm.clear = clear;
        vm.save = save;
        vm.productionunits = ProductionUnit.query({"size": 1000, "sort":"(name),asc"});
        vm.trainingSelectList = Training.query();
        vm.newTraining = null;

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.productLine.id !== null) {
                ProductLine.update(vm.productLine, onSaveSuccess, onSaveError);
            } else {
                ProductLine.save(vm.productLine, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:productLineUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.addUser = function () {
            if (vm.newUser != null) {
                var alreadyAdded = false;
                vm.productLine.users.forEach(function (productLineUser) {
                    if (productLineUser.id == vm.newUser.id) {
                        alreadyAdded = true;
                    }
                });
                if (!alreadyAdded) {
                    vm.productLine.users.push(vm.newUser);
                    vm.newUser = null;
                }
            }
        }

        vm.deleteUser = function (index) {
            vm.productLine.users.splice(index,1);
            return false;
        }

        vm.deleteExpectedTraining = function(index) {
            vm.productLine.expectedTrainings.splice(index, 1);
        };

        vm.addExpectedTraining = function() {
            if (vm.newTraining != null) {
                var alreadyAdded = false;

                vm.productLine.expectedTrainings.forEach(function(expt) {
                    if (expt.training.id == vm.newTraining.id)
                        alreadyAdded = true;
                })

                if (!alreadyAdded) {
                    var newExpectedTraining = {id:null, training: vm.newTraining, required: false };
                    vm.productLine.expectedTrainings.push(newExpectedTraining);
                }

                vm.newTraining = null;
            }
        };
    }
})();
