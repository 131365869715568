(function () {
    'use strict';

    angular
            .module('abcQualityMatchApp')
            .controller('TaskVerificationController', TaskVerificationController);

    TaskVerificationController.$inject = ['$scope', '$state', '$stateParams','TaskVerificationRedone', 'AlertService', 'questionnaire', 'tasks', 'TicketStateTypeList', 'DataUtils'];

    function TaskVerificationController($scope, $state, $stateParams, TaskVerificationRedone, AlertService, questionnaire, tasks, TicketStateTypeList, DataUtils) {
        var vm = this;
        window.vvm = vm;
        vm.questionnaire = questionnaire;
        vm.tasks = tasks;
        vm.questions = new Array();
        vm.verificationTasks = new Array();
        vm.ticketStates = new Array();

        loadTicketStates();
        loadAll();

        function loadAll() {

            TaskVerificationRedone.getVerificationAnswers({parentTaskId: $stateParams.parentTaskId, questionnaireId: vm.questionnaire.id}, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.taskEvents = data;
                var taskMap = new Map();
                for (var i = 0; i < tasks.length; i++) {
                    taskMap.set(tasks[i].id, i);
                }

                vm.questionnaire.sections.forEach(function (section) {
                    section.questions.forEach(function (question) {
                        var newQuestion = angular.copy(question);
                        var answerArray = new Array(tasks.length);
                        vm.taskEvents.forEach(function (row) {
                            if (row.questionId == question.id) {
                                answerArray[taskMap.get(row.taskId)] = row;
                            }
                        });
                        newQuestion.answerArray = answerArray;
                        vm.questions.push(newQuestion);
                    });
                });

                vm.tasks.forEach(function (task) {
                    var verificationTask = {};
                    verificationTask.id = task.id;
                    verificationTask.state = angular.copy(task.mainTicket.state);
                    verificationTask.comment = null;
                    if (vm.verificationTasks.length > 0) {
                        verificationTask.copy = true;
                    } else {
                        verificationTask.copy = false;
                    }
                    vm.verificationTasks.push(verificationTask);

                    var answerArray = new Array();
                    vm.taskEvents.forEach(function (row) {
                        if (row.taskId == task.id) {
                            answerArray.push(row);
                        }
                    });
                    task.answerArray = answerArray;
                });
                loadAttachments();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadTicketStates() {
            TicketStateTypeList.query({"ticketType": 'TASK', "size": 1000, "sort": "listIndex,asc"}, onSuccess, onError);

            function onSuccess(data, header) {
                data.forEach( function (ticket) {
                    if (ticket.ticketStateType === "DONE" || ticket.ticketStateType === "VERIFIED") {
                        vm.ticketStates.push(ticket);
                    }
                });
            }

            function onError() { }
        }

        vm.save = function () {
            TaskVerificationRedone.verifyTasks(vm.verificationTasks, onSaveSuccess, onSaveError);

            function onSaveSuccess() {
                $state.go("home");
            }

            function onSaveError() {
                alert("Virhe tapahtui lomaketta tallennettaessa.");
            }
        }

        vm.answerClassCheck = function (answer) {
            if (answer == undefined || answer.answerAnomaly == undefined || answer.answerAnomaly == false) {
                return "";
            } else if (answer.answerAnomalyConfirmed == true) {
                return "alert-warning";
            } else {
                return "bg-danger";
            }
        };

        vm.taskClassCheck = function (task) {
            if (task.answerArray == null || task.answerArray == undefined || task.answerArray.length == 0) {
                return "";
            } else if (task.answerArray[0].qaAnomaly == false) {
                return "";
            } else if (task.answerArray[0].qaAnomalyConfirmed == true) {
                return "alert-warning";
            } else {
                return "bg-danger";
            }
        };

        vm.copy = function () {
            vm.verificationTasks.forEach(function (verificationTask) {
                if (verificationTask.copy) {
                    verificationTask.state = angular.copy(vm.verificationTasks[0].state);
                    verificationTask.comment = vm.verificationTasks[0].comment;
                }
            });
        }

        vm.roundAnswerValueIfDecimal = function (answerType, value) {
            if (answerType != "decimal"){
                return value;
            }else{
                if (value != null) {
                    var parts = value.split(".");
                    if (parts.length > 1) {
                        // If value already has enough decimals?
                        if (parts[1].length >= 2) {
                            return value;
                        }
                    }
                    return parseFloat(value).toFixed(2);
                }
            }
        }

        function loadAttachments() {
            vm.questions.forEach(function (question) {
                question.answerArray.forEach(function (answer) {
                    console.log('task verification ', answer);
                    answer.storageFiles = new Array();
                    
                    TaskVerificationRedone.getAnswerAttachments(answer, onSuccess, onError);
                    function onSuccess(data, header) {
                        console.log(data)
                        data.forEach(function (storageFile) {
                            storageFile.visible = false;
                        })
                        answer.storageFiles = data;
                    }
                    function onError() {
                        alert("Virhe tapahtui liitetta hakiessa.");
                    }
                })
            });
        }

    }
})();
