(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TaskDialogController', TaskDialogController);

    TaskDialogController.$inject = ['$translate', '$timeout', '$scope', '$stateParams',        '$uibModalInstance',
                                    'entity',     'Task',     'User',   'TicketStateTypeList', 'uiDatetimePickerConfig',
                                    'QuestionnaireCompany', 'UserGroup', 'ProductLine',
                                    'TicketRelatedMaterial', 'TicketMaterial', 'DataUtils',
                                     'AlertService'];

    function TaskDialogController ($translate, $timeout, $scope, $stateParams,         $uibModalInstance,
                                     entity,     Task,      User,   TicketStateTypeList, uiDatetimePickerConfig,
                                     QuestionnaireCompany, UserGroup, ProductLine,
                                     TicketRelatedMaterial, TicketMaterial, DataUtils,
                                     AlertService) {
        var vm = this;
        window.vvm = vm;
        vm.task = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.addedMaterialids =[];
        vm.uploadingTicketMaterial = false;
        vm.ticketMaterials = [];
        vm.uploadTicketMaterial = uploadTicketMaterial;
        vm.deleteTicketMaterial = deleteTicketMaterial;
        vm.selectedFileTooLarge = false;
        loadTicketMaterials();


        vm.usergroups = UserGroup.query({"size": 1000});
        vm.productLines = ProductLine.query({"size": 1000});
        vm.ticketstates = TicketStateTypeList.query({"ticketType":'TASK', "size": 1000, "sort":"listIndex,asc"});
        vm.questionnaires = QuestionnaireCompany.QueryByType.query({"questionnaireType":'NORMAL', "size": 1000});
        vm.clickAllDay = clickAllDay;

        vm.picker = {
            timepickerOptions: {
                showMeridian: false
            }
        };

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.task.addedMaterialids = vm.addedMaterialids;
            if (vm.task.id !== null) {
                Task.update(vm.task, onSaveSuccess, onSaveError);
            } else {
                Task.save(vm.task, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:taskUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.start = false;
        vm.datePickerOpenStatus.end = false;
        vm.datePickerOpenStatus.until = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
            uiDatetimePickerConfig.buttonBar.today.text = $translate.instant('global.datepicker.today');
            uiDatetimePickerConfig.buttonBar.now.text = $translate.instant('global.datepicker.now');
            uiDatetimePickerConfig.buttonBar.time.text = $translate.instant('global.datepicker.time');
            uiDatetimePickerConfig.buttonBar.date.text = $translate.instant('global.datepicker.date');
            uiDatetimePickerConfig.buttonBar.clear.text = $translate.instant('global.datepicker.clear');
            uiDatetimePickerConfig.buttonBar.close.text = $translate.instant('global.datepicker.close');
        }

        function clickAllDay() {
            if (vm.task.allDay && vm.task.freq == 'HOURLY') {
                vm.task.freq = null;
            }
        }






        // Material

        function loadTicketMaterials() {

            var parameters = {};
            if (vm.task != null && vm.task.ticketId > 0) {
                parameters.ticketId = vm.task.ticketId;
            }
            if (vm.addedMaterialids.length > 0) {
                parameters.addedMaterialIds = vm.addedMaterialids.join();
            }


            TicketRelatedMaterial.Materials.query(parameters, onSuccess, onError);
            function onSuccess(data) {
                vm.ticketMaterials = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.setFileContent = function ($file, $invalidFiles) {

            vm.selectedFileTooLarge = false;
            if ($invalidFiles && $invalidFiles.length > 0) {
                invalidFileSelected($invalidFiles);
                return;
            }

            if ($file == null) {
                return;
            }

            var ticketMaterial = {
                name: $file.name,
                fileContentContentType: $file.type,
                company: {},
                ticket: null
            };
            if (vm.task.ticketId > 0) {
                ticketMaterial.ticket = {id:vm.task.ticketId};
            }


            DataUtils.toBase64($file, function (base64Data) {
                ticketMaterial.fileContent = base64Data;
                vm.uploadTicketMaterial(ticketMaterial);
            });
        };

        function invalidFileSelected($invalidFiles) {

            console.log('invalid file selected ' + JSON.stringify($invalidFiles));
            vm.selectedFileTooLarge = true;
            $timeout(function () {
                vm.selectedFileTooLarge = false;
            }, 3000);


        }

        function uploadTicketMaterial(ticketMaterial) {
            vm.selectedFileTooLarge = false;
            vm.uploadingTicketMaterial = true;

            ticketMaterial.listIndex = vm.ticketMaterials.length;


            TicketMaterial.save(ticketMaterial, onSuccess, onError);

            function onSuccess(ticketMaterial) {
                console.log('uploaded ' + +ticketMaterial.id);
                if (ticketMaterial.ticket == null) {
                    vm.addedMaterialids.push(ticketMaterial.id);
                }
                vm.uploadingTicketMaterial = false;
                loadTicketMaterials();
            }
            function onError(err) {
                vm.uploadingTicketMaterial = false;
                console.log("failed to upload ticketmaterial " + err);
            }
        }

        function deleteTicketMaterial(tm) {
            TicketMaterial.delete(tm, onSuccess, onError);

            function onSuccess(ticketMaterial) {
                console.log('deleted ticket material');
                loadTicketMaterials();
            }
            function onError(err) {
                console.log("failed to delete ticketmaterial " + err);
            }


        }







    }
})();
