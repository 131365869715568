(function() {

    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('AnswerAttachmentDialogController',AnswerAttachmentDialogController);

    AnswerAttachmentDialogController.$inject = ['$uibModalInstance', '$timeout', '$scope', '$state', '$stateParams', 'Task', 'DataUtils'];

    function AnswerAttachmentDialogController($uibModalInstance, $timeout, $scope, $state, $stateParams, Task, DataUtils) {
        var vm = this;

        vm.file = null;
        vm.fileContent = null;
        vm.clear = clear;
        vm.add = add;
        vm.camera = camera;
        vm.takePhoto = takePhoto;
        vm.fileSelected = fileSelected;
        vm.isCamera=false;
        vm.isSnapshot=false;
        vm.image_data_url = '';
        vm.selectedFileTooLarge = false;

        console.log('answer attachment dialog controller', $stateParams);

        function clear () {
            console.log('Clear');
            $uibModalInstance.dismiss('Clear');
        }

        function camera() {
            if (vm.isCamera) {
                vm.isCamera=false;
            } else {
                vm.isCamera=true;
                enableCamera();
            }
        }
        function enableCamera() {
            // turns camera on (if there is any)
            navigator.mediaDevices.getUserMedia({ video: true, audio: false })
            .then(function(stream) {
                video.srcObject = stream;
            })
            .catch(function(err) {
              console.log('media device error ->', err);
            });
        }

        function takePhoto() {
            console.log('take photo');
            vm.isSnapshot = true;

            var video = angular.element("#video");
            var snapshot = angular.element("#snapshot");

            // get image from live camera feed -> put it on canvas
            var ctx = snapshot[0].getContext('2d')
            ctx.drawImage(video[0], 0, 0, snapshot[0].width, snapshot[0].height);
            // image data
            vm.image_data_url = snapshot[0].toDataURL('image/jpeg');
            // hide camera
            vm.isCamera = false;
            // data url of the image -> get connten excluding the mimetype
            vm.fileContent = vm.image_data_url.split(",")[1];

            vm.file = {
                name: 'SNAPSHOT_' + Math.floor(Date.now() / 1000),
                type: 'image/jpeg',
            }
        }

        function add () {
            if (vm.file) {
                console.log('add -> file content');
                var dataDTO = {
                    id: null,
                    companyId: null,
                    fileName: vm.file.name,
                    contentType: vm.file.type,
                    content: vm.fileContent
                }
                console.log('dataDTO', dataDTO);

                Task.saveAnswerAttachment(dataDTO, onSuccess, onError);

                function onSuccess(response) {
                    console.log('Task saveAnswerAttachment success response ->', response)
                    $uibModalInstance.close(response);
                }
                function onError(error) {
                    console.log('Task saveAnswerAttachment error ->', error);
                    alert('Tallennus ei onnistunut');
                }
            }
        }

        function fileSelected ($file, $invalidFiles) {
            //console.log('file selected !', $file);

            vm.selectedFileTooLarge = false;
            if ($invalidFiles && $invalidFiles.length > 0) {
                invalidFileSelected($invalidFiles);
                return;
            }

            if ($file != null) {
                vm.file = $file;
                DataUtils.toBase64($file, function (base64Data) {
                    vm.fileContent = base64Data;
                });
            }
        }

        function invalidFileSelected($invalidFiles) {

            console.log('invalid file selected ' + JSON.stringify($invalidFiles));
            vm.selectedFileTooLarge = true;
            $timeout(function () {
                vm.selectedFileTooLarge = false;
            }, 3000);

        }

    }
})();
