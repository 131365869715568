(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('ticket', {
            parent: 'app',
            url: '/ticket',
            data: {
                authorities: ['ROLE_COMPANY_USER', 'ROLE_COMPANY_ADMIN', 'ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/ticket/ticket.html',
                    controller: 'TicketController',
                    controllerAs: 'vm'
                }
            }
        })       
        .state('ticket-new', {
            parent: 'ticket',
            url: '/new',
            data: {
                authorities: ['ROLE_COMPANY_USER','ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/ticket/ticket-dialog.html',
                    controller: 'TicketDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'                    
                }).result.then(function() {
                    $state.go('home', null, { reload: 'home' });
                }, function() {
                    $state.go('home');
                });
            }]
        });
    }
})();