(function() {
    'use strict';


    angular
        .module('abcQualityMatchApp').filter('trainingMaterialEncodeFilename', function() {

            return function(name){ return  window.encodeURIComponent(name) };
        });

    angular
        .module('abcQualityMatchApp').filter('trainingMaterialLinkUrlAddProtocoll', function() {
            return function(url) {
                if (!url.match('^.+://')){
                    url = 'http://' + url;
                }
                return url;
            }
        });

    angular
        .module('abcQualityMatchApp')
        .controller('TrainingDialogController', TrainingDialogController);

    TrainingDialogController.$inject = ['$uibModal','$rootScope','$translate', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Training', 'User','DataUtils', 'TrainingMaterial','TrainingRelatedMaterial' ,'AlertService'];

    function TrainingDialogController($uibModal, $rootScope, $translate, $timeout, $scope, $stateParams, $uibModalInstance, entity, Training, User, DataUtils, TrainingMaterial, TrainingRelatedMaterial, AlertService) {
        var vm = this;

        vm.training = entity;
        vm.training.addedMaterialids =[];
        vm.clear = clear;
        vm.save = save;
        vm.uploadingTrainingMaterial = false;



        vm.addNewChecklistItemName = addNewChecklistItemName;
        vm.deleteChecklistItem = deleteChecklistItem;
        vm.moveChecklistItem = moveChecklistItem;
        vm.addExamQuestion = addExamQuestion;
        vm.deleteExamQuestion = deleteExamQuestion;
        vm.moveExamQuestion = moveExamQuestion;


        vm.trainingMaterials = [];
        vm.uploadTrainingMaterial = uploadTrainingMaterial;
        vm.deleteTrainingMaterial = deleteTrainingMaterial;
        vm.showAddMaterialLinkDialog = showAddMaterialLinkDialog;
        vm.moveTrainingMaterial = moveTrainingMaterial;
        vm.selectedFileTooLarge = false;

        loadTrainingMaterials();

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.training.id !== null) {
                Training.update(vm.training, onSaveSuccess, onSaveError);
            } else {
                Training.save(vm.training, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:trainingUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        function addNewChecklistItemName() {

            var newItem = {
                name: "",
                listIndex: vm.training.checklistItems.length
            };
            vm.training.checklistItems.push(newItem);
            return false;
        }

        function deleteChecklistItem(index) {
            vm.training.checklistItems.splice(index,1);
            updateListIndexes(vm.training.checklistItems);
            return false;
        }

        function moveChecklistItem(index, direction) {
            if(direction === "up") {
                vm.training.checklistItems[index-1].listIndex++;
                vm.training.checklistItems[index].listIndex--;
            }
            else {
                vm.training.checklistItems[index+1].listIndex--;
                vm.training.checklistItems[index].listIndex++;
            }
            vm.training.checklistItems.sort(function(a,b){
                return a.listIndex - b.listIndex;
            });
            updateListIndexes(vm.training.checklistItems);
        }


        function addExamQuestion(){
            var question = { questionText: '',
                             expectedAnswer: true,
                             listIndex: vm.training.checklistItems.length,
                         };
            vm.training.examQuestions.push(question);

            return false;
        }

        function deleteExamQuestion(index) {
            vm.training.examQuestions.splice(index,1);
            updateListIndexes(vm.training.examQuestions);
            return false;
        }

        function moveExamQuestion(index, direction) {
            if(direction === "up") {
                vm.training.examQuestions[index-1].listIndex++;
                vm.training.examQuestions[index].listIndex--;
            }
            else {
                vm.training.examQuestions[index+1].listIndex--;
                vm.training.examQuestions[index].listIndex++;
            }
            vm.training.examQuestions.sort(function(a,b){
                return a.listIndex - b.listIndex;
            });
            updateListIndexes(vm.training.examQuestions);
        }

        function updateListIndexes(list){
            list.forEach(function(currentValue, index, array) {
                currentValue.listIndex = index;
            });
        }


        function moveTrainingMaterial(index, direction){
            var tm = vm.trainingMaterials[index];

            var step = 1;
            if (direction === 'down'){
                step = -1;
            }

            var parameters = {
                newListIndex: tm.listIndex + step

            };

            if (vm.training.id > 0){
                parameters.trainingId = vm.training.id;
            }
            if (vm.training.addedMaterialids.length > 0){
                parameters.addedMaterialIds = vm.training.addedMaterialids.join();
            }


            TrainingRelatedMaterial.Order.updateListIndex({trainingMaterialId: tm.id,  inParams:parameters}, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.trainingMaterials = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }


        }


        function loadTrainingMaterials() {

            var parameters = {};
            if (vm.training.id > 0){
                parameters.trainingId = vm.training.id;
            }
            if (vm.training.addedMaterialids.length > 0){
                parameters.addedMaterialIds = vm.training.addedMaterialids.join();
            }


            TrainingRelatedMaterial.Materials.query(parameters, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.trainingMaterials = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.setFileContent = function ($file, $invalidFiles) {

            vm.selectedFileTooLarge = false;
            if ($invalidFiles && $invalidFiles.length > 0){
                invalidFileSelected($invalidFiles);
                return;
            }

            if ($file == null) {
                return;
            }

            var trainingMaterial = {
                name: $file.name,
                fileContentContentType: $file.type,
                company: {},
                training: null
            };
            if (vm.training.id){
                trainingMaterial.training = vm.training;
            }


            DataUtils.toBase64($file, function(base64Data) {
                trainingMaterial.fileContent = base64Data;
                vm.uploadTrainingMaterial(trainingMaterial);
            });
        };

        function invalidFileSelected($invalidFiles){

            console.log('invalid file selected ' + JSON.stringify($invalidFiles));
            vm.selectedFileTooLarge = true;
            $timeout(function(){vm.selectedFileTooLarge = false;}, 3000);


        }

        function uploadTrainingMaterial(trainingMaterial){
            vm.selectedFileTooLarge = false;
            vm.uploadingTrainingMaterial = true;

            trainingMaterial.listIndex = vm.trainingMaterials.length;


            TrainingMaterial.save(trainingMaterial, onSuccess, onError);

            function onSuccess(trainingMaterial){
                console.log('uploaded '+  + trainingMaterial.id);
                if (trainingMaterial.training == null){
                    vm.training.addedMaterialids.push(trainingMaterial.id);
                }
                vm.uploadingTrainingMaterial = false;
                loadTrainingMaterials();
            }
            function onError (err) {
                vm.uploadingTrainingMaterial = false;
                console.log("failed to upload trainingmaterial " + err);
            }
        }

        function deleteTrainingMaterial(tm){
            TrainingMaterial.delete(tm, onSuccess, onError);

            function onSuccess(trainingMaterial){
                console.log('deleted training material');
                loadTrainingMaterials();
            }
            function onError (err) {
                console.log("failed to delete trainingmaterial " + err);
            }


        }

        function showAddMaterialLinkDialog(){

            $uibModal.open({
                templateUrl: 'app/training-main/training/training-material-add-link-dialog.html',
                controller: 'TrainingMaterialLinkController',
                controllerAs: 'vm',
                size: 'sm'
            }).result.then(function (newTrainingMaterialLinkUrl) {
                console.log("adding material link " + newTrainingMaterialLinkUrl);

                if (newTrainingMaterialLinkUrl == null || newTrainingMaterialLinkUrl.trim().length == 0){
                    console.log("newTrainingMaterialLinkUrl was empty");
                    return;
                }

                var trainingMaterial = {
                    name: "",
                    company: {},
                    training: null,
                    linkUrl: newTrainingMaterialLinkUrl
                };

                if (vm.training.id){
                    trainingMaterial.training = vm.training;
                }
                vm.uploadTrainingMaterial(trainingMaterial);
            });

        }

    }


    angular
            .module('abcQualityMatchApp')
            .controller('TrainingMaterialLinkController', TrainingMaterialLinkController);

    TrainingMaterialLinkController.$inject = ['$uibModalInstance', '$timeout'];

    function TrainingMaterialLinkController($uibModalInstance, $timeout) {
        var vm = this;
        vm.newTrainingMaterialLinkUrl = "";

        $timeout(function (){
            angular.element('#materialLink').focus();
        });

        vm.closeDialog = function() {
            $uibModalInstance.dismiss('cancel');
        }
        vm.save = function() {
            $uibModalInstance.close(vm.newTrainingMaterialLinkUrl);
        }
    }

})();
