(function() {
    'use strict';
    angular.module('abcQualityMatchApp').component('questionnaireAnswersView', {
        templateUrl: 'app/components/questionnaire-answers-view/questionnaire-answers-view.component.html',
        controllerAs: 'vm',
        bindings: {
            questionnaireAnswer: '<'
        },
        restrict: 'E',
        controller: QuestionnaireAnswersView
    });

    QuestionnaireAnswersView.$inject = ['$scope', 'AlertService'];
    function QuestionnaireAnswersView(scope, AlertService) {
        var vm = this;

        setupQestionAnswerRelations(this.questionnaireAnswer);



        function setupQestionAnswerRelations(questionnareAnswer) {

            var answerMap = new Map();
            questionnareAnswer.answers.forEach(function (answer) {
                answerMap.set(answer.question.id, answer);
            });

            questionnareAnswer.questionnaire.sections.forEach(function (section) {
                section.questions.forEach(function (question) {
                    var answer = answerMap.get(question.id);

                    if (!answer) {
                        answer = {};
                        answer.questionRootVersionId = question.rootVersionId;
                        answer.answerType = question.questionType;
                        answer.anomaly = false;
                        answer.anomalyConfirmed = false;
                        if (answer.answerType === "multi") {
                            answer.optionAnswers = [];
                        } else {
                            answer.value = null;
                        }
                    }
                    question.answer = answer;
                });
            });
        }
    } // end of QuestionnaireAnswersView

})();

