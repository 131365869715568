(function () {
    'use strict';

    var tasks = {
        templateUrl: 'app/components/home/tasks/tasks.html',
        controllerAs: 'vm',
        controller: ['TaskEvent','$state', function (TaskEvent, $state) {
                var vm = this;
                window.vvm = vm;
                vm.dateCategories = [{dateCategory: 'LATE'}, {dateCategory: 'TODAY'}, {dateCategory: 'TOMORROW'}, {dateCategory: 'THIS_WEEK'}, {dateCategory: 'NEXT_WEEK'}];
                vm.tasks = null;

                loadTasks();

                function loadTasks() {
                    TaskEvent.UserLatest.query({}, onSuccess, onError);
                    function onSuccess(data, headers) {
                        vm.tasks = data;
                        vm.dateCategories.forEach(function (parent) {
                            parent.children = vm.tasks.filter(function (value) {
                                return value.dateCategory === parent.dateCategory;
                            });
                        });
                    }
                    function onError(error) {
                        console.log("failed");
                    }
                }
                
                vm.confirm = function (taskId, recurrenceDateId) {
                    TaskEvent.Confirm.confirm({"taskId": taskId, "recurrenceDateId": recurrenceDateId}, onSuccess, onError);
                    function onSuccess(data, headers) {
                        $state.reload();
                    }
                    function onError(error) {
                        console.log("failed");
                    }
                }
                
                vm.taskClassCheck = function (task) {
                    if (task.dateCategory == 'LATE') {
                        return "bg-danger";
                    } else {
                        return "";
                    }
                };
            }]
    };

    angular
            .module('abcQualityMatchApp')
            .component('tasks', tasks);


})();