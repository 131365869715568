(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('QuestionnaireGroupDeleteController',QuestionnaireGroupDeleteController);

    QuestionnaireGroupDeleteController.$inject = ['$uibModalInstance', 'entity', 'QuestionnaireGroup'];

    function QuestionnaireGroupDeleteController($uibModalInstance, entity, QuestionnaireGroup) {
        var vm = this;

        vm.questionnaireGroup = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            QuestionnaireGroup.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
