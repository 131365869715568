(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('DocumentFileDetailController', DocumentFileDetailController);

    DocumentFileDetailController.$inject = [
        '$state', '$scope', '$rootScope',
        '$stateParams', 'previousState', 'documentFileVersioning',
        '$uibModal', 'DocumentFile'];

    function DocumentFileDetailController(
        $state, $scope, $rootScope,
        $stateParams, previousState, documentFileVersioning,
        $uibModal, DocumentFile) {

        var vm = this;

        vm.documentFileVersioning = documentFileVersioning;
        vm.previousState = previousState.name;
        vm.archivedVisible = [];


        vm.TAB_INDEXES = {
            curreent: 0,
            next: 1,
            archived: 2
        };


        if (vm.documentFileVersioning.currentVersion){
            vm.activeTab = vm.TAB_INDEXES.current;
        }
        else {
            vm.activeTab = vm.TAB_INDEXES.next;
        }

        if ($stateParams.tab == 'current'){
            vm.activeTab = vm.TAB_INDEXES.current;
        }
        else if ($stateParams.tab == 'next'){
            vm.activeTab = vm.TAB_INDEXES.next;
        }


        function reloadFileVersioning(){
            DocumentFile.getVersioningForRootId({rootVersionId : vm.documentFileVersioning.rootVersionId} ,onSuccess, onFail);

            function onSuccess(updatedVersioning){
                vm.documentFileVersioning = updatedVersioning;
            }
            function onFail(){
                console.log("failed to load changes");
            }
        }



        vm.toggleArchivedVisible = function(index){
            vm.archivedVisible[index] = !vm.archivedVisible[index];
        }

        vm.editCurrentVersion = function(){
            openEditDialog(vm.documentFileVersioning.currentVersion, 'metadataCorrection');
        };

        vm.editNextVersion = function(){
            openEditDialog(vm.documentFileVersioning.nextVersion, 'nextVersionEdit');
        };

        vm.createNextVersion = function(){

            var documentFile = angular.copy(vm.documentFileVersioning.currentVersion);
            documentFile.state="DRAFT";
            documentFile.fileContent = null;
            documentFile.fileName = null;
            documentFile.fileContentContentType = null;

            documentFile.validityEndDate = null;
            documentFile.validityEndAlertDate = null;
            documentFile.lastReviewDate = null;
            documentFile.approvalDate = null;
            documentFile.inspectionDate = null;
            documentFile.publishedDate = null;

            openEditDialog(documentFile, 'newVersion');
        };



        function openEditDialog(documentFile, useCase){
                $uibModal.open({
                    templateUrl: 'app/document/document-tree/document-file/edit-dialog/document-file-edit-dialog.html',
                    controller: 'DocumentFileEditDialogController',
                    controllerAs: 'vm',
                    size: 'lg',
                    backdrop: 'static',
                    resolve:{
                        documentFile: function (){return angular.copy(documentFile);},
                        useCase: function(){return useCase;}
                    }
                }).result.then(function(result) {
                    reloadFileVersioning();

                });
        }

    }
})();
