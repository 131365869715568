(function () {
    'use strict';
    angular
            .module('abcQualityMatchApp')
            .factory('Project', Project);

    Project.$inject = ['$resource', 'DateUtils'];

    function Project($resource, DateUtils) {
        var resourceUrl = 'api/projects/:id';

        return {
            CRUD: $resource(resourceUrl, {}, {
                'query': {method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                            data.startDate = DateUtils.convertLocalDateFromServer(data.startDate);
                            data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);
                            data.ticket.doneTime = DateUtils.convertDateTimeFromServer(data.ticket.doneTime);
                        }
                        return data;
                    }
                },
                'update': {
                    method: 'PUT',
                    transformRequest: function (data) {
                        var copy = angular.copy(data);
                        copy.startDate = DateUtils.convertLocalDateToServer(copy.startDate);
                        copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);
                        return angular.toJson(copy);
                    }
                },
                'save': {
                    method: 'POST',
                    transformRequest: function (data) {
                        var copy = angular.copy(data);
                        copy.startDate = DateUtils.convertLocalDateToServer(copy.startDate);
                        copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);
                        return angular.toJson(copy);
                    }
                }
            }),
            Phases: $resource(resourceUrl + '/phases', {}, {'query': {method: 'GET', isArray: true}}),
            UsersOpenProjects: $resource('api/projects/open', {}, {'query': {method: 'GET', isArray: true}})
        };
    }
})();
