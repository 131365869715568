(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('ReasonCodeDetailController', ReasonCodeDetailController);

    ReasonCodeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ReasonCode', 'Company'];

    function ReasonCodeDetailController($scope, $rootScope, $stateParams, previousState, entity, ReasonCode, Company) {
        var vm = this;

        vm.reasonCode = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('abcQualityMatchApp:reasonCodeUpdate', function(event, result) {
            vm.reasonCode = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
