(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TrainingEventDeleteController',TrainingEventDeleteController);

    TrainingEventDeleteController.$inject = ['$uibModalInstance', 'entity', 'TrainingEvent'];

    function TrainingEventDeleteController($uibModalInstance, entity, TrainingEvent) {
        var vm = this;

        vm.trainingEvent = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        vm.showDeleteHint = false;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            TrainingEvent.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                },onError);
        }
        
        function onError(result){
            if (result.status === 409){
                vm.showDeleteHint = true; 
            }
        }
    }
})();
