(function() {
    'use strict';
    angular
        .module('abcQualityMatchApp')
        .factory('ReasonCode', ReasonCode);

    ReasonCode.$inject = ['$resource'];

    function ReasonCode ($resource) {
        var resourceUrl =  'api/reason-codes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'forInformationType': {
                url: 'api/reason-codes/information-type/:questionnaireType',
                method: 'GET',
                isArray: true},

        });
    }
})();
