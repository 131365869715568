(function () {
    'use strict';
    angular
        .module('abcQualityMatchApp')
        .factory('TaskVerificationRedone', TaskVerificationRedone);

    TaskVerificationRedone.$inject = ['$resource'];

    function TaskVerificationRedone($resource) {
        var resourceUrl = 'api/task-verifications';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'getVerificationAnswers':{url: 'api/task-verifications/verification-answers/:parentTaskId/:questionnaireId', method:'GET', isArray:true},
            'getTasksToVerify': {url: 'api/task-verifications/tasks/:parentTaskId/:questionnaireId', method:'GET', isArray:true},
            'verifyTasks': {url: 'api/task-verifications/verify-tasks', method:'POST'},

            'getAnswerAttachments': {
                url:'/api/task-verifications/get-answer-attachments/:answerId',
                method:'GET',
                isArray: true
            }

        });


    }
})();
