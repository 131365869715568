(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('ProductionUnitDetailController', ProductionUnitDetailController);

    ProductionUnitDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ProductionUnit', 'Company'];

    function ProductionUnitDetailController($scope, $rootScope, $stateParams, previousState, entity, ProductionUnit, Company) {
        var vm = this;

        vm.productionUnit = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('abcQualityMatchApp:productionUnitUpdate', function(event, result) {
            vm.productionUnit = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
