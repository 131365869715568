(function () {
    'use strict';

    angular
            .module('abcQualityMatchApp')
            .controller('TraineeTrainingAvailableController', TraineeTrainingAvailableController);

    TraineeTrainingAvailableController.$inject = [
        '$uibModal','$location', '$scope',
        '$state', 'Training', 'ParseLinks',
        'AlertService','paginationConstants',
        'pagingParams', 'TraineeEventSignup','TrainingEvent'];

    function TraineeTrainingAvailableController(
        $uibModal, $location, $scope,
        $state, Training, ParseLinks,
        AlertService, paginationConstants,
        pagingParams, TraineeEventSignup, TrainingEvent) {
        var vm = this;

        vm.independentStudyTrainings = [];
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.confirmSignupToIndependentStudyTraining = confirmSignupToIndependentStudyTraining;
        vm.confirmSignupToTrainingEvent = confirmSignupToTrainingEvent;
        vm.isSigningUp = false;
        vm.failureReason = null;

        loadSelfStudies();
        loadAvailableTrainingEvents();

        function loadAvailableTrainingEvents() {
            TrainingEvent.getAvailableForSignUp({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.trainingEvents = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadSelfStudies() {
            TrainingEvent.getIndependentTrainingAvailableForSignUp({}, onSuccess, onError);

            function onSuccess(data) {
                vm.independentStudyTrainings = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function confirmSignupToTrainingEvent(trainingEvent) {
            vm.failureReason = null;

            $uibModal.open({
                templateUrl: 'app/training-main/trainee/trainee-training-signup-confirm-dialog.html',
                controller: 'TrainingSignupConfirmController',
                controllerAs: 'vm',
                size: 'sm'
            }).result.then(function (confirmd) {
               if (confirmd){
                   vm.isSigningUp = true;
                   TraineeEventSignup.signupToTrainingEvent({trainingEventId: trainingEvent.id}, onSignupSuccess, onSignupFailure);
               }
            });
        }

        function confirmSignupToIndependentStudyTraining(independentStudyTraining) {
            vm.failureReason = null;

            $uibModal.open({
                templateUrl: 'app/training-main/trainee/trainee-training-signup-confirm-dialog.html',
                controller: 'TrainingSignupConfirmController',
                controllerAs: 'vm',
                size: 'sm'
            }).result.then(function (confirmd) {
               if (confirmd){
                   vm.isSigningUp = true;
                   TraineeEventSignup.signupToIndependentStydyTraining({trainingId: independentStudyTraining.id}, onSignupSuccess, onSignupFailure);
               }
            });
        }

        function onSignupSuccess(result) {
            vm.isSigningUp = false;
            //$location.path('/trainee/records');
            $state.reload();
        }

        function onSignupFailure(result) {
            vm.isSigningUp = false;
            if (result.status === 409){
                vm.failureReason = result.data.reason;
            }
        }
    }
})();
