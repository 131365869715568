(function () {
    'use strict';

    var anomalies = {
        templateUrl: 'app/components/home/anomalies/anomalies.html',
        controllerAs: 'vm',
        controller: ['Anomaly', 'QuestionnaireCompany', '$state', function (Anomaly, QuestionnaireCompany, $state) {
                var vm = this;
                vm.anomalies = null;
                vm.informationQuestionnaires = null;                

                loadAnomalies();
                loadInformationQuestionnaires();

                function loadAnomalies() {
                    Anomaly.UserLatest.query({}, onSuccess, onError);
                    function onSuccess(data, headers) {
                        vm.anomalies = data;
                    }
                    function onError(error) {
                        console.log("failed");
                    }
                }
                
                function loadInformationQuestionnaires() {
                    QuestionnaireCompany.QueryByType.query({"questionnaireType": 'ANOMALY', "size": 1000}, onSuccess, onError);
                    function onSuccess(data, headers) {
                        vm.informationQuestionnaires = data;
                    }
                    function onError(error) {
                        console.log("failed");
                    }
                }

                vm.reject = function (qaId, answerId) {
                    Anomaly.Answer.reject({"questionnaireAnswerId": qaId, "answerId": answerId}, onSuccess, onError);
                    function onSuccess(data, headers) {
                        $state.go("home");
                    }
                    function onError(error) {
                        console.log("failed");
                    }
                }
            }]
    };

    angular
            .module('abcQualityMatchApp')
            .component('anomalies', anomalies);


})();