(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TaskExecutionsController', TaskExecutionsController);

    TaskExecutionsController.$inject = [
        '$scope', '$rootScope', '$stateParams',
        'previousState', 'Task',
        'DateUtils', 'ParseLinks', 'AlertService',
        'paginationConstants','pagingParams','$state', 'task', 'TaskVerificationRedone'];

    function TaskExecutionsController(
        $scope, $rootScope, $stateParams,
        previousState,  Task,
        DateUtils, ParseLinks, AlertService,
        paginationConstants, pagingParams, $state, task, TaskVerificationRedone) {

        var vm = this;
        vm.previousState = previousState.name + "(" + JSON.stringify(previousState.params) +")";

        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {fromDate: false, toDate:false };

        vm.filtering = {
            taskId: pagingParams.taskId,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate
        };

        vm.task = task;


        vm.executionsPerPage = 50;


        loadAll();

        function loadAll () {
            console.log('Load All');
            var parameters = {
                page: pagingParams.page - 1,
                size: vm.executionsPerPage
            };

            parameters = addFilteringParameters(parameters);

            Task.getExecutionsTables(parameters, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.taskExecutionsTables = data;
                vm.page = pagingParams.page;

                vm.hasNextPage = headers('hasNextPage') === 'true';
                vm.hasPreviousPage = vm.page > 1;
                loadAttachments();
            }
            function onError(error) {
                console.log(JSON.stringify(error));
                AlertService.error(error.data.message);
            }
        }

        function loadAttachments() {
            // console.log('Load Attachments', vm.taskExecutionsTables);
            vm.taskExecutionsTables.forEach(function(table) {
                table.questionnaire.questions.forEach(function (question) {
                    question.answers.forEach(function(answer) {
                        // console.log(answer);
                        // call get storage files
                        answer.storageFiles = new Array();
                        TaskVerificationRedone.getAnswerAttachments(answer, onSuccess, onError);
    
                        function onSuccess(data, header) {
                            console.log(data)
                            data.forEach(function (storageFile) {
                                storageFile.visible = false;
                            })
                            answer.storageFiles = data;
                        }
                        function onError() {
                            alert("Virhe tapahtui liitetta hakiessa.");
                        }
    
                    });
                });
            });
        }

        function addFilteringParameters(parameters) {

            parameters.taskId = vm.filtering.taskId;

            parameters.fromDate = null;
            if (vm.filtering.fromDate) {
                parameters.fromDate = DateUtils.convertLocalDateToServer(vm.filtering.fromDate);
            }

            parameters.toDate = null;
            if (vm.filtering.toDate) {
                parameters.toDate = DateUtils.convertLocalDateToServer(vm.filtering.toDate);
            }
            return parameters;
        }

        vm.refreshSearch = function(){
            vm.loadPage(1);
        };

        vm.loadPage = function(page) {
            vm.page = page;
            vm.transition();
        };

        vm.transition = function() {
            var parameters = {
                page: vm.page
            };
            parameters = addFilteringParameters(parameters);

            $state.transitionTo($state.$current, parameters);
        };



        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.setFloatPrecision = function(value) {
            var parts = value.toString().split(".");
            if (parts.length > 1){
                // If value already has enough decimals?
                if(parts[1].length >= 2){
                    return value;
                }
            }
            return parseFloat(value).toFixed(2);
        }

    }
})();
