(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('ProductImportdialogController', ProductImportdialogController);

    ProductImportdialogController.$inject = ['$uibModal','$timeout', '$scope',  'DataUtils','$stateParams', '$uibModalInstance','Product'];

    function ProductImportdialogController ($uibModal,$timeout, $scope,  DataUtils,$stateParams, $uibModalInstance, Product) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.archived = false;
        vm.importFail=false;

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            Product.uploadImportSpreadsheet({archived:vm.archived},{fileContent:vm.fileContent}, onSaveSuccess, onSaveFail);
        }

        function onSaveSuccess () {

            $uibModalInstance.close();
        }
        function onSaveFail(errors)
        {

            vm.errorData= errors.data;
            vm.errorType = vm.errorData.errorType;
            if (!vm.errorType) {
                vm.errorType="UNEXPECTED_ERROR"
            }

            vm.importFail=true;
        }

        vm.setFileContent = function ($file, $invalidFiles) {
            vm.selectedFileTooLarge = false;
            vm.isSaving=false;
            vm.badFile=false;

            if ($file) {
                vm.fileContent=null;
                var imgTypes = ["application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
                var typeIndex = imgTypes.indexOf($file.type);
                if (typeIndex < 0) {
                    console.log("wrong filetype " + $file.type+" ");
                    vm.isSaving=true;
                    vm.badFile=true;
                    vm.badFileLabel = "Väärä tiedostomuoto. Tuettu tiedostomuoto on .xls, .xlsx";
                    $file=null;
                    return;

                }

                console.log($file.type);
                vm.filename = $file.name;

                if ($invalidFiles && $invalidFiles.length > 0) {
                    vm.selectedFileTooLarge = true;
                    $timeout(function () {
                        vm.selectedFileTooLarge = false;
                    }, 3000);
                    return;
                }

                vm.preparingSelectedFile = true;
                DataUtils.toBase64($file, function (base64Data) {
                    vm.fileContent = base64Data;
                    vm.importFail=false;
                    $timeout(function () {
                        vm.preparingSelectedFile = false;
                        console.log("done setting file data");
                    }, 1);

                });

            }

        };
        vm.openImportConfirmDialog = function(){
            $uibModal.open({
                templateUrl: 'app/company_admin/product/import-dialog/import-dialog-confirm.html',
                controller: 'ProductImportdialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg'
            });

        };

    }
})();
