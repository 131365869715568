(function() {
    'use strict';
    angular.module('abcQualityMatchApp').component('productSelect', {
        templateUrl: 'app/components/product-select/product-select-component.html',
        restrict: 'E',
        bindings: {
            product: '=',
            filteringProductId: '@',
            onSelect: '&',
            placeholder:'@',
            viewonly: '<'
        },
        controller: ProductSelectController
    });

    ProductSelectController.$inject = ['$timeout','$scope', 'Product']
    function ProductSelectController($timeout, scope, Product) {
        var ctrl = this;

        ctrl.getProducts = function(val){
            return Product.query({
                searchTerm: val,
                sort:'name,number,id',
                includeArchived: 0
            }).$promise;
        };

        ctrl.setSelected = function(){
            $timeout(function (){
                if (ctrl.onSelect) {
                    ctrl.onSelect();
                }
            });

        };

        ctrl.evaluateChange = function(){
            if(ctrl.product === null){
                $timeout(function (){
                    if (ctrl.onSelect) {
                        ctrl.onSelect();
                    }
                });
            }
        }
    }

})();
