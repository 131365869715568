(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('AdminHomeController', AdminHomeController);

    AdminHomeController.$inject = [];

    function AdminHomeController () {
        var vm = this;  
    }
})();
