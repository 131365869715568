(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TraineeSignDialogController', TraineeSignDialogController);

    TraineeSignDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TrainingRecord', 'TrainingEvent', 'User', 'Company'];

    function TraineeSignDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TrainingRecord, TrainingEvent, User, Company) {
        var vm = this;

        vm.trainingRecord = entity;
        vm.clear = clear;
        vm.save = save;
        
        vm.signed = vm.trainingRecord.traineeSignedDate != null;


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save(){
            
            if (!vm.signed || vm.trainingRecord.traineeSignedDate != null){
                return;
            }
            vm.isSaving = false;
            
            vm.trainingRecord.traineeSignedDate = new Date();
            TrainingRecord.update(vm.trainingRecord, onSaveSuccess, onSaveError);
            
        }

        function onSaveSuccess (result) {
            $scope.$emit('abcQualityMatchApp:trainingRecordUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
