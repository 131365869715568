(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('task-fulfilment', {
            parent: 'app',
            url: '/task-fulfilment/{taskId}?recurrenceDateId&returnToView',
            params : {
                taskId: null,
                recurrenceDateId: null,
                returnToView: null
            },
            data: {
                authorities: ['ROLE_COMPANY_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/task/task-fulfilment/task-fulfilment.html',
                    controller: 'TaskExecutionController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('question');
                    $translatePartialLoader.addPart('ticket');
                    $translatePartialLoader.addPart('ticketMaterial');
                    $translatePartialLoader.addPart('anomalyEvent');
                    $translatePartialLoader.addPart('information');
                    $translatePartialLoader.addPart('task');
                    $translatePartialLoader.addPart('questionnaire');
                    return $translate.refresh();
                }],
                taskFulfillmentDTO: ['$stateParams', 'Task', function($stateParams, Task) {
                    var params = {
                        taskId: $stateParams.taskId,
                        recurrenceDateId: $stateParams.recurrenceDateId
                    };
                    return Task.getTaskFulfillment(params).$promise;
                }],
            }
        })
    }
})();
