(function () {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .component('notableDocumentsListView', {
            templateUrl: 'app/document/notable-documents-list-component/notable-documents-list-component.html',
            controllerAs: 'vm',
            controller: NotableDocumentsListView
        });

    NotableDocumentsListView.$inject = ['DocumentFile', '$state','$uibModal'];
    function NotableDocumentsListView(DocumentFile, $state, $uibModal) {
        var vm = this;
        vm.notableFiles = null;

        load();

        function load() {
            DocumentFile.getNotableFiles({}, onSuccess, onError);
            function onSuccess(data) {
                vm.notableFiles = data;
            }
            function onError(error) {
                console.log("failed");
            }
        }

        vm.openStateChangeDialog = function(documentFile, useCase){
            console.log("state change dialog for %s to new useCase: %s", documentFile.id, useCase);

            $uibModal.open({
                templateUrl: 'app/document/state-change-dialog/document-state-change-dialog.html',
                controller: 'DocumentFileStateChangeDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    documentFile: documentFile,
                    useCase: function (){return useCase;}
                }
            }).result.then(function(result) {
                $state.reload();
            });

        };

    }
})();
