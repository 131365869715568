(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .controller('TrainingDetailController', TrainingDetailController);

    TrainingDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Training', 'Company'];

    function TrainingDetailController($scope, $rootScope, $stateParams, previousState, entity, Training, Company) {
        var vm = this;

        vm.training = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('abcQualityMatchApp:trainingUpdate', function(event, result) {
            vm.training = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
  
})();
