(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('task-verification', {
            parent: 'functions_menu',
            url: '/task-verification/{parentTaskId}/{questionnaireId}',
            data: {
                authorities: ['ROLE_COMPANY_ADMIN','VERIFICATION','VERIFICATION_BY_PRODUCT_LINE'],
                pageTitle: 'abcQualityMatchApp.taskVerification.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/task/task-verification/task-verification.html',
                    controller: 'TaskVerificationController',
                    controllerAs: 'vm'
                }
            },
            params: {},
            resolve: {
                questionnaire: ['Questionnaire', '$stateParams', function (Questionnaire, $stateParams) {
                        console.log("id:"+$stateParams.questionnaireId);
                        return Questionnaire.get({id: $stateParams.questionnaireId}).$promise;
                }],
                tasks: ['TaskVerificationRedone', '$stateParams', function (TaskVerificationRedone, $stateParams) {
                        return TaskVerificationRedone.getTasksToVerify({parentTaskId: $stateParams.parentTaskId, questionnaireId: $stateParams.questionnaireId}).$promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {                    
                    $translatePartialLoader.addPart('ticket');
                    $translatePartialLoader.addPart('information');
                    $translatePartialLoader.addPart('questionnaire');
                    $translatePartialLoader.addPart('task');
                    $translatePartialLoader.addPart('taskVerification');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
