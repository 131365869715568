(function() {
    'use strict';
    angular.module('abcQualityMatchApp').component('usersMultipleSelect', {
          templateUrl: 'app/components/users-multiple-select/users-multiple-select-component.html',
          restrict: 'E',
          bindings: {
              users: '=',
              placeholder:'@'
          },
          controller: UsersMultipleSelectController
        });

    UsersMultipleSelectController.$inject = ['$scope']
    function UsersMultipleSelectController(scope) {
        var ctrl = this;
        ctrl.newUser = null;

        ctrl.addUser = function () {
            if (ctrl.newUser != null) {
                var alreadyAdded = false;
                ctrl.users.forEach(function (user) {
                    if (user.id == ctrl.newUser.id) {
                        alreadyAdded = true;
                    }
                });
                if (!alreadyAdded) {
                    ctrl.users.push(ctrl.newUser);
                }
            }
            ctrl.newUser = null;
        };

        ctrl.deleteUser = function (index) {
            ctrl.users.splice(index,1);
            return false;
        };

    }

})();
