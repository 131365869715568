(function () {
    'use strict';
    angular
            .module('abcQualityMatchApp')
            .factory('Report', Report);

    Report.$inject = ['$resource'];

    function Report($resource) {
        var resourceUrl = 'api/report/';

        return {
            Stats: $resource(resourceUrl + 'stats', {}, {'query': {method: 'GET', isArray: false}})
        };
    }
})();
