(function () {
    'use strict';

    angular
            .module('abcQualityMatchApp')
            .factory('Principal', Principal);

    Principal.$inject = ['$q', 'Account'];

    function Principal($q, Account) {
        var _identity,
                _authenticated = false;

        var service = {
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            hasGroupAuthority: hasGroupAuthority,
            hasGroupOrUserAuthority: hasGroupOrUserAuthority,
            hasGroup: hasGroup,
            hasUserId: hasUserId,
            hasFolderReadPermission: hasFolderReadPermission,
            hasFolderWritePermission: hasFolderWritePermission,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved,
            forcedPasswordChange:forcedPasswordChange
        };

        return service;

        function authenticate(identity) {
            _identity = identity;
            _authenticated = identity !== null;
        }

        function hasAnyAuthority(authorities) {
            if (!_authenticated || !_identity || !_identity.combinedAuthorities) {
                return false;
            }
            if (_identity.authorities && hasRecursiveAuthorities(authorities)) {
                return true;
            }
            return _identity.userGroupAuthorities && hasGroupAuthorities(authorities);
        }

        function hasAuthority(authority) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function (_id) {
                return hasAnyAuthority([authority]);
            }, function () {
                return false;
            });
        }

        function hasGroupAuthority(authorities) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function (_id) {
                hasAnyAuthority(authorities);
            }, function () {
                return false;
            });
        }

        function hasGroupOrUserAuthority(authorities) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function (_id) {
                hasAnyAuthority(authorities);
            }, function () {
                return false;
            });
        }

        function hasGroup(group) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function (_id) {
                return _id.userGroups && _id.userGroups.indexOf(group) !== -1;
            }, function () {
                return false;
            });
        }

        function hasUserId(userId) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function (_id) {
                return _id.id == userId;
            }, function () {
                return false;
            });
        }

        function hasFolderReadPermission(folder) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function (_id) {
                if (!_id.combinedAuthorities) {
                    return false;
                }
//                console.log(folder);
                if (hasAnyAuthority(['ROLE_COMPANY_ADMIN','DOCUMENT_ADMIN'])) {
                    return true;
                }
                if (!_id.userGroupAuthorities) {
                    return false;
                }
                return hasFolderReadRights(folder);
            }, function () {
                return false;
            });
        }

        function hasFolderWritePermission(folder) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function (_id) {
                if (!_id.combinedAuthorities) {
                    return false;
                }
//                console.log(folder);
                if (hasAnyAuthority(['ROLE_COMPANY_ADMIN','DOCUMENT_ADMIN'])) {
                    return true;
                }
                if (!_id.userGroupAuthorities) {
                    return false;
                }
                return hasFolderWriteRights(folder);
            }, function () {
                return false;
            });
        }

        function hasRecursiveAuthorities(authorities) {
            var allAuthorities = ['ROLE_ADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_COMPANY_SUPERVISOR', 'ROLE_COMPANY_USER'];
            var recursiveAuthorities = [];

            for (var i = 0; i < allAuthorities.length; i++) {
                if (_identity.authorities.indexOf(allAuthorities[i]) !== -1) {
                    recursiveAuthorities = allAuthorities.slice(i);
                    break;
                }
            }

            for (var i = 0; i < recursiveAuthorities.length; i++) {
                if (authorities.indexOf(recursiveAuthorities[i]) !== -1) {
                    return true;
                }
            }
            return false;
        }

        function hasGroupAuthorities(authorities) {
            if (!_identity.userGroupAuthorities) {
                return false;
            }
            for (var i = 0; i < _identity.userGroupAuthorities.length; i++) {
                if (authorities.indexOf(_identity.userGroupAuthorities[i]) !== -1) {
                    return true;
                }
            }
            return false;
        }

        function hasFolderReadRights(folder) {
            var found = folder.permissions.some(function (permission) {
                if (_identity.userGroups.indexOf(permission.userGroup.id) !== -1) {
                    return true;
                }
            });
            if (found) {
                return true;
            }
            if (folder.parentFolder != undefined) {
                return hasFolderReadRights(folder.parentFolder);
            }
        }

        function hasFolderWriteRights(folder) {
            var found = folder.permissions.some(function (permission) {
                if (_identity.userGroups.indexOf(permission.userGroup.id) !== -1 && permission.writePermission) {
                    return true;
                }
            });
            if (found) {
                return true;
            }
            if (folder.parentFolder != undefined) {
                return hasFolderWriteRights(folder.parentFolder);
            }
        }

        function identity(force) {
            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            Account.get().$promise
                    .then(getAccountThen)
                    .catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen(account) {
                _identity = account.data;
                _authenticated = true;
                deferred.resolve(_identity);
            }

            function getAccountCatch() {
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            }
        }

        function isAuthenticated() {
            return _authenticated;
        }

        function isIdentityResolved() {
            return angular.isDefined(_identity);
        }
        function forcedPasswordChange(){
            return _identity.forcedPasswordChangeOnNextLogin;
        }
    }
})();
